import React from 'react';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import 'tailwindcss/tailwind.css';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const duration = 300; // Animation duration in milliseconds

  const defaultStyle = {
    height: 0,
    opacity: 0,
    transition: `height ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  };

  const transitionStyles = {
    entering: { height: 0, opacity: 0 },
    entered: { height: 'auto', opacity: 1 },
    exiting: { height: 0, opacity: 0 },
    exited: { height: 0, opacity: 0 },
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="my-4">
      <button
        className="flex items-center justify-between w-full py-2 px-4 bg-gray-200 text-gray-800 font-medium rounded-lg focus:outline-none"
        onClick={handleToggle}
      >
        <span>{question}</span>
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className="ml-2 text-gray-600"
        />
      </button>
      <Transition in={isOpen} timeout={duration}>
        {(state) => (
          <div
            className={`overflow-hidden transition-height transition-opacity ${
              state === 'entered' ? 'block' : 'hidden'
            }`}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className="bg-white text-gray-800 px-4 py-2 mt-2 rounded-lg">
              {answer}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

const FaqPage = () => {
  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-bold mb-4 text-center">FAQ</h1>
      <div className="grid grid-cols-1 gap-4">
        <FaqItem
          question="Bagaimana saya dapat melakukan pembayaran untuk kursus ini?"
          answer="Anda dapat melakukan pembayaran melalui Traktir Boba dengan jumlah sebesar 75k."
        />
        <FaqItem
          question="Bagaimana cara konfirmasi pembayaran setelah saya membayar?"
          answer="Anda dapat melakukan konfirmasi pembayaran melalui WhatsApp (WA) dengan mengirimkan bukti pembayaran kepada kami."
        />
        <FaqItem
          question="Apakah kursus ini bersifat permanen atau memiliki batas waktu tertentu?"
          answer="Kursus ini bersifat permanent, yang berarti Anda dapat mengaksesnya sepanjang waktu."
        />
        <FaqItem
          question="Apa manfaat yang akan saya dapatkan dari kursus ini?"
          answer="Kursus ini memberikan manfaat hard skill yang dapat Anda terapkan dalam pekerjaan, membantu Anda dalam mencari pekerjaan, membantu Anda membuat proyek, serta membantu Anda dalam mengerjakan tugas sekolah/kuliah."
        />
        <FaqItem
          question="Mengapa saya harus mencantumkan email saya pada pesanan?"
          answer="Anda perlu mencantumkan email Anda pada pesanan untuk memudahkan kami mengirimkan akses kursus dan informasi terkait ke email Anda."
        />
        <FaqItem
          question="Berapa lama waktu yang dibutuhkan bagi admin untuk merespons pesanan atau pertanyaan?"
          answer="Admin akan merespons pesanan atau pertanyaan dalam waktu maksimal 24 jam."
        />
      </div>
    </div>
  );
};

export default FaqPage;
