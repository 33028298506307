import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import Navbara from '../pages/Navbara';
import Navigation from '../pages/Navigation';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import firebase from "firebase/compat/app";
import parse from 'html-react-parser';
const DetailItem = () => {
  const navigate = useNavigate();
  const { permalink } = useParams();

  const [itemData, setItemData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputData, setInputData] = useState('');
  const [isLoading, setIsLoading] = useState(true); // New loading state

  useEffect(() => {
    const database = getDatabase();
    const itemsRef = ref(database, 'notifications/users');

    onValue(itemsRef, (snapshot) => {
      const data = snapshot.val();
      const selectedItem = Object.values(data).find(
        (item) => item.permalink === permalink
      );

      if (selectedItem) {
        setItemData(selectedItem);
        setIsLoading(false); // Set loading to false when data is loaded
      } else {
        navigate('/404');
      }
    });
  }, [permalink, navigate]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getData = () => {
    if (itemData && itemData.title) {
      setInputData(itemData.title);
    }
  };

  const saveData = () => {
    if (itemData) {
      const database = getDatabase();
      const itemRef = ref(database, `notifications/users/${itemData.id}`);

      update(itemRef, {
        title: inputData,
        imageUrl: null,
      })
        .then(() => {
          console.log('Data updated successfully in Firebase');
          setItemData({ ...itemData, title: inputData, imageUrl: null });
        })
        .catch((error) => {
          console.error('Error updating data:', error);
        });
    }
  };

  const applyFormatsToContent = (content) => {
    let formattedContent = content;
  
    // Array format yang didefinisikan
    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'video'
    ];
  
    // Menerapkan format-format ke dalam HTML
    formats.forEach(format => {
      // Ganti setiap format ke dalam bentuk HTML yang sesuai
      switch (format) {
        case 'header':
          formattedContent = formattedContent.replace(new RegExp(`<${format}>`, 'g'), '<strong>');
          formattedContent = formattedContent.replace(new RegExp(`<\/${format}>`, 'g'), '</strong>');
          break;
        case 'font':
          // Menangani font
          // ...
          break;
        case 'size':
          // Menangani size
          // ...
          break;
        // Menangani format-format lainnya
        // ...
        default:
          break;
      }
    });
  
    return formattedContent;
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

// walmmar

return (
    <>
      {itemData && (
        <Helmet>
          <title>{itemData.title}</title>
          <meta name="description" content={itemData.data} />
          {/* Add other relevant meta tags */}
        </Helmet>
      )}
          <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} shadow-md  min-h-screen`}>

        <Navbara />
        <main className="container mx-auto pt-2">
          <div className="max-w-4xl mx-auto flex items-center justify-center h-full">
          <div className="p-3 rounded-lg shadow-md ">
  {isLoading ? (
    <div className="flex items-center justify-center">
      <ClipLoader color={'#800080'} loading={isLoading} css={override} size={35} />
    </div>
  ) : itemData ? (
    <>
      {itemData.imageUrl && (
        <img
          src={itemData.imageUrl}
          alt="Item"
          className="transition-all duration-500 transform hover:scale-105 rounded-lg shadow-md mb-4 max-w-full h-auto"
        />
      )}
{itemData.title && (
  <h2 className=" text-lg md:text-4xl font-bold mb-4 " style={{textDecoration: 'underline', textTransform: 'uppercase'}}>
    {itemData.title}
  </h2>
)}


{itemData.data && (
  <div className="text-base md:text-base mb-4">
{parse(itemData.data)}
    
  </div>
)}


      {itemData.author && (
        <div className='mb-20'>
          <p className="text-lg md:text-lg text-gray-500 mb-4">Author: {itemData.author}</p>
        </div>
      )}
      {itemData.toggle && (
        <p className="text-base md:text-lg text-gray-700 mb-4">Value: {itemData.toggle}</p>
      )}
    </>
  ) : (
    <p className="text-center text-base md:text-lg">Loading...</p>
  )}
</div>

          </div>
        </main>

        <footer className="bg-gray-900 text-black">
          <Navigation />
        </footer>
      </div>

    </>
  );
};

export default DetailItem;

// CSS override for spinner
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
