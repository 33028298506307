import React, { useState, useEffect } from 'react';
import { useSpring, animated, } from "react-spring";
import { config } from 'react-spring';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

import { Link, Route, Routes, useLocation } from "react-router-dom";
import { MdHome, MdAddCircle, MdWork, MdMonetizationOn, MdAdd, MdAccountCircle, MdLanguage, MdCompost, MdGames, MdStore, MdRoomService, MdRequestPage, MdNewspaper } from "react-icons/md";
import {  FaPlus, FaHome, FaUser, FaCoins, FaDollarSign, FaAd, FaAffiliatetheme, FaArrowUp, FaDoorOpen, FaArrowAltCircleUp } from "react-icons/fa";




function Navigation() {
  const [showPopup, setShowPopup] = useState(false);
  const [registered, setRegistered] = useState(false); // New state for controlling form display

  const location = useLocation();
  const categories = [
  
    { id: 1, category: "news", name: "Share", icon: MdNewspaper, link: "/upload" },
    { id: 2, category: "cuan", name: "Start", icon: MdGames, link: "/roulettecomponent" },

    { id: 6, category: "ecommerce", name: "Seller", icon: MdStore, link: "/addproductform" },
    // { id: 4, category: "cuan", name: "Jadi Mitra Kami", icon: MdGames, link: "/affiliatecomponent" },
    // { id: 4, category: "cuan", name: "Jadi Mitra Kami", icon: MdGames, link: "/affiliatecomponent" },
  ];

    const popupAnimation = useSpring({
    opacity: showPopup ? 1 : 0,
    transform: showPopup ? "scale(1)" : "scale(0)",
    config: config.default, // Menggunakan konfigurasi animasi default
  });

  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [userExists, setUserExists] = useState(false); // Define userExists state
  useEffect(() => {
    if (user) {
      const onlineUsersRef = firebase.database().ref('notifications/formulir');

      onlineUsersRef.once('value', (snapshot) => {
        const userData = snapshot.val();
        const exists = Object.values(userData || {}).some((data) => data.userId === user.uid);

        setUserExists(exists); // Set userExists based on UID existence
      });
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [startAnimation, setStartAnimation] = useState(false);

  const arrowAnimation = useSpring({
    from: { translateY: 0 },
    to: { translateY: startAnimation ? -20 : 0 },
    loop: { reverse: true },
    config: { duration: 1000 },
  });

  useEffect(() => {
    const startAnimationTimeout = setTimeout(() => {
      setStartAnimation(true); // Mulai animasi setelah 10 detik pertama kali
    }, 10000);

    const stopAnimationTimeout = setTimeout(() => {
      setStartAnimation(false); // Hentikan animasi setelah 5 detik dari mulai animasi
    }, 15000); // Total waktu animasi adalah 5 detik setelah 10 detik diam

    return () => {
      clearTimeout(startAnimationTimeout);
      clearTimeout(stopAnimationTimeout);
    };
  }, []);
const handlePopup = () => {
    if (!user) {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider)
        .then((result) => {
          setUser(result.user);
          setShowPopup(!showPopup);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setShowPopup(!showPopup);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (user) {
      const onlineUsersRef = firebase.database().ref('notifications/formulir');

      onlineUsersRef.once('value', (snapshot) => {
        const userData = snapshot.val();
        const userExists = Object.values(userData || {}).some((data) => data.userId === user.uid);

        if (userExists) {
          console.log("Formulir Anda dalam pengecekan.");
          window.location.replace('/'); // Redirect to home if user exists
        } else {
          // Submit the form data to Firebase
          onlineUsersRef.push({
            name: formData.name,
            email: formData.email,
            password: formData.password,
            userId: user.uid,
            registrationStatus: 'in process',
            whatsapp: formData.whatsapp, // Tambahkan field whatsapp ke objek yang dikirim
          }).then(() => {
            setFormData({
              name: '',
              email: '',
              password: '',
            });
            setShowPopup(false);
            setRegistered(true); // Set the flag to control form display
            window.location.replace('/'); // Redirect after successful registration
          }).catch((error) => {
            console.error("Error submitting form:", error);
          });
        }
      });
    }
  };
  

  useEffect(() => {
    console.log('Nilai userExists berubah:', userExists);
  }, [userExists]);
  
  
  const currentUserEmail = user ? user.email : '';

  

  return (
    <nav
      style={{
        backgroundColor: "FFFFFF",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
      }}
      className="bg-FFFFFF dark:bg-FFFFFF   "
    >
<div className="flex justify-around items-center py-3" style={{ backgroundColor: '#0F0F0F' }}>

<Link
  to="/"
  activeClassName="text-green-600"
  className="flex flex-col items-center text-gray-500 hover:text-green-600 transition-colors duration-200"
  id="element3"
>
<FaHome className="text-xl text-gray-400" />
  <span className="text-xs">Home</span>
</Link>
<Link
  activeClassName="text-green-600"
  className="fixed flex flex-col items-center text-gray-500 hover:text-green-600 transition-colors duration-200"
  style={{
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '16px',
    borderRadius: '100%',
    backgroundColor: '#0F0F0F',
    padding: '18px',
  }}
  onClick={handlePopup}
  aria-label="Post something" // Tambahkan atribut aria-label untuk aksesibilitas
>
  {/* Gunakan animated.div untuk menerapkan animasi */}
  <animated.div style={{ transform: arrowAnimation.translateY.interpolate(y => `translateY(${y}px)`) }}>
    <div className="relative">
      <FaArrowAltCircleUp className='text-green-700 text-3xl' />
      {/* Tambahkan label yang mengambang */}
      <span
        className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 text-sm text-gray-400 px-2 py-1 rounded-full"
        style={{ marginTop: '3.0rem', marginLeft: '15px' }} // Tambahkan margin kiri di sini
      >
        Posting
      </span>
    </div>
  </animated.div>
</Link>

<Link
  to="/login"
  activeClassName="text-green-600"
  className="flex flex-col items-center text-gray-500 hover:text-green-600 transition-colors duration-200"
>
<FaUser className="text-xl text-gray-400" />
  <span className="text-xs">Account</span>
</Link>
</div>

      {showPopup && !registered && (
  <animated.div
    className="fixed inset-0 flex items-center justify-center overflow-auto"
    style={{
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
      ...popupAnimation,
      backdropFilter: "blur(5px)",
    }}
  >
    {userExists ? (
      <div className="bg-white rounded-lg w-11/12 max-w-md mx-auto p-6 text-center text-black border border-green-700">
        <p>Formulir Anda sedang dalam pengecekan<br/>Kami akan memberitahu anda di WhatsApp</p>
        <button
          onClick={() => setShowPopup(false)}
          className="w-full bg-gray-700 text-white rounded-lg py-2 mt-4 hover:bg-gray-500 transition-colors duration-200"
        >
          Tutup
        </button>
      </div>
    ) : (
<div className="bg-white rounded-lg w-11/12 max-w-md mx-auto p-6">
<div className="bg-black border-l-4 border-green-500 text-white p-4 mb-4 rounded-lg">
      <p className="text-sm">
        <strong>Pastikan Nomor kamu Benar </strong> <br/>Pemberitahuan akan dikirim ke nomor WhatsApp
      </p>
    </div>
  <form onSubmit={handleFormSubmit} id="myForm" className="mb-4">
    <input
      type="text"
      placeholder="Nama Lengkap"
      value={formData.name}
      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      className="mb-3 px-3 py-2 border rounded-lg w-full focus:outline-none focus:ring focus:border-blue-500"
      form="myForm"
    />
 <input
  type="email"
  placeholder="Email kamu disini"
  value={currentUserEmail}
  readOnly // Menggunakan readOnly agar tidak bisa diubah oleh pengguna
  className="mb-3 px-3 py-2 border rounded-lg w-full focus:outline-none focus:ring focus:border-blue-500"
  form="myForm"
/>

<input
  type="number"
  placeholder="+62"
  value={formData.whatsapp}
  onChange={(e) => setFormData({ ...formData, whatsapp: e.target.value })}
  className="mb-3 px-3 py-2 border rounded-lg w-full focus:outline-none focus:ring focus:border-blue-500"
  form="myForm"
/>


   
    <button
      type="submit"
      className="w-full bg-green-600 text-white rounded-lg py-2 hover:bg-green-700 transition-colors duration-200"
      form="myForm"
    >
      Daftar
    </button>
  </form>
  <button
    onClick={() => setShowPopup(false)}
    className="w-full bg-gray-400 text-white rounded-lg py-2 hover:bg-gray-500 transition-colors duration-200"
  >
    Tutup
  </button>
</div>

    )}
  </animated.div>
)}

    </nav>
  );
}

export default Navigation;
