import React, { useState, useEffect } from 'react';
import { Link, Route, Routes } from "react-router-dom";
import Sosmed from "./Sosmed";
import firebase from "firebase/compat/app";
import "firebase/compat/database";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function toggleMobileMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  return (
    <nav>



<div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} max-w-7xl mx-auto px-4 sm:px-6 lg:px-8`}>


    <div className="flex justify-between items-center h-100">
      <a href="/"><div className="flex items-center">
      
          {/* <img className="h-8 w-auto" src={logozivzu} alt="Logo" /> */}
       
        <h1 className="ml-1 text-xl font-bold">KELASSKILL</h1>
      </div></a>
      <div className="hidden md:block">
        <div className="ml-10 flex items-baseline space-x-4">
          {/* <a href="#" className="hover:bg-gray-50 text-black px-3 py-2 rounded-md text-sm font-medium">
          <Link to="/">Home</Link>
          </a> */}
        
          <a href="#" className="hover:bg-gray-50 text-black px-3 py-2 rounded-md text-sm font-medium">
          <Link to="/about">Tentang Kami</Link>
          </a>
          <a href="#" className="hover:bg-gray-50 text-black px-3 py-2 rounded-md text-sm font-medium">
          <Link to="/trendingitem">Blog</Link>
          </a>
          <a href="#" className="hover:bg-gray-50 text-black px-3 py-2 rounded-md text-sm font-medium">
          <Link to="# ">Changelog</Link>
          </a>
  


        </div>
      </div>
      <div className="-mr-2 flex md:hidden">
        <button onClick={toggleMobileMenu} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-100 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out">
          <span className="sr-only">Open main menu</span>
          <svg className={`${isMobileMenuOpen ? "hidden" : "block"} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg className={`${isMobileMenuOpen ? "block" : "hidden"} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div className={`${isMobileMenuOpen ? "block" : "hidden"} md:hidden`}>
    <div className="px-2 pt-2 pb-3 sm:px-3">
      {/* <a href="#" className="block hover:bg-gray-700 text-white px-3 py-2 rounded-md text-base font-medium">
      <Link to="/">Home</Link>
      </a> */}
     
      <a href="/about" className="block hover:bg-gray-50 text-black px-3 py-2 rounded-md text-base font-medium">
      Tentang Kami
      </a>
      <a href="# " className="block hover:bg-gray-50 text-black px-3 py-2 rounded-md text-base font-medium">
      Changelog
      </a>

    
  
      
    </div>
    
  </div>

</nav>



  );
}

export default Navbar;
