import React, { useState, useEffect } from 'react';
import { useSpring, animated, } from "react-spring";
import { config } from 'react-spring';


import { Link, Route, Routes, useLocation } from "react-router-dom";
import { MdHome, MdAddCircle, MdWork, MdMonetizationOn, MdAdd, MdAccountCircle, MdLanguage, MdCompost, MdGames, MdStore, MdRoomService, MdRequestPage, MdNewspaper } from "react-icons/md";
import {  FaPlus, FaHome, FaUser } from "react-icons/fa";




function Navigation() {
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const categories = [
    { id: 1, category: "news", name: "Share", icon: MdNewspaper, link: "/upload" },
    { id: 2, category: "cuan", name: "Start", icon: MdGames, link: "/roulettecomponent" },

    { id: 6, category: "ecommerce", name: "Seller", icon: MdStore, link: "/addproductform" },
    // { id: 4, category: "cuan", name: "Jadi Mitra Kami", icon: MdGames, link: "/affiliatecomponent" },
    // { id: 4, category: "cuan", name: "Jadi Mitra Kami", icon: MdGames, link: "/affiliatecomponent" },
  ];




  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  const popupAnimation = useSpring({
    opacity: showPopup ? 1 : 0,
    transform: showPopup ? "scale(1)" : "scale(0)",
    config: config.default, // Menggunakan konfigurasi animasi default
  });

  return (
    <nav
      style={{
        backgroundColor: "#ffffff",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
      }}
      className="bg-ffffff dark:bg-ffffff"
    >
      <div className="flex justify-around items-center py-3">
        <Link
          to="/"
          activeClassName="text-purple-600"
          className="flex flex-col items-center text-gray-500 hover:text-purple-600 transition-colors duration-200"
          id="element3"
        >
    <FaHome className="text-xl" />
          <span className="text-xs">Home</span>
        </Link>

        <Link
            activeClassName="text-red-600"
            className="flex flex-col items-center text-gray-500 hover:text-purple-600 transition-colors duration-200"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "16px",
              borderRadius: "100%",
              backgroundColor: "#8B5CF6",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              padding: "18px",
           
            
          }}
          onClick={handlePopup}
        >
  <FaPlus className='text-white'
  />        </Link>

        <Link
          to="/login"
          activeClassName="text-purple-600"
          className="flex flex-col items-center text-gray-500 hover:text-purple-600 transition-colors duration-200"
        >
       <FaUser className="text-xl" />
          <span className="text-xs">Account</span>
        </Link>
      </div>

      {showPopup && (
        <animated.div
          className="fixed inset-0 flex items-center justify-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
            ...popupAnimation,
            backdropFilter: "blur(5px)",
          }}
        >
          <div
            className="bg-white p-4 rounded-lg"
            style={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
  <div class="flex items-center p-4 text-sm text-gray-800  rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600" role="alert">
  <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span class="sr-only">Info</span>
  <p className='text-xs'>
 Tunggu apalagi? <br/> Mulai dapatkan peluangmu disini.






  </p>
</div>
            <div className="flex justify-center items-center mt-4">
              <div className="flex flex-wrap justify-center">
                {categories.map((category) => (
                  <Link
                    key={category.id}
                    to={category.link}
                    className="flex flex-col items-center space-y-2 w-1/4 px-4 py-2 text-center font-medium text-black hover:text-purple-600 transition-colors duration-200"
                  >
                    {React.createElement(category.icon, {
                      className: "text-4xl text-gray-900",
                      style: { filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))" },
                    })}
                    <span className="text-xs">{category.name}</span>
                  </Link>
                ))}
              </div>
            </div>
            <button
              className="mt-4 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200"
              onClick={handlePopup}
            >
              Tutup
            </button>
          </div>
        </animated.div>
      )}
    </nav>
  );
}

export default Navigation;