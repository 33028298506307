import React, { useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Inisialisasi Firebase
const firebaseConfig = {
    apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
    authDomain: "kelasskilla.firebaseapp.com",
    databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
    projectId: "kelasskilla",
    storageBucket: "kelasskilla.appspot.com",
    messagingSenderId: "785364290918",
    appId: "1:785364290918:web:1da135595c6cea24be4ca7",
    measurementId: "G-R4L1YVZSTB"
  };

const AddStepForm = () => {
  const [steps, setSteps] = useState([
    { 
      socialMedia: '', 
      service: '', 
      packageType: '',
      subject: '',
      deskripsi: '',
      quantity: '',
      price: ''
    }
  ]);

  // Fungsi untuk menambahkan langkah baru
  const addStep = () => {
    setSteps([...steps, { 
      socialMedia: '', 
      service: '', 
      packageType: '',
      subject: '',
      deskripsi: '',
      quantity: '',
      price: ''
    }]);
  };

  // Fungsi untuk menambahkan langkah ke Firestore
  const addStepToFirestore = async () => {
    try {
      const stepsCollection = firebase.firestore().collection('Steps');

      // Tambahkan setiap langkah ke koleksi "Steps"
      await Promise.all(
        steps.map(async (step) => {
          await stepsCollection.add(step);
        })
      );

      // Reset form setelah berhasil menambahkan
      setSteps([{
        socialMedia: '', 
        service: '', 
        packageType: '',
        subject: '',
        deskripsi: '',
        quantity: '',
        price: ''
      }]);

      alert('Steps have been added to Firestore!');
    } catch (error) {
      console.error('Error adding steps to Firestore: ', error);
      alert('Failed to add steps. Please try again.');
    }
  };

  // Fungsi untuk mengubah nilai langkah tertentu
  const handleStepChange = (index, event) => {
    const { name, value } = event.target;
    const newSteps = [...steps];
    newSteps[index][name] = value;
    setSteps(newSteps);
  };

  return (
    <div className='bg-red-500'>
      <h2>Add Step Form</h2>
      {steps.map((step, index) => (
        <div key={index}>
          <label>
            Select Social Media:
            <select
              name="socialMedia"
              value={step.socialMedia}
              onChange={(e) => handleStepChange(index, e)}
            >
              <option value="">Select One</option>
              <option value="instagram">Instagram</option>
              <option value="facebook">Facebook</option>
              <option value="Tiktok">Tiktok</option>
              <option value="youtube">YouTube</option>
            </select>
          </label>
          <br />
          <label>
            Enter Service:
            <input
              type="text"
              name="service"
              value={step.service}
              onChange={(e) => handleStepChange(index, e)}
            />
          </label>
          <br />
    
          <br />
          <label>
            Enter Subject:
            <input
              type="text"
              name="subject"
              value={step.subject}
              onChange={(e) => handleStepChange(index, e)}
            />
          </label>
          <br />
     
          <br />
          <label>
            Enter Quantity:
            <input
              type="number"
              name="quantity"
              value={step.quantity}
              onChange={(e) => handleStepChange(index, e)}
            />
          </label>
          <br />
          <label>
            Enter Price:
            <input
              type="number"
              name="price"
              value={step.price}
              onChange={(e) => handleStepChange(index, e)}
            />
          </label>
          <label>
            Enter Description:
            <input
              type="text"
              name="deskripsi"
              value={step.deskripsi}
              onChange={(e) => handleStepChange(index, e)}
            />
          </label>
          <br />
        </div>
      ))}
      <button onClick={addStep}>Add Step</button>
      <button onClick={addStepToFirestore}>Add Steps to Firestore</button>
    </div>
  );
};

export default AddStepForm;
