import React, { useEffect } from 'react';
import CheckProduct from './CheckProduct';
import { getAuth } from 'firebase/auth';
import AdminForm from './AdminForm';
import FirebaseDataViewer from './FirebaseDataViewer';
import AdminPanel from './AdminPanel';
import FormDataComponent from '../pages/FormDataComponent';
import TaskForm from './TaskForm';
const allowedUserIds = ['Iius3XSd75gMGLhRz5XwCJcLlng2', 'GJ5uRaD6eFhUPgl1j1T48bdM3Ky2', 'YxeXN5KgRxfcOFdFEw8ULKUcKR92'];

const UserContext = () => {
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const userId = user.uid;
      if (!allowedUserIds.includes(userId)) {
        // Redirect to home or show 404 message
        window.location.href = '/'; // Replace '/' with the desired home page URL
      }
    } else {
      // User is not authenticated, redirect to home or show 404
      window.location.href = '/'; // Replace '/' with the desired home page URL
    }
  }, []);

  return (
    <div className=''>

    
          <FormDataComponent/>

            <FirebaseDataViewer/>
            <AdminForm/>
            <TaskForm/>
            <CheckProduct/>

    </div>
  );
}

export default UserContext;
