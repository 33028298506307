 import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { CheckCircleIcon } from '@heroicons/react/outline';
import qrisCode from '../assets/rezas.jpeg'; // Mengimpor gambar QRIS dari direktori assets
import axios from 'axios'; // Mengimpor Axios untuk melakukan permintaan HTTP

const PaymentComponent = ({ permalink }) => {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState(null);
  const [selectedPermalink, setSelectedPermalink] = useState(permalink); // Menyimpan permalink kelas yang dipilih
  const [inputMessage, setInputMessage] = useState('');

  useEffect(() => {
    const fetchKelas = async () => {
      try {
        const kelasDoc = await firebase.firestore().collection('classes').where('permalink', '==', selectedPermalink).get();
        if (!kelasDoc.empty) {
          const kelasData = kelasDoc.docs[0].data();
          const { price, title } = kelasData;
          setPrice(price);
          setTitle(title);
        } else {
          console.log('Kelas tidak ditemukan');
        }
      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data kelas:', error);
      }
    };

    fetchKelas();
  }, [selectedPermalink]);

  const handleConfirmPayment = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const uid = user.uid;
  
        // Mengganti alert dengan window confirm
        const confirmResult = window.confirm('Pesanan Anda dalam pengecekan. Silahkan kembali ke halaman awal');
  
        if (confirmResult) {
          // Menambahkan pembayaran ke Firestore
          await firebase.firestore().collection('payments').add({
            title: title,
            price: price,
            qrisCode: qrisCode,
            status: false,
            uid: uid,
            permalink: selectedPermalink,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          });
  
          // Kirim pesan menggunakan Axios
          const message = `Halo kak, pesanan saya untuk kelas ${title}, ${uid} dengan harga ${formatToRupiah(price)}`;
          const response = await axios.get(`https://asia-southeast2-starry-gravity-428814-h7.cloudfunctions.net/function-1?message=${encodeURIComponent(message)}`);
          console.log('Response from server:', response.data);
  
          // Setelah mendapatkan respons, tambahkan logika untuk menanggapi atau menangani respons sesuai kebutuhan Anda.
  
          // Arahkan pengguna ke halaman kelas setelah selesai
          window.location.href = '/kelas';
        } else {
          console.log('Pembayaran tidak dikonfirmasi oleh pengguna.');
          // Handle logic if user cancels the confirmation
        }
      } else {
        console.log('Pengguna belum masuk');
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menambahkan pembayaran ke Firestore:', error);
    }
  };
  

  // Fungsi untuk mengonversi harga menjadi format Rupiah (IDR)
  const formatToRupiah = (price) => {
    // Ubah string harga menjadi angka
    const numericPrice = parseFloat(price);
    
    // Format angka menjadi format mata uang Rupiah
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(numericPrice);
  };

  const handleCancel = () => {
    // Fungsi untuk mengarahkan pengguna kembali
    window.history.back();
  };

  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-gray-800 bg-opacity-75 backdrop-filter backdrop-blur-lg -mt-16">
      <div className="text-center mb-4">
        <h2 className="text-sm font-semibold mb-2 text-white">{title}</h2>
        <p className="text-sm text-white">
          Silakan lakukan pembayaran sejumlah <span className={`text-blue-500 ${price !== null ? 'font-semibold' : ''}`}>{price !== null ? formatToRupiah(price) : 'loading...'}<span>0</span></span><br />
          dengan memindai QRIS berikut:
        </p>
      </div>
  
      <div className="flex justify-center items-center mb-4">
        <div className="p-4 rounded-lg shadow-lg bg-white">
          <a href={qrisCode} download>
            <img src={qrisCode} alt="QRIS Code" className="w-48 h-auto transition-transform duration-300 hover:scale-105" />
          </a>
        </div>
      </div>
  
      <div className="flex flex-col justify-center items-center space-y-4">
        <button onClick={handleConfirmPayment} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md flex items-center space-x-2">
          <CheckCircleIcon className="w-6 h-6" /> {/* Ikon */}
          Konfirmasi Pembayaran
        </button>
        <button onClick={handleCancel} className="bg-black hover:bg-gray-500 text-white py-2 px-4 rounded-md flex items-center">
          X
        </button>
      </div>
    </div>
  );
}  

export default PaymentComponent;
