import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const AddUIDForm = () => {
    const [newUID, setNewUID] = useState('');

    const handleInputChange = (e) => {
        setNewUID(e.target.value);
    };

    const handleAddUID = async () => {
        // Pastikan UID tidak kosong
        if (newUID.trim() === '') {
            alert("UID tidak boleh kosong.");
            return;
        }

        try {
            // Tambahkan UID baru ke koleksi 'uids' di Firestore
            await firebase.firestore().collection('uids').add({
                uid: newUID
            });
            alert("UID berhasil ditambahkan!");
            setNewUID(''); // Reset nilai input setelah berhasil menambahkan UID
        } catch (error) {
            console.error('Error adding UID: ', error);
            alert('Terjadi kesalahan saat menambahkan UID.');
        }
    };

    return (
        <div>
            <h2>Tambahkan UID Baru</h2>
            <input 
                type="text" 
                value={newUID} 
                onChange={handleInputChange} 
                placeholder="Masukkan UID baru" 
            />
            <button onClick={handleAddUID}>Tambahkan UID</button>
        </div>
    );
};

export default AddUIDForm;
