import React, { useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";
const TransactionForm = () => {
  const [item, setItem] = useState('');
  const [amount, setAmount] = useState('');
  const [category, setCategory] = useState(''); // Menambah state untuk kategori

  const handleSubmit = (e) => {
    e.preventDefault();

    const database = firebase.database();
    const transactionsRef = database.ref('notifications/transactions'); // Ubah 'transactions' sesuai dengan path database Anda

    transactionsRef.push({
      item,
      amount: parseInt(amount),
      category, // Menyertakan kategori dalam data yang dikirim ke Firebase
      // Jika diperlukan, Anda juga bisa menambahkan properti lain ke dalam data yang akan dikirim ke Firebase
    });

    // Reset form setelah pengiriman
    setItem('');
    setAmount('');
    setCategory('');
  };
  return (
    <form onSubmit={handleSubmit} className="p-4 w-full max-w-lg mx-auto mt-8">
      <div className="mb-4">
        <label htmlFor="itemInput" className="block text-gray-700">Item:</label>
        <input
          type="text"
          id="itemInput"
          value={item}
          onChange={(e) => setItem(e.target.value)}
          className="w-full border rounded-md py-2 px-3 text-gray-700 mt-1 focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="amountInput" className="block text-gray-700">Amount:</label>
        <input
          type="number"
          id="amountInput"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full border rounded-md py-2 px-3 text-gray-700 mt-1 focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="categorySelect" className="block text-gray-700">Category:</label>
        <select
          id="categorySelect"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="w-full border rounded-md py-2 px-3 text-gray-700 mt-1 focus:outline-none focus:ring focus:border-blue-300"
        >
          <option value="">Pilih Kategori</option>
          <option value="Electronics">Elektronik</option>
          <option value="Clothing">Pakaian</option>
          <option value="Food">Makanan</option>
          {/* Tambahkan kategori lain sesuai kebutuhan */}
        </select>
      </div>
      <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Tambah Transaksi
      </button>
    </form>
  );
};


export default TransactionForm;
