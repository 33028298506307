import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const database = firebase.database();

function TaskForm() {
  const [taskContent, setTaskContent] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [tasks, setTasks] = useState([]);
  const [editingTask, setEditingTask] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  useEffect(() => {
    const tasksRef = database.ref('notifications/tasks');

    tasksRef.on('value', (snapshot) => {
      const tasksData = snapshot.val();
      if (tasksData) {
        const tasksArray = Object.entries(tasksData).map(([taskId, task]) => ({
          id: taskId,
          content: task.content,
          timestamp: task.timestamp,
          completed: task.completed,
        }));
        setTasks(tasksArray);
      } else {
        setTasks([]);
      }
    });

    return () => {
      tasksRef.off();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!taskContent || !timestamp) {
      alert('Isi konten dan timestamp!');
      return;
    }

    try {
      const chosenTimestamp = new Date(timestamp);

      if (isNaN(chosenTimestamp)) {
        alert('Format timestamp tidak valid!');
        return;
      }

      const tasksRef = database.ref('notifications/tasks');

      const newTaskRef = tasksRef.push();
      await newTaskRef.set({
        content: taskContent,
        timestamp: chosenTimestamp.toString(),
        completed: false,
      });

      setTaskContent('');
      setTimestamp('');

      alert('Task berhasil ditambahkan!');
    } catch (error) {
      console.error('Error menambahkan task:', error);
      alert('Terjadi kesalahan saat menambahkan task.');
    }
  };

  const handleEdit = (taskId, taskContent) => {
    setEditingTask(taskId);
    setEditedContent(taskContent);
  };

  const handleSave = async (taskId) => {
    try {
      const taskRef = database.ref(`notifications/tasks/${taskId}`);
      await taskRef.update({ content: editedContent });
      setEditingTask(null);
      alert('Task berhasil diubah!');
    } catch (error) {
      console.error('Error mengubah task:', error);
      alert('Terjadi kesalahan saat mengubah task.');
    }
  };

  const handleDelete = async (taskId) => {
    if (window.confirm('Apakah kamu yakin ingin menghapus task ini?')) {
      try {
        const taskRef = database.ref(`notifications/tasks/${taskId}`);
        await taskRef.remove();
        alert('Task berhasil dihapus!');
      } catch (error) {
        console.error('Error menghapus task:', error);
        alert('Terjadi kesalahan saat menghapus task.');
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
        <div className='mb-4'>
          <label htmlFor='taskContent' className='block text-gray-700 text-sm font-bold mb-2'>
            Konten Task:
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='taskContent'
            type='text'
            value={taskContent}
            onChange={(e) => setTaskContent(e.target.value)}
            placeholder='Masukkan konten task'
          />
        </div>
        <div className='mb-6'>
          <label htmlFor='timestamp' className='block text-gray-700 text-sm font-bold mb-2'>
            Timestamp (Contoh: 2023-12-04T01:15:00):
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='timestamp'
            type='text'
            value={timestamp}
            onChange={(e) => setTimestamp(e.target.value)}
            placeholder='Masukkan timestamp'
          />
        </div>
        <button
          type='submit'
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
        >
          Tambah Task
        </button>
      </form>
  
      <div className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
        <h2 className='text-gray-700 text-lg font-bold mb-4'>List of Tasks:</h2>
        <ul>
          {tasks.map((task) => (
            <li key={task.id} className='mb-4'>
              {editingTask === task.id ? (
                <div>
                  <input
                    type='text'
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2'
                  />
                  <button
                    onClick={() => handleSave(task.id)}
                    className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2'
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div>
                  <div className='mb-1'>
                    <strong className='text-gray-700'>Task:</strong> {task.content}
                  </div>
                  <div className='mb-2'>
                    <strong className='text-gray-700'>Timestamp:</strong> {task.timestamp}
                  </div>
                  <button
                    onClick={() => handleEdit(task.id, task.content)}
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2'
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(task.id)}
                    className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                  >
                    Delete
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      </>
  );
  
}

export default TaskForm;
