import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database'; // Menggunakan database dari Firebase
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [isMarqueeDisplayed, setIsMarqueeDisplayed] = useState(true); // State untuk mengontrol apakah teks berjalan sudah ditampilkan

  useEffect(() => {
    const tasksRef = firebase.database().ref('notifications/tasks');

    const handleData = (snapshot) => {
      const tasksData = [];
      snapshot.forEach((childSnapshot) => {
        const task = {
          id: childSnapshot.key,
          ...childSnapshot.val(),
        };
        tasksData.push(task);
      });
      setTasks(tasksData);
    };

    tasksRef.on('value', handleData);

    return () => {
      tasksRef.off('value', handleData);
    };
  }, []);

  const handleDeleteTask = (taskId) => {
    const taskRef = firebase.database().ref(`notifications/tasks/${taskId}`);
    taskRef.remove()
      .then(() => {
        const updatedTasks = tasks.filter(task => task.id !== taskId);
        setTasks(updatedTasks);
      })
      .catch(error => {
        console.error('Error removing task: ', error);
      });
  };

  const currentTime = new Date();

  const filteredTasks = tasks.filter((task) => {
    const taskTime = new Date(task.timestamp);
    return (
      !task.completed &&
      currentTime.getTime() >= taskTime.getTime()
    );
  });

  // Tampilkan teks berjalan hanya jika belum ditampilkan sebelumnya
  const marqueeElement = filteredTasks.length > 0 && isMarqueeDisplayed && (
    <div className='bg-violet-900 py-2 px-4 rounded-md'>
      <marquee behavior='scroll' direction='left' className='text-white font-medium'>
        {filteredTasks.map((task) => task.content).join('  || ')}
      </marquee>
    </div>
  );

  // Jika teks berjalan ditampilkan, atur state agar tidak ditampilkan lagi
  useEffect(() => {
    if (filteredTasks.length > 0 && isMarqueeDisplayed) {
      setIsMarqueeDisplayed(false);
    }
  }, [filteredTasks, isMarqueeDisplayed]);

  return (
    <div className=''>
      {marqueeElement}
    </div>
  );
};

export default TaskList;
