import React, { useState, useEffect } from 'react';

import designku from "../assets/designku.png";
import { Helmet } from 'react-helmet';
import firebase from "firebase/compat/app";

import "firebase/compat/database";
import Navbar from '../pages/Navbar';
import Navigation from '../pages/Navigation';
import FaqPage from './FaqPage';
import DiscountAlert from './DiscountAlert';
import Syllabus from './Syllabus';
import Navbara from '../pages/Navbara';
const FlashSale = () => {
  // set dummy data
  const [imageSrc, setImageSrc] = useState('https://via.placeholder.com/300x200');

  const products = [
    {
      title: "Kursus Design Buat Aplikasi Gojek",
      image: designku,
      discountPrice: 250000,
      currentPrice: 75000,
      itemsSold: 5
    },
    // {
    //   title: "Kursus Design Buat Aplikasi Tinder",
    //   image: kelastinder,
    //   discountPrice: 250000,
    //   currentPrice: 75000,
    //   itemsSold: 5
    // },
    // {
    //   title: "Kursus Design Buat Aplikasi Murah",
    //   image: designku,
    //   discountPrice: 200000,
    //   currentPrice: 50000,
    //   itemsSold: 3
    // },
    // {
    //   title: "Kaos zivzu T-Shirt Oversize Garret Cotton Black - XL",
    //   image: zivzu,
    //   discountPrice: 300000,
    //   currentPrice: 90000,
    //   itemsSold: 7
    // },
    // Add more products here
  ];

  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [showModal, setShowModal] = useState(false);

  const [showMore, setShowMore] = useState(false);

  const handleShowMoreClick = () => {
    setShowMore(true);
  };

  // calculate percentage of items sold
  const soldPercentage = Math.round((selectedProduct.itemsSold / 10) * 100);

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleBuyButtonClick = () => {
    setShowModal(true);
  };

  const handleConfirmPurchase = () => {
    const paymentUrl = `https://trakteer.id/kelasskill/tip`;
    window.location.href = paymentUrl;
  };

  const handleCancelPurchase = () => {
    setShowModal(false);
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);
  

  return (
   <>
       <Helmet>
        <title>Flash Sale Page</title>
        <meta name="description" content="Flashsale kelasskill dengan kursus berkualitas dan diskon menarik." />
        {/* Tambahkan tag-tag <head> lainnya sesuai kebutuhan SEO */}
      </Helmet>
<Navbara/>
   <div className="grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {
      products.map((product, index) => (
        <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} shadow-md p-4`}>



  

          <div className="w-full">
            <div className="flex items-center">
            </div>
            
            <img
  src={product.image}
  alt="Product"
  className="max-w-full h-auto mb-4 cursor-pointer"
  onClick={handleImageClick}
  loading="lazy" // Tambahkan atribut loading="lazy" di sini
/>

          </div>
   
          <div className="w-full mb-20">
            <div className="flex flex-col justify-between h-full">
              <div>
              
                <h1 className="text-2xl font-bold mb-0">{product.title}</h1>
             <DiscountAlert/>
                <del className=" text-2xl font-bold mb-2">Rp {product.discountPrice.toLocaleString()}</del>
                <p className="text-gray-500 text-sm">Harga Sekarang</p>
                <p className="text-green-700 text-xl font-bold mb-4">Rp {product.currentPrice.toLocaleString()}</p>
             
                <div className="grid grid-cols-2 gap-4">
                  
                <button
  className="col-span-1 inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center bg-gradient-to-r from-green-500 to-green-600 rounded-md opacity-100 focus:ring-gray-100 text-white dark:text-white"
  style={{
    background: 'linear-gradient(to right, #00cc66, #009933)',
    backgroundSize: '200% 100%',
    backgroundPosition: 'right center',
    transition: 'background-position 0.5s',
  }}
  onMouseEnter={(e) => {
    e.target.style.backgroundPosition = 'left center';
  }}
  onMouseLeave={(e) => {
    e.target.style.backgroundPosition = 'right center';
  }}
  onClick={handleBuyButtonClick}
>
  Beli
</button>




  <div className="col-span-1">
    <a href='https://www.zivzu.com/faqpage'
    className="col-span-1 inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center bg-gray-700 focus:ring-gray-100 text-white dark:text-white"
    style={{
      backgroundSize: '200% 100%',
      backgroundPosition: 'right center',
      transition: 'background-position 0.5s',
    }}
    onMouseEnter={(e) => {
      e.target.style.backgroundPosition = 'left center';
    }}
    onMouseLeave={(e) => {
      e.target.style.backgroundPosition = 'right center';
    }}
   
  >
    FAQ
  </a>
  </div>
  
</div>
<div className='mt-4'>
<Syllabus/>
     </div>           
              </div>
            </div>
          </div>

          {showModal && (
            <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
              <div className="max-w-full mx-4 md:w-1/2 lg:w-1/3 rounded-lg bg-gray-200 p-4">
                {/* <img
                  src={product.image}
                  alt="Product"
                  className="max-w-full h-auto mb-4"
                /> */}
                <p className="text-center text-gray-700 mb-4">Jangan lupa isi email kamu di pesan dukungan ya, jika butuh bantuan silahkan hubungi WhatsApp admin</p>
                <div className="flex justify-center">
                  <button
                    className="bg-green-600 text-black rounded-full py-2 px-4 mr-2 hover:bg-green-700"
                    onClick={handleConfirmPurchase}
                  >
                    OK
                  </button>
                  <button
                    className="bg-red-600 text-black rounded-full py-2 px-4 hover:bg-red-700"
                    onClick={handleCancelPurchase}
                  >
                    Batal
                  </button>
                </div>
                
              </div>
              
            </div>
          )}
        </div>
      ))}
   
    </div>

    <Navigation/>
   </>
  );
};

export default FlashSale;
