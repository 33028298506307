import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

function Monitor() {
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const db = firebase.firestore();
                const snapshot = await db.collection('Sosmed').get();
                const requestsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRequests(requestsData);
            } catch (error) {
                console.error('Error fetching requests:', error);
            }
        };

        fetchRequests();
    }, []);

    const addStatusToStepsCollection = async () => {
        try {
            const db = firebase.firestore();
            const stepsRef = db.collection('Steps');

            // Ambil semua dokumen di koleksi Steps
            const snapshot = await stepsRef.get();

            // Perbarui setiap dokumen dengan menambahkan properti status: true
            const batch = db.batch();
            snapshot.docs.forEach(doc => {
                const docRef = stepsRef.doc(doc.id);
                batch.update(docRef, { status: true });
            });

            // Komitkan perubahan pada batch
            await batch.commit();

            console.log('Status added to Steps collection successfully.');
        } catch (error) {
            console.error('Error adding status to Steps collection:', error);
        }
    };

    return (
        <div className="max-w-screen-lg mx-auto mt-8 p-4 bg-white shadow-md rounded-lg overflow-x-auto">
            <h2 className="text-2xl font-semibold mb-4">Monitor Social Media Data Collection</h2>
            
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 hidden sm:table">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Social Media</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Package</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username/Link</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>

                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {requests.map(request => (
                            <tr key={request.id} className="table-row">
                                <td className="px-6 py-4 whitespace-nowrap">{request.id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{request.selectedSocialMedia}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{request.selectedService}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{request.selectedPackage}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{request.quantity}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{request.totalPrice}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{request.usernameLink}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <button
                                        onClick={() => addStatusToStepsCollection(request.id)}
                                        className="text-indigo-600 hover:text-indigo-900"
                                    >
                                        Add Status to Steps
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* Tampilan untuk layar kecil */}
                <div className="sm:hidden">
                    {requests.map(request => (
                        <div key={request.id} className="p-4 border-b border-gray-200">
                            <div className="font-bold">ID: {request.id}</div>
                            <div>Social Media: {request.selectedSocialMedia}</div>
                            <div>Service: {request.selectedService}</div>
                            <div>Package: {request.selectedPackage}</div>
                            <div>Quantity: {request.quantity}</div>
                            <div>Price: {request.totalPrice}</div>
                            <div>Username/Link: {request.usernameLink}</div>
                            
                        </div>
                    ))}
                </div>
         
            </div>
        </div>
    );
}

export default Monitor;
