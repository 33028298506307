import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getDatabase, ref, get } from "firebase/database";
import { motion } from "framer-motion";

const IsResponse = () => {
  const [requests, setRequests] = useState([]);
  const [responses, setResponses] = useState({});
  const [allowedUid, setAllowedUid] = useState([]);

  useEffect(() => {
    const db = firebase.firestore();

    const unsubscribe = db.collection("isrequest").onSnapshot((snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        const requestData = doc.data();
        if (requestData.isHiddenField === false) {
          data.push({
            id: doc.id,
            ...requestData,
          });
        }
      });
      setRequests(data);
    });

    const database = getDatabase();
    const allowedUidRef = ref(database, 'notifications/allowedUid');

    get(allowedUidRef).then((snapshot) => {
      if (snapshot.exists()) {
        const allowedUidList = snapshot.val();
        setAllowedUid(allowedUidList);
      }
    }).catch((error) => {
      console.error("Error:", error);
    });

    return () => unsubscribe();
  }, []);

  const handleResponseSubmit = async (e, requestId) => {
    e.preventDefault();

    const user = firebase.auth().currentUser;

    if (!user || !allowedUid.includes(user.uid)) {
      alert("Akses ditolak!");
      return;
    }

    const db = firebase.firestore();

    try {
      await db.collection("isrequest").doc(requestId).update({
        isHiddenField: true,
        response: responses[requestId] || "",
      });

      alert("Tanggapan berhasil disimpan!");
    } catch (error) {
      console.error("Error updating request:", error);
      alert("Terjadi kesalahan. Silakan coba lagi.");
    }
  };

  if (!firebase.auth().currentUser || !allowedUid.includes(firebase.auth().currentUser.uid)) {
    return null;
  }

  const handleDeleteRequest = async (requestId) => {
    const db = firebase.firestore();
  
    try {
      await db.collection("isrequest").doc(requestId).delete();
      alert("Permintaan berhasil dihapus!");
    } catch (error) {
      console.error("Error deleting request:", error);
      alert("Terjadi kesalahan saat menghapus permintaan. Silakan coba lagi.");
    }
  };

  return (
    <div className="bg-gray-900 text-white mt-20 ">
  
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mb-20">
      {requests.map((request) => (
  <motion.div
    key={request.id}
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white text-gray-900 shadow-lg rounded p-6"
  >
    <h3 className="text-2xl font-bold mb-2">{request.category}</h3>
    <p className="text-lg mb-4">{request.details}</p>
    <p className="text-lg mb-4">{request.nomorWhatsApp}</p>
    <p className="text-lg mb-4">{request.namaPemesan}</p>
    
    {request.imageUrl && (
  <img src={request.imageUrl} alt="Gambar" className="mb-4 rounded-lg" />
)}


    <form onSubmit={(e) => handleResponseSubmit(e, request.id)} className="">
      <label htmlFor={`response_${request.id}`} className="text-lg">Tanggapan:</label>
      <input
        className="border rounded py-2 px-3 w-full mb-2"
        type="text"
        id={`response_${request.id}`}
        value={responses[request.id] || ""}
        onChange={(e) => setResponses({ ...responses, [request.id]: e.target.value })}
      />
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition w-full text-lg"
        type="submit"
      >
        Setuju
      </button>
    </form>
    <button
      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition w-full text-lg mt-4"
      onClick={() => handleDeleteRequest(request.id)}
    >
      Hapus Permintaan
    </button>
  </motion.div>
))}

      </div>
    </div>
  );
};

export default IsResponse;
