import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; // Import firebase/auth untuk autentikasi
import PaymentComponent from './PaymentComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import Navigation from './Navigation';
import Navbara from './Navbara'
const DetailKelas = () => {
  const { permalink } = useParams();
  const [kelas, setKelas] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [activeMenu, setActiveMenu] = useState('about');
  const [showPayment, setShowPayment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null); // State untuk menyimpan informasi pengguna





  const bankAccount = '1234-5678-9012-3456';


  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);
  useEffect(() => {
    const fetchKelas = async () => {
      try {
        const kelasDoc = await firebase.firestore().collection('classes').where('permalink', '==', permalink).get();
        if (!kelasDoc.empty) {
          const kelasData = kelasDoc.docs[0].data();
          setKelas(kelasData);
          setLoading(false);
        } else {
          console.log('Kelas tidak ditemukan');
          setLoading(false);
        }
      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data kelas:', error);
        setLoading(false);
      }
    };

    fetchKelas();

    // Listener untuk perubahan status autentikasi pengguna
    const authListener = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });

    // Membersihkan listener saat komponen unmount
    return () => {
      authListener();
      setKelas(null);
    };
  }, [permalink]);

  const [showWarning, setShowWarning] = useState(false); // Memindahkan pemanggilan useState ke sini

  const handleItemClick = (index) => {
    if (index >= 1) {
      setShowWarning(true);
      setTimeout(() => {
        setShowWarning(false);
      }, 4000); // Hapus popup setelah 3 detik
    }
  };

  const formatToRupiah = (price) => {
    // Ubah string harga menjadi angka
    const numericPrice = parseFloat(price);
    
    // Format angka menjadi format mata uang Rupiah
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(numericPrice);
  };

  // Handler untuk menampilkan komponen pembayaran
  const handleShowPayment = () => {
    if (user) {
      setShowPayment(true);
    } else {
      // Jika pengguna belum login, arahkan ke proses login menggunakan Google
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider)
        .then((result) => {
          // Jika berhasil login, setShowPayment(true) dapat juga dilakukan di sini jika perlu
          console.log('Berhasil login dengan Google:', result.user);
        })
        .catch((error) => {
          console.error('Gagal melakukan login dengan Google:', error);
        });
    }
  };

  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!kelas) {
    return <p>Kelas tidak ditemukan.</p>;
  }


  
  return (
    <>
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} `}>

    <Navbara/>
    <div className="max-w-screen-xl mx-auto px-2 py-6 -mt-4">
      <div className=" rounded-lg overflow-hidden shadow-md">
      {kelas.videoURL && (
  <div className="w-full h-64 overflow-hidden">
    <video
      className="w-full h-full object-cover object-center"
      controls
      src={kelas.videoURL}
    ></video>
  </div>
)}


        <div className="p-4 bg-black">
          <div className="flex justify-between items-center">
            <div>
            <h1 className="text-base font-semibold text-white">{kelas.title }</h1>
            {kelas.discountedPrice && (
        <div>
          <p className="text-gray-700 text-sm line-through">Harga: {formatToRupiah(kelas.price)}<span>0</span></p>
        </div>
      )}
      <p className="text-sm  text-green-500 font-bold">Discount: {formatToRupiah(kelas.discountedPrice ? kelas.discountedPrice : kelas.price)}<span>0</span></p>
            </div>
      <div>

              <div className="flex items-center mt-2">
                <svg className="w-6 h-6 text-yellow-500 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M17.52 7.416l-4.896-.714-2.192-4.447c-.3-.607-1.323-.607-1.623 0l-2.192 4.447-4.896.714c-.662.097-.929.854-.458 1.285l3.55 3.462-.839 4.899c-.114.666.561 1.181 1.136.852l4.398-2.312 4.398 2.312c.575.302 1.25-.186 1.136-.852l-.839-4.899 3.55-3.462c.472-.46.204-1.188-.458-1.285z" clipRule="evenodd" />
                </svg>
                <p className="text-sm text-yellow-500">{kelas.rating}</p>
              </div>

    </div>
          </div>
        </div>
        <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} p-4`}>

          <div className="flex justify-between border-b border-gray-200 pb-2">
            <div className={`py-2 cursor-pointer ${activeMenu === 'about' ? 'border-b-2 border-blue-500' : ''}`} onClick={() => setActiveMenu('about')}>
              <p className="text-base font-semibold">About</p>
            </div>
            <div className={`py-2 cursor-pointer ${activeMenu === 'lesson' ? 'border-b-2 border-blue-500' : ''}`} onClick={() => setActiveMenu('lesson')}>
              <p className="text-base font-semibold">Lesson</p>
            </div>
            <div className={`py-2 cursor-pointer ${activeMenu === 'tools' ? 'border-b-2 border-blue-500' : ''}`} onClick={() => setActiveMenu('tools')}>
              <p className="text-base font-semibold">Tools</p>
            </div>
          </div>

          <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} mt-4`}>
          {activeMenu === 'about' && (
  <>

    <p className="">{kelas.description}</p>
  </>
)}
  {showWarning && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-lg">
          <div className="bg-gray-800 text-white p-4 rounded-md shadow-lg text-center">

          <div>
    <p className="text-lg">Untuk Mendapatkan Akses Video</p>
    <p className="text-lg">Harus Mendaftar Sebagai Siswa</p>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={handleShowPayment}>Daftar Kelas</button>
</div>
      </div>
        </div>
      )}

{activeMenu === 'lesson' && kelas && kelas.optionalInputs && kelas.optionalInputs.length > 0 && (
  <>
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} mt-4 space-y-2`}>
      {kelas.optionalInputs.map((input, index) => (
        <div key={index} className="flex items-center" style={{ background: index === 0 ? '#45ad37' : '#', padding: '10px', borderRadius: '8px' }} onClick={() => handleItemClick(index)}>
          <p className="ml-2">{input}</p>
          {index === 0 ? (
            <FontAwesomeIcon icon={faUnlockAlt} className="h-4 w-4 text-black ml-auto" />
          ) : (
            (index === 1 || index >= 2) && (
              <FontAwesomeIcon icon={faLock} className="h-4 w-4 text-gray-700 ml-auto" />
            )
          )}
        </div>
      ))}
    </div>
    {kelas.optionalInputs.length > 2 && (
      <div className="animate-pulse mt-4">
        <p className="text-gray-500 pl-5">Daftar kelas untuk membuka lebih banyak playlist..</p>
      </div>
    )}
  </>
)}
  {activeMenu === 'tools' && kelas && kelas.tools && kelas.tools.toolsUsed && kelas.tools.toolsUsed.length > 0 && (
        <>
          <ul className="mt-4 divide-y divide-gray-200">
            {kelas.tools.toolsUsed.map((tool, index) => (
              <li  className="py-4 flex justify-between items-center">
                <span className="">{tool}</span>
                {index === 0 ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-black">Required</span> : <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-gray-800">Required</span>}
              </li>
            ))}
          </ul>
        </>
      )}
      
      
      
          </div>
        </div>
<div className='mb-16 mt-4 p-4'>
          <button
            onClick={handleShowPayment}
            className="bg-green-700 hover:bg-gray-300 text-white py-2 px-4 rounded-md w-full md:w-auto"
          >
            {user ? 'Daftar Kelas' : 'Login Dulu untuk Daftar'}
          </button>
        </div>
      </div>

      {showPayment && (
        <PaymentComponent
          bankAccount={bankAccount}
          permalink={permalink}
        />
      )}
    </div>
    </div>
    <Navigation/>
    
    </>
  );
};

export default DetailKelas;
