import React, { useState } from "react";
import { getDatabase, ref, set, get } from "firebase/database";

const AddAllowedUidForm = () => {
  const [newAllowedUid, setNewAllowedUid] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const database = getDatabase();
    const allowedUidRef = ref(database, 'notifications/allowedUid');

    try {
      const snapshot = await get(allowedUidRef);
      const allowedUidArray = snapshot.exists() ? [...snapshot.val(), newAllowedUid] : [newAllowedUid];
      await set(allowedUidRef, allowedUidArray);
      setNewAllowedUid("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className="bg-white rounded-lg p-6 shadow-lg">
      <div className="mb-4">
        <label htmlFor="allowedUid" className="block text-gray-700 font-bold mb-2">
          Masukkan allowedUid baru:
        </label>
        <input
          id="allowedUid"
          type="text"
          value={newAllowedUid}
          onChange={(e) => setNewAllowedUid(e.target.value)}
          className="w-full py-2 px-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
      </div>
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Tambah AllowedUid
      </button>
    </form>
  );
};

export default AddAllowedUidForm;
