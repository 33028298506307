import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

library.add(fas);

function Mentahan({ title, items }) {
  const [showSwipeText, setShowSwipeText] = useState(true);
  const [autoScroll, setAutoScroll] = useState(false); // State untuk autoscroll
  const [currentSlide, setCurrentSlide] = useState(0); // State untuk melacak slide saat ini
  const sliderRef = useRef(null); // Ref untuk slider

  useEffect(() => {
    const database = firebase.database();
    const swipeRef = database.ref("notifications/swipe");

    swipeRef.on("value", (snapshot) => {
      const swipeValue = snapshot.val();
      setShowSwipeText(swipeValue);
    });

    return () => {
      swipeRef.off();
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: autoScroll, // Mengatur autoplay sesuai state autoscroll
    autoplaySpeed: 2000,
    arrows: false,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  useEffect(() => {
    if (autoScroll && sliderRef.current) {
      // Lakukan scroll otomatis ke slide berikutnya jika autoscroll aktif
      sliderRef.current.slickNext();
    }
  }, [autoScroll]);

  const handleScrollClick = () => {
    if (sliderRef.current) {
      // Memeriksa apakah sliderRef ada sebelum memanggil slickNext
      sliderRef.current.slickNext(); // Gulir ke slide berikutnya
    }
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} p-4`}>

      <h2 className="text-xl font-medium">{title}</h2>
      <Slider ref={sliderRef} {...settings}>
        {items.map((item, index) => (
          <div
            key={item.id}
            className={`slider-item ${index === currentSlide ? "active" : ""}`}
          >
            <div className="flex flex-col items-center justify-center text-gray-700">
              <Link
                to={item.link}
                className="flex flex-col items-center text-gray-500 hover:text-black transition-colors duration-200"
              >
                <div className="bg-white-200 rounded-full p-3 mb-2" id="element1">
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="text-2xl text-green-700"
                  />
                </div>
                <span className="text-sm">{item.title}</span>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
      {showSwipeText && (
        <div
          className="text-center mt-4 animate-fade-out text-gray-500"
          onClick={handleScrollClick}
          style={{ cursor: "pointer" }} // Menambahkan style cursor pointer agar terlihat dapat diklik
        >
          <span className="text-sm font-bold text-gradient shadow-md">
        Libur sekolah? coba sekolah di kelasskill          </span>
          <FontAwesomeIcon
            icon={["fas", "angle-double-right"]}
            className="text-green-700 text-sm ml-1 animate-pulse"
          />
        </div>
      )}
    </div>
  );
}

export default Mentahan;

