import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faBalanceScale } from '@fortawesome/free-solid-svg-icons';

import { Link, Route, Routes,   } from "react-router-dom";


import ReactJoyride, { STATUS } from "react-joyride";
import { useLocation } from "react-router-dom";
import ToggleComponent from './ToggleComponent';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const CoinBalance = ({ balance }) => {
  const location = useLocation();
  const [steps, setSteps] = useState([
    {
      target: "#element1",
      content: "Temukan kategorimu disini",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#element2",
      content: "Toko Online Jual & Beli",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#element3",
      content: "Halaman Menu dibawah",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#element4",
      content: "Topup Pulsa/Paket/Voucher",
      placement: "bottom",
      disableBeacon: true,
    },
  ]);

  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
  };

  const customStyles = {
    options: {
      arrowColor: "#333",
      backgroundColor: "linear-gradient(to bottom right, rgba(255, 23, 255, 0.5), rgba(255, 33, 255, 0.2))", // Modify the background gradient here
      overlayColor: "rgba(0, 0, 0, 0.5)",
      primaryColor: "#f50125",
      textColor: "#333",
      maxWidth: "80vw", // Adjust the text width relative to the viewport width
    },
    buttonBack: {
      backgroundColor: "#e5e7eb",
      color: "#333",
    },
    buttonClose: {
      backgroundColor: "#e5e7eb",
      color: "#333",
    },
    buttonNext: {
      backgroundColor: "#6b08cf",
      color: "#fff",
      hoverBackgroundColor: "#4338ca",
    },
    tooltip: {
      backgroundColor: "#ffffff",
      borderRadius: "0.5rem",
      color: "#333",
      padding: "1rem",
    },
    tooltipContainer: {
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "-10px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "20px",
        height: "20px",
        backgroundColor: "#00ff73",
        borderRadius: "50%",
        zIndex: "-1",
      },
    },
    tooltipTitle: {
      fontWeight: "bold",
      fontSize: "1.2rem",
    },
    tooltipContent: {
      fontSize: "1rem",
      marginTop: "1rem",
    },
    tooltipArrow: {
      color: "#de0495",
    },
    highlight: {
      backgroundColor: "#5eff00 !important",
    },
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);


  return (

    <section className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} p-4 py-4 px-4 md:px-6 lg:px-8 flex justify-between items-cente`}>

    


<div className="bg-gray-700 text-white border border-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-300 dark:hover:bg-black-300 dark:focus:ring-black-800 py-2 px-3 rounded-md flex items-center">
    <FontAwesomeIcon icon={faCreditCard} className="text-2xl text-white mr-2" />
    <div>
        <Link to="/kelas">
            <p className="font-medium text-lg mb-1 text-white" id='element4'>KURSUS</p>
        </Link>
        <p className="font-semibold text-xl text-white">{balance}</p>
    </div>
</div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-0 md:mt-0">
      <a href="#"
            className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center bg-gradient-to-r from-purple-500 to-indigo-600 rounded-md opacity-100 focus:ring-gray-100 text-white dark:text-white"
            style={{
              background: 'linear-gradient(to right, #7ce08e, #5bc37d, #3ea76f, #238c6b, #00796b, #5bc37d)',
              backgroundSize: '200% 100%',
              backgroundPosition: 'right center',
              transition: 'background-position 0.5s',
            }}
            
            onMouseEnter={(e) => {
              e.target.style.backgroundPosition = 'left center';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundPosition = 'right center';
            }}
            onClick={() => setRun(false)}
          >
      

<ToggleComponent/>        
            
        
 
         </a>
        
      </section>
      <ReactJoyride
        steps={steps}
        run={run}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        disableCloseOnEsc={true} // prevents closing tour on ESC key
        styles={customStyles}
        locale={{
          back: "Back",
          close: "Close",
          last: "Finish",
          next: "Next",
          skip: "Skip",
        }}
        callback={handleJoyrideCallback}
      />
    </section>
  );
};

export default CoinBalance;

