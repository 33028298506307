import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const AddCategoryForm = () => {
  const [icon, setIcon] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editIcon, setEditIcon] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [editLink, setEditLink] = useState('');

  useEffect(() => {
    const categoriesRef = firebase.database().ref('notifications/categories');
    categoriesRef.on('value', (snapshot) => {
      const categories = snapshot.val();
      const categoryList = [];
      for (let id in categories) {
        categoryList.push({ id, ...categories[id] });
      }
      setCategories(categoryList);
    });
  }, []);

  const handleIconChange = (e) => {
    setIcon(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const categoriesRef = firebase.database().ref('notifications/categories');
    const newCategoryRef = categoriesRef.push();
    newCategoryRef.set({
      icon: icon,
      title: title,
      link: link,
    });

    setIcon('');
    setTitle('');
    setLink('');
  };

  const handleEdit = (id, icon, title, link) => {
    setEditMode(true);
    setEditId(id);
    setEditIcon(icon);
    setEditTitle(title);
    setEditLink(link);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditId(null);
    setEditIcon('');
    setEditTitle('');
    setEditLink('');
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const categoryRef = firebase.database().ref(`notifications/categories/${editId}`);
    categoryRef.update({
      icon: editIcon,
      title: editTitle,
      link: editLink,
    });

    handleCancelEdit();
  };

  const handleDelete = (id) => {
    const categoryRef = firebase.database().ref(`notifications/categories/${id}`);
    categoryRef.remove();
  };

  const moveItemUp = (index) => {
    if (index > 0 && index < categories.length) {
      const updatedCategories = [...categories];
      const currentItem = updatedCategories[index];
      updatedCategories.splice(index, 1);
      updatedCategories.splice(index - 1, 0, currentItem);
      setCategories(updatedCategories);
  
      // Update Firebase with the new order
      const categoriesRef = firebase.database().ref('notifications/categories');
      categoriesRef.set(updatedCategories);
    }
  };
  
  const moveItemDown = (index) => {
    if (index >= 0 && index < categories.length - 1) {
      const updatedCategories = [...categories];
      const currentItem = updatedCategories[index];
      updatedCategories.splice(index, 1);
      updatedCategories.splice(index + 1, 0, currentItem);
      setCategories(updatedCategories);
  
      // Update Firebase with the new order
      const categoriesRef = firebase.database().ref('notifications/categories');
      categoriesRef.set(updatedCategories);
    }
  };
  
  return (
    <div className="bg-white p-6">
      <h2 className="text-2xl font-semibold mb-4">
        {editMode ? 'Edit Kategori' : 'Tambah Kategori Baru'}
      </h2>
      {editMode ? (
        <form onSubmit={handleUpdate} className="mb-6">
          <div className="mb-4">
            <label className="block text-black">
              Icon URL:
              <input
                type="text"
                value={editIcon}
                onChange={(e) => setEditIcon(e.target.value)}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-black">
              Title:
              <input
                type="text"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-black">
              Link:
              <input
                type="text"
                value={editLink}
                onChange={(e) => setEditLink(e.target.value)}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </label>
          </div>
          <button type="submit" className="bg-green-500 text-black px-4 py-2 rounded-md mr-2">
            Simpan Perubahan
          </button>
          <button onClick={handleCancelEdit} className="bg-gray-500 text-black px-4 py-2 rounded-md">
            Batal
          </button>
        </form>
      ) : (
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-4">
            <label className="block text-black">
              Icon URL:
              <input
                type="text"
                value={icon}
                onChange={handleIconChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-black">
              Title:
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-black">
              Link:
              <input
                type="text"
                value={link}
                onChange={handleLinkChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </label>
          </div>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Tambah Kategori
          </button>
        </form>
      )}

      <ul>
        {categories.map((category, index) => (
          <li key={category.id} className="flex justify-between items-center mb-2">
            <span className="text-black">{category.title}</span>
            <div className="space-x-2">
              <button
                onClick={() => handleEdit(category.id, category.icon, category.title, category.link)}
                className="bg-blue-500 text-white px-3 py-1 rounded-md"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(category.id)}
                className="bg-red-500 text-white px-3 py-1 rounded-md"
              >
                Hapus
              </button>
              <button onClick={() => moveItemUp(index)} className="bg-green-500 text-white px-3 py-1 rounded-md">
                Move Up
              </button>
              <button onClick={() => moveItemDown(index)} className="bg-yellow-500 text-white px-3 py-1 rounded-md">
                Move Down
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddCategoryForm;
