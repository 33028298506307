import React, { useState } from 'react';

const IncomeVisualization = () => {
  // Data contoh
  const [incomeData, setIncomeData] = useState([
    { source: 'Job', amount: 3000 },
    { source: 'Investments', amount: 1500 },
    { source: 'Side Hustle', amount: 500 },
  ]);

  // State untuk menyimpan kondisi tertentu (misalnya, kondisi "lowIncome")
  const [lowIncome, setLowIncome] = useState(false);

  // Fungsi untuk memperbarui data penghasilan
  const updateIncome = (source, newAmount) => {
    const updatedData = incomeData.map(item =>
      item.source === source ? { ...item, amount: newAmount } : item
    );
    setIncomeData(updatedData);
  };

  // Fungsi untuk menandai kondisi tertentu (misalnya, "lowIncome")
  const markLowIncome = () => {
    // Misalnya, kita menandai kondisi low income jika total penghasilan kurang dari 2000
    const totalIncome = incomeData.reduce((acc, item) => acc + item.amount, 0);
    setLowIncome(totalIncome < 2000);
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold mb-4">Income Visualization</h1>
      <div className="grid grid-cols-3 gap-4">
        {incomeData.map((item, index) => (
          <div key={index} className="p-4 border border-gray-300 rounded">
            <h2 className="text-xl font-bold mb-2">{item.source}</h2>
            <p className="text-gray-600">Amount: ${item.amount}</p>
          </div>
        ))}
      </div>
      <button
        onClick={markLowIncome}
        className={`mt-4 px-4 py-2 bg-blue-500 text-white rounded ${
          lowIncome ? 'bg-red-500' : ''
        }`}
      >
        Mark Low Income
      </button>
      {lowIncome && (
        <p className="mt-2 text-red-500">
          Total income is less than $2000. This is considered low income.
        </p>
      )}
      <div className="mt-4">
        <h2 className="text-xl font-bold mb-2">Update Income</h2>
        {incomeData.map((item, index) => (
          <div key={index} className="flex items-center mb-2">
            <label className="mr-2">{item.source}:</label>
            <input
              type="number"
              value={item.amount}
              onChange={(e) => updateIncome(item.source, parseInt(e.target.value))}
              className="border border-gray-400 rounded px-2 py-1"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncomeVisualization;
