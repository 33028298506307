 import React, { useState, useEffect, useRef } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import QRCode from 'qrcode.react'; // Import QRCode library

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const storage = firebase.storage();

const EditQR = () => {
  const [contacts, setContacts] = useState([]);
  const qrCanvasRef = useRef(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const contactsRef = firestore.collection('contacts');
        const snapshot = await contactsRef.get();

        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }

        let fetchedContacts = [];
        snapshot.forEach(doc => {
          fetchedContacts.push({ id: doc.id, ...doc.data() });
        });

        setContacts(fetchedContacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, []);

  const handleEdit = (id) => {
    const updatedContacts = contacts.map(contact => {
      if (contact.id === id) {
        return { ...contact, isEditing: true };
      }
      return contact;
    });
    setContacts(updatedContacts);
  };

  const handleSave = async (id, updatedData) => {
    try {
      const contactRef = firestore.collection('contacts').doc(id);
      await contactRef.update(updatedData);
      console.log('Document successfully updated!');
      const updatedContacts = contacts.map(contact => {
        if (contact.id === id) {
          return { ...contact, ...updatedData, isEditing: false };
        }
        return contact;
      });
      setContacts(updatedContacts);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const handleChange = (id, field, value) => {
    const updatedContacts = contacts.map(contact => {
      if (contact.id === id) {
        return { ...contact, [field]: value };
      }
      return contact;
    });
    setContacts(updatedContacts);
  };

  const uploadQRCode = async (id) => {
    const canvas = qrCanvasRef.current.querySelector(`canvas[data-id='${id}']`);
    const url = canvas.toDataURL("image/png");
    const blob = await fetch(url).then(res => res.blob());
    const storageRef = storage.ref();
    const qrStorageRef = storageRef.child(`qrcodes/${id}.png`);
    
    try {
      await qrStorageRef.put(blob);
      const downloadURL = await qrStorageRef.getDownloadURL();
      console.log('File available at', downloadURL);

      // Update Firestore document with the download URL
      const contactRef = firestore.collection('contacts').doc(id);
      await contactRef.update({ qrCodeURL: downloadURL });

      const updatedContacts = contacts.map(contact => {
        if (contact.id === id) {
          return { ...contact, qrCodeURL: downloadURL };
        }
        return contact;
      });
      setContacts(updatedContacts);

    } catch (error) {
      console.error('Error uploading QR code:', error);
    }
  };

  return (
    <>
      <div>
        <h2>Daftar Kontak</h2>
        <ul>
          {contacts.map(contact => (
            <li key={contact.id}>
              {contact.isEditing ? (
                <div>
                  {Object.keys(contact).map(key => (
                    <div key={key}>
                      <label>{key}</label>:
                      <input
                        type="text"
                        value={contact[key]}
                        onChange={(e) => handleChange(contact.id, key, e.target.value)}
                      />
                    </div>
                  ))}
                  <button onClick={() => handleSave(contact.id, contact)}>
                    Save
                  </button>
                </div>
              ) : (
                <div>
                  <pre>{JSON.stringify(contact, null, 2)}</pre>
                  {/* Example: Generate QR code for entire contact */}
                  <div ref={qrCanvasRef}>
                    <QRCode value={JSON.stringify(contact)} data-id={contact.id} />
                  </div>
                  <button onClick={() => handleEdit(contact.id)}>
                    Edit
                  </button>
                  <button onClick={() => uploadQRCode(contact.id)}>
                    Upload QR
                  </button>
                  {contact.qrCodeURL && (
                    <div>
                      <a href={contact.qrCodeURL} target="_blank" rel="noopener noreferrer">View QR Code</a>
                    </div>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default EditQR;
