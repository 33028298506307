import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
    apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
    authDomain: "kelasskilla.firebaseapp.com",
    databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
    projectId: "kelasskilla",
    storageBucket: "kelasskilla.appspot.com",
    messagingSenderId: "785364290918",
    appId: "1:785364290918:web:1da135595c6cea24be4ca7",
    measurementId: "G-R4L1YVZSTB"
  };

firebase.initializeApp(firebaseConfig);

const HighestTransaction = () => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const db = firebase.database();
        const transactionsRef = db.ref('notifications/transactions');
  
        transactionsRef.on('value', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const transactionData = Object.entries(data).map(([key, value]) => {
              return { id: key, ...value }; // Adding the 'id' property
            });
            setTransactions(transactionData);
          }
        });
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
  
    fetchTransactions();
  }, []);
  

  useEffect(() => {
    const filteredTransactions = selectedCategory !== 'All'
      ? transactions.filter(transaction => transaction.category === selectedCategory)
      : transactions;

    const transactionLabels = filteredTransactions.map((transaction) => transaction.item);
    const transactionAmounts = filteredTransactions.map((transaction) => transaction.amount);

    const ctx = chartContainer.current.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: transactionLabels,
        datasets: [
          {
            label: 'Volume Transaksi',
            data: transactionAmounts,
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }, [selectedCategory, transactions]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };  const handleDelete = (transactionId) => {
    try {
      const db = firebase.database();
      const transactionRef = db.ref(`notifications/transactions/${transactionId}`);
      transactionRef.remove();
    } catch (error) {
      console.error('Error deleting transaction:', error);
    }
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Transaksi Tertinggi Hari Ini</h1>
      <div className="flex items-center mb-4">
        <label htmlFor="categoryFilter" className="mr-2">Filter Kategori:</label>
        <select
          id="categoryFilter"
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="border border-gray-300 rounded-md p-1"
        >
          <option value="All">Semua Kategori</option>
          <option value="Electronics">Elektronik</option>
          <option value="Apparel">Pakaian</option>
          {/* Add more category options */}
        </select>
      </div>
      <div className="border border-gray-300 rounded-md shadow-md p-4">
        <h2 className="text-xl font-semibold mb-2">Grafik Volume Transaksi</h2>
        <div>
          <canvas ref={chartContainer} />
        </div>
        <div className="mt-4">
        <ul>
          {transactions.map((transaction) => (
            <li key={transaction.id}>
              <span>{transaction.item} - {transaction.amount}</span>
              <button onClick={() => handleDelete(transaction.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
      </div>
      <div className="mt-4">
        {/* Additional UI elements or information display can be added here */}
      </div>
    </div>
  );
};

export default HighestTransaction;
