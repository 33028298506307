 import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const UploadKelas = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null); // State untuk menyimpan video yang akan diunggah
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [category, setCategory] = useState('');
  const [suggestedCategories, setSuggestedCategories] = useState([]); 
  const [optionalInputs, setOptionalInputs] = useState([]);
  const [description, setDescription] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('');
  const [rating, setRating] = useState(null);
  const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
const [videoUploadProgress, setVideoUploadProgress] = useState(0);
const [toolsUsed, setToolsUsed] = useState([]);
const [newTool, setNewTool] = useState('');
const [editIndex, setEditIndex] = useState(null);

const handleAddTool = () => {
  if (newTool.trim() !== '') {
    if (editIndex !== null) {
      const updatedTools = [...toolsUsed];
      updatedTools[editIndex] = newTool.trim();
      setToolsUsed(updatedTools);
      setEditIndex(null);
    } else {
      setToolsUsed([...toolsUsed, newTool.trim()]);
    }
    setNewTool('');
  }
};

const handleEditTool = (index) => {
  setNewTool(toolsUsed[index]);
  setEditIndex(index);
};

const handleDeleteTool = (index) => {
  const updatedTools = [...toolsUsed];
  updatedTools.splice(index, 1);
  setToolsUsed(updatedTools);
};

  
  useEffect(() => {
    const fetchCategories = async () => {
      const classesCollection = await firebase.firestore().collection('classes').get();
      const categories = new Set();
      classesCollection.forEach(doc => {
        const data = doc.data();
        categories.add(data.category);
      });
      setSuggestedCategories(Array.from(categories));
    };

    fetchCategories();
  }, []);

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setVideo(file);
  };

  const handleAddOptionalInput = () => {
    setOptionalInputs([...optionalInputs, '']);
  };
  const handleRatingChange = (e) => {
    setRating(parseFloat(e.target.value));
  };
  

  const handleOptionalInputChange = (value, index) => {
    const updatedInputs = [...optionalInputs];
    updatedInputs[index] = value;
    setOptionalInputs(updatedInputs);
  };

  const handleUpload = () => {
    if (!thumbnail || !video || !title || !price || !discount || !category || !youtubeURL) {
      alert('Please fill all fields including YouTube URL and upload a video');
      return;
    }
  
    const user = firebase.auth().currentUser;
    if (!user) {
      alert('Please log in to upload.');
      return;
    }
  
    const discountedPrice = price - (price * (discount / 100));
  
    const { displayName, email, photoURL, uid } = user;
  
    const thumbnailRef = firebase.storage().ref(`thumbnails/${thumbnail.name}`);
    const videoRef = firebase.storage().ref(`videos/${video.name}`);
  
    // Upload thumbnail
    const thumbnailUploadTask = thumbnailRef.put(thumbnail);
  
    // Upload video
    const videoUploadTask = videoRef.put(video);
  
    // Track upload progress
    thumbnailUploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setThumbnailUploadProgress(progress);
      },
      (error) => {
        console.error("Error uploading thumbnail: ", error);
        alert("Upload failed");
      }
    );
    
    videoUploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setVideoUploadProgress(progress);
      },
      (error) => {
        console.error("Error uploading video: ", error);
        alert("Upload failed");
      }
    );
    
  
    // Similar for video upload progress
    videoUploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Video Upload is " + progress + "% done");
        // You can update UI with the progress here
      },
      (error) => {
        // Handle errors
        console.error("Error uploading video: ", error);
        alert("Upload failed");
      }
    );
  
    // After both uploads are complete
    Promise.all([thumbnailUploadTask, videoUploadTask])
      .then((snapshotArray) => {
        // Get download URLs
        const thumbnailDownloadURL = snapshotArray[0].ref.getDownloadURL();
        const videoDownloadURL = snapshotArray[1].ref.getDownloadURL();
  
        return Promise.all([thumbnailDownloadURL, videoDownloadURL]);
      })
      .then(([thumbnailURL, videoURL]) => {
        // Save class details to Firestore
        firebase
          .firestore()
          .collection("classes")
          .add({
            thumbnailURL,
            videoURL,
            title,
            description,
            price,
            discount,
            rating, // Menambah properti rating

            discountedPrice,
            category,
            permalink: generatePermalink(title),
            userId: uid,
            rated: false,
            optionalInputs,
            userProfile: {
              displayName,
              email,
              photoURL,
            },
            youtubeURL,
            tools: {
              toolsUsed // Hanya toolsUsed yang dimasukkan
            }
          })
          .then(() => {
            console.log("Class details saved successfully");
            // Reset form fields
            setThumbnail(null);
            setVideo(null);
            setTitle("");
            setPrice("");
            setDiscount("");
            setCategory("");
            setOptionalInputs([]);
            setDescription("");
            setYoutubeURL("");
            alert("Upload successful");
          })
          .catch((error) => {
            console.error("Error saving class details: ", error);
            alert("Upload failed");
          });
      })
      .catch((error) => {
        console.error("Error uploading files: ", error);
        alert("Upload failed");
      });
  };
  
  
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  const generatePermalink = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} max-w-xl mx-auto p-6`}>

      <h2 className="text-2xl font-bold mb-4">Upload Kelas</h2>
      <div>
  <label>Thumbnail Upload Progress:</label>
  <input type="file" accept="image/*" onChange={handleThumbnailChange} className="mb-4" />
  <progress value={thumbnailUploadProgress} max="100">{thumbnailUploadProgress}%</progress>


</div>
<div>
  
<div>
  <label>Video Upload Progress:</label>

  </div>
  <input type="file" accept="video/*" onChange={handleVideoChange} className="mb-4" />
  <progress value={videoUploadProgress} max="100">{videoUploadProgress}%</progress>
</div>


<select value={rating} onChange={handleRatingChange} className="border rounded px-3 py-2 mb-4 w-full text-black">
  <option value="">Pilih Rating</option>
  <option value="4.7">4.7</option>
  <option value="4.8">4.8</option>
  <option value="4.9">4.9</option>
</select>

      <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} className="border  rounded px-3 py-2 mb-4 w-full text-black" />
      <input type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} className="border  rounded px-3 py-2 mb-4 w-full text-black" />
      <input type="number" placeholder="Discount (%)" value={discount} onChange={(e) => setDiscount(e.target.value)} className="border  rounded px-3 py-2 mb-4 w-full text-black" />

      <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} className="border  rounded px-3 py-2 mb-4 w-full text-black" />
      <input type="text" placeholder="YouTube Embed URL" value={youtubeURL} onChange={(e) => setYoutubeURL(e.target.value)} className="border rounded px-3 py-2 mb-4 w-full text-black" />
      <input type="text" placeholder="Category" value={category} onChange={(e) => setCategory(e.target.value)} className="border  rounded px-3 py-2 mb-4 w-full text-black" />

      {optionalInputs.map((inputValue, index) => (
        <input key={index} type="text" placeholder={`Optional ${index + 1}`} value={inputValue} onChange={(e) => handleOptionalInputChange(e.target.value, index)} className="border  rounded px-3 py-2 mb-4 w-full text-black" />
      ))}
      
      {toolsUsed.map((tool, index) => (
          <div key={index}>
            <input
              type="text"
              value={tool}
              disabled={editIndex === index}
              className="border rounded px-3 py-2 mb-2 w-full text-black"
            />
            {editIndex === index ? (
              <button onClick={() => handleAddTool()} className="bg-blue-500 text-white py-2 px-4 rounded">Update</button>
            ) : (
              <button onClick={() => handleEditTool(index)} className="bg-blue-500 text-white py-2 px-4 rounded">Edit</button>
            )}
            <button onClick={() => handleDeleteTool(index)} className="bg-red-500 text-white py-2 px-4 rounded ml-2">Delete</button>
          </div>
        ))}
        <input
          type="text"
          placeholder="Add a new tool"
          value={newTool}
          onChange={(e) => setNewTool(e.target.value)}
          className="border rounded px-3 py-2 mb-2 w-full text-black"
        />
        <button onClick={handleAddTool} className="bg-blue-500 text-white py-2 px-4 rounded">Add Tools</button>
      <button onClick={handleAddOptionalInput} className="bg-green-500 text-white py-2 px-4 rounded ml-2">Add Lesson</button>

    
      
      {suggestedCategories.length > 0 && (
        <div className="mb-4">
          <p className="text-sm font-semibold mb-2">Suggested Categories:</p>
          <ul className="list-disc pl-5">
            {suggestedCategories.map((cat, index) => (
              <li key={index} onClick={() => setCategory(cat)} className="cursor-pointer text-blue-500">{cat}</li>
            ))}
          </ul>
        </div>
      )}
      
      {discount && price && (
        <p className="text-gray-600 mb-4">Harga setelah diskon: ${(price - (price * (discount / 100))).toFixed(2)}</p>
      )}
      
      <button onClick={handleUpload} className="bg-blue-500 text-white py-2 px-4 rounded mr-2">Upload</button>
    </div>
  );
};

export default UploadKelas;