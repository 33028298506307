import React, { useState } from 'react';
import Uploaduser from '../component/Uploaduser';
import AddCategoryForm from './worker/AddCategoryForm';
import HighestTransaction from './HighestTransaction';
import TransactionForm from './TransactionForm';
const HelloWorldComponent = () => {
  const [password, setPassword] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const correctPassword = 'qq'; // Ganti dengan kata sandi yang diinginkan

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setShowMessage(true);
    } else {
      alert('Kata sandi salah!'); // Ganti dengan tindakan yang ingin dilakukan jika kata sandi salah
    }
  };

  return (
    <div className='bg-green-50 mb-20'>
      <form onSubmit={handlePasswordSubmit}>
        {!showMessage && (
          <label>
           add
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        )}
        {!showMessage && (
          <button type="submit">subscribe</button>
        )}
      </form>
      {showMessage && <div className='bg-white'>  
 <HighestTransaction/><TransactionForm/><AddCategoryForm/>
<Uploaduser/>      
</div>}
    </div>
  );
};

export default HelloWorldComponent;
