import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Cloud from './Cloud';

const firebaseConfig = {
    apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
    authDomain: "kelasskilla.firebaseapp.com",
    databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
    projectId: "kelasskilla",
    storageBucket: "kelasskilla.appspot.com",
    messagingSenderId: "785364290918",
    appId: "1:785364290918:web:1da135595c6cea24be4ca7",
    measurementId: "G-R4L1YVZSTB"
  };
  

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Peserta = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaQuestion, setCaptchaQuestion] = useState('');

  useEffect(() => {
    generateCaptcha();
    checkAuthToken();
  }, []);

  const firestore = firebase.firestore();
  const memberRef = firestore.collection('loginqr').doc('member');
  const loginAttemptRef = firestore.collection('loginAttempts');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setError(null);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(null);
  };

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const question = `Berapa hasil dari ${num1} + ${num2}?`;
    const answer = num1 + num2;
    setCaptchaQuestion(question);
    setCaptchaValue(answer.toString());
  };

  const handleLoginAttempt = async () => {
    try {
      await loginAttemptRef.add({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        username,
        success: false,
      });
    } catch (error) {
      console.error('Error saving login attempt:', error);
    }
  };

  const handleSuccessfulLogin = () => {
    const authToken = {
      token: 'yourAuthTokenHere', // Ganti dengan token yang sesuai
      expiry: Date.now() + 3600000, // Contoh: token kadaluwarsa dalam 1 jam
    };
    localStorage.setItem('authToken', JSON.stringify(authToken));
    setIsLoggedIn(true);
  };

  const checkAuthToken = () => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      try {
        const authToken = JSON.parse(storedToken);
        if (authToken.expiry > Date.now()) {
          setIsLoggedIn(true);
        } else {
          handleLogout(); // Token kadaluwarsa, logout
        }
      } catch (error) {
        console.error('Error parsing authToken:', error);
        handleLogout(); // Format token tidak valid, logout
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loginAttempts >= 3) {
      setError('Anda telah mencoba login terlalu banyak. Silakan coba lagi nanti.');
      return;
    }

    if (captchaValue !== event.target.captchaAnswer.value) {
      setError('Jawaban CAPTCHA salah. Silakan coba lagi.');
      generateCaptcha();
      return;
    }

    setLoading(true);
    try {
      const doc = await memberRef.get();
      if (doc.exists) {
        const { username: expectedUsername, password: expectedPassword } = doc.data();
        if (username === expectedUsername && password === expectedPassword) {
          handleSuccessfulLogin();
        } else {
          setError('Username atau password salah');
          setLoginAttempts((prev) => prev + 1);
          await handleLoginAttempt();
          generateCaptcha(); // Regenerasi CAPTCHA setelah percobaan gagal
        }
      } else {
        setError('Dokumen tidak ditemukan');
      }
    } catch (error) {
      console.error('Error getting document:', error);
      setError('Terjadi kesalahan saat mengambil data');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="username" className="sr-only">Username</label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Username"
                  value={username}
                  onChange={handleUsernameChange}
                  disabled={loading}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  disabled={loading}
                />
              </div>
              <div>
                <p className="mt-2">{captchaQuestion}</p>
                <input
                  id="captchaAnswer"
                  name="captchaAnswer"
                  type="text"
                  autoComplete="off"
                  required
                  className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Jawaban CAPTCHA"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Login'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Cloud />

    </div>
  );
};

export default Peserta;
