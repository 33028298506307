import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import Navbara from './Navbara';
import Navigation from './Navigation';

const PaymentDataComponent = () => {
  const [payments, setPayments] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const paymentsSnapshot = await firebase.firestore().collection('payments').get();
        const paymentsData = paymentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPayments(paymentsData);
      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data pembayaran:', error);
      }
    };

    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    const unsubscribeAuth = firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    fetchPayments();
    fetchDarkModeSetting();

    return () => {
      unsubscribeAuth();
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  useEffect(() => {
    if (currentUser && payments.length > 0) {
      const hasValidPayment = payments.some(payment => payment.uid === currentUser.uid && payment.status);
      if (!hasValidPayment) {
        window.location.href = '/kelas'; // Mengarahkan kembali pengguna ke halaman /kelas jika tidak ada pembayaran yang sesuai
      }
    }
  }, [currentUser, payments]);

  return (
    <div className="bg-gray-100 dark:bg-gray-800 min-h-screen">
      <Navbara />
      <div className="max-w-3xl mx-auto px-4 py-8 mt-10">
  {currentUser && payments.map(payment => (
    payment.uid === currentUser.uid && payment.status && (
      <div key={payment.id} className="relative bg-white dark:bg-gray-700 shadow-md rounded-md p-4 mb-4 transform hover:scale-105 transition-transform duration-300">
        <h2 className="text-lg font-semibold mb-2 text-white bg-green-500 py-2 px-4 rounded-md absolute top-0 left-0 -mt-8 -ml-4">Data Pembayaran</h2>
        <p className="text-gray-600 dark:text-gray-300">You have:</p>
        <ul>
          <li className="text-gray-700 dark:text-gray-300">
            <strong>Judul Kelas:</strong> {payment.title}, <strong>Harga:</strong> {payment.price}, <strong>Status:</strong> {payment.status ? 'Sudah dibayar' : 'Belum dibayar'}
          </li>
        </ul>
      </div>
    )
  ))}
  {!currentUser && <p className="text-gray-600 dark:text-gray-300">Silakan login untuk melihat data pembayaran.</p>}
  {currentUser && payments.length === 0 && (
    <div className="bg-white dark:bg-gray-700 shadow-md rounded-md p-4">
      <p className="text-gray-700 dark:text-gray-300">Anda belum memiliki kelas.</p>
      <a href="/kelas" className="text-blue-500 hover:underline">Kembali</a>
    </div>
  )}
</div>

      <Navigation />
    </div>
  );
};

export default PaymentDataComponent;
