import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { FaLock } from "react-icons/fa";
import classNames from "classnames";
import designku from "../assets/designku.png";
const Syllabus = () => {
  const topics = [
    { title: "Pendahuluan dan pengenalan kursus", isLocked: true },
    { title: "Membuat elemen dasar: vektor, bentuk, dan lapisan", isLocked: true },
    { title: "Membuat wireframe dan integrasi visual", isLocked: true },
    { title: "Video lain..", isLocked: true },
  ];

  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);


  return (
    <>
        <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} rounded-lg shadow-lg p-4`}>

      <h2 className="text-1xl font-bold mb-4">Materi pelajaran</h2>
      <ul className="space-y-2">
        {topics.map((topic, index) => (
          <li
            key={index}
            className={classNames("flex items-center bg-black-700 p-2 rounded-lg ", {
              "opacity-70": topic.isLocked,
            })}
          >
            {topic.isLocked && <FaLock className="mr-2" />}
            <span>{topic.title}</span>
          </li>
        ))}
      </ul>

 
    </div>
         <div className="mt-3 ">
         <h2 className="text-1xl font-bold mb-4 text-center">Tentang Kursus</h2>
         <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
           <div>
             <button
               onClick={() => handleMenuClick("topics")}
               className={` hover:text-blue-700 ${
                 activeMenu === "topics" ? "" : ""
               }w-full`}
             >
               Topics
             </button>
             {activeMenu === "topics" && (
               <div>
             
                 <p className="blackspace-pre-wrap bg-green-700 rounded-lg">
                 "Temukan potensimu dalam dunia desain dan bergabunglah dalam Kursus Design Buat Aplikasi Gojek! Dapatkan keahlian yang sangat dibutuhkan dalam industri ini dan berkontribusilah dalam menciptakan pengalaman pengguna yang luar biasa. Ayo, daftar sekarang dan jadilah bagian dari revolusi desain aplikasi! Tingkatkan keterampilanmu dan buka pintu menuju kesuksesan kreatif
                 </p>
               </div>
             )}
           </div>
           <div>
             <button
               onClick={() => handleMenuClick("lesson")}
               className={` hover:text-blue-700 ${
                 activeMenu === "lesson" ? "" : ""
               }w-full`}
             >
               Lesson
             </button>
             {activeMenu === "lesson" && (
               <div>
               
                 <p className="blackspace-pre-wrap  bg-green-700 rounded-lg">
                  Kamu dapat melihat seluruh materi rancangan apabila telah membeli pelatihan ini
                 </p>
               </div>
             )}
           </div>
       
           <div>
             <button
               onClick={() => handleMenuClick("testimoni")}
               className={` hover:text-blue-700 ${
                 activeMenu === "testimoni" ? "" : ""
               }w-full`}
             >
               Showcase
             </button>
             {activeMenu === "testimoni" && (
               <div>
                 <img
                   src={designku}
                   alt="Testimoni"
                   className="w-full h-auto"
                 />
              
               </div>
             )}
           </div>
         </div>
       </div>
       </>
  );
};

export default Syllabus;
