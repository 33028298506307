import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

function Waiting() {
    const [isWaiting, setIsWaiting] = useState(true); // State untuk menentukan apakah pesanan masih dalam proses
    const [status, setStatus] = useState(false); // State untuk menampung status pesanan
    const [hasOrder, setHasOrder] = useState(true); // State untuk menentukan apakah ada pesanan

    useEffect(() => {
        const checkStatus = async () => {
            try {
                const db = firebase.firestore();
                const stepsRef = db.collection('Steps');
                const snapshot = await stepsRef.get();
                let foundOrder = false;

                snapshot.forEach(doc => {
                    if (doc.exists) {
                        const data = doc.data();
                        if (data.status) {
                            setStatus(true); // Jika status sudah true, pesanan selesai
                            foundOrder = true;
                        }
                    }
                });

                if (!foundOrder) {
                    setHasOrder(false); // Jika tidak ditemukan pesanan
                }

                setIsWaiting(false); // Setelah pengecekan selesai, matikan indikator waiting
            } catch (error) {
                console.error('Error checking status:', error);
            }
        };

        checkStatus();
    }, []);

    return (
        <div className="max-w-screen-lg mx-auto mt-8 mr-22 rounded-lg text-center text-white">
            {isWaiting ? (
                <p>Loading...</p>
            ) : (
                <p>
                    {hasOrder ? (
                        status ? "Pesanan Anda sudah selesai" : "Pesanan Anda dalam proses pengerjaan"
                    ) : (
                        "Kamu belum memiliki pesanan."
                    )}
                </p>
            )}
        </div>
    );
}

export default Waiting;