import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Navigation from './Navigation';
import Navbara from './Navbara';
import kelasskill from "../assets/kelasskill.png"

const Changelog = () => {
    const [rotateX, setRotateX] = useState(0);
    const [rotateY, setRotateY] = useState(0);
    const [rotateZ, setRotateZ] = useState(0);
    const [fov, setFOV] = useState(75);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [selectedView, setSelectedView] = useState('front'); // Default view is front
    const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 }); // State to hold the starting position of drag
    const [imageSize, setImageSize] = useState(200); // State to hold the size of the selected image
    const [isDragging, setIsDragging] = useState(false);

    const handleRotateX = (e) => {
        setRotateX(e.target.value);
    };

    const handleRotateY = (e) => {
        setRotateY(e.target.value);
    };

    const handleRotateZ = (e) => {
        setRotateZ(e.target.value);
    };

    const handleFOVChange = (e) => {
        setFOV(e.target.value);
    };

    const handleViewChange = (view) => {
        setSelectedView(view);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
    };

    const handleImageSizeChange = (e) => {
        setImageSize(e.target.value);
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
    };
    
    const handleMouseMove = (e) => {
        if (isDragging) {
            const deltaX = e.clientX - dragStart.x;
            const deltaY = e.clientY - dragStart.y;
            setPosition({ x: position.x + deltaX, y: position.y + deltaY });
            setDragStart({ x: e.clientX, y: e.clientY });
        }
    };
    
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleReset = () => {
        setRotateX(0);
        setRotateY(0);
        setRotateZ(0);
        setFOV(75);
        setPosition({ x: 0, y: 0 });
        setSelectedView('front');
        setSelectedImage(null);
        setImageSize(200);
        setIsDragging(false);
    };

    const handleShare = () => {
        // Logic to share the design
        alert('Sharing the design...');
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const shirtImageUrl = {
        front: 'https://i.ibb.co/5BqXX12/Group-6.png',
        back: 'https://i.ibb.co/Zc4G0QM/Group-7.png',
        right: 'https://i.ibb.co/TL23vrY/Group-8.png',
        left: 'URL_gambar_kiri', // URL gambar kiri
    };

       const [password, setPassword] = useState('');
    const [passwordCorrect, setPasswordCorrect] = useState(false);
    const handleSubmitPassword = () => {
        if (password === 'terberak') {
            setPasswordCorrect(true);
        } else {
            // Tambahkan pesan kesalahan jika kata sandi salah
            alert('Password salah. Silakan coba lagi.');
        }
    };
    
    if (!passwordCorrect) {
        return (
            <>
                <div className="min-h-screen flex items-center justify-center bg-black py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-md w-full space-y-8">
                        <div>
                        <h2 className="mt-6 text-center text-3xl font-bold text-white">
                        <img
        src={kelasskill}
        className="mx-auto mb-4 rounded-full w-32 h-32 object-cover border-4 border-white"
        alt="CEO Image"
      /> 404 Not Found
</h2>
                        </div>
                        <form className="mt-8 space-y-6" onSubmit={handleSubmitPassword}>
                            <div className="rounded-md shadow-sm -space-y-px">
                                <div>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        placeholder="Enter your password"
                                    />
                                </div>
                            </div>
    
                            {/* Tambahkan pesan kesalahan di bawah input */}
                            <p className="text-red-500 text-sm">{/* Tambahkan pesan kesalahan di sini */}</p>
    
                            <div>
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                        <svg
                                            className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                            <path fillRule="evenodd" d="M4 8V6a4 4 0 118 0v2h2a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V10a2 2 0 012-2h2zm6-2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </span>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
    

    return (
        <>
            <Navbara/>
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-4 mt-12">
                <div className="perspective-3d">
                    <div
                        className="transform-gpu transition-transform duration-500 w-full lg:w-64 h-64 bg-gray-300 rounded-lg"
                        style={{
                            transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg)`,
                            perspective: selectedView === 'front' ? `${fov}px` : 'none',
                            position: 'relative'
                        }}
                    >
                        <div
                            className="w-full h-full relative"
                        >
                            <img
                                src={shirtImageUrl[selectedView]}
                                alt="Kaos"
                                className="w-full h-full object-cover"
                            />
 <div className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md mt-2" role="alert">
      <div className="flex">
        <div className="py-1">
          <svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-2a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0-9a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1zm0 6a1 1 0 0 1-1-1V9a1 1 0 1 1 2 0v3a1 1 0 0 1-1 1z"/>
          </svg>
        </div>
        <div>
          <p className="font-bold">Custom Design</p>
          <p className="text-sm">Will come, Stay tuned for more updates!</p>
        </div>
      </div>
    </div>
                            {selectedView === 'front' && selectedImage && (
                                <div
                                    className="absolute"
                                    style={{ top: position.y, left: position.x, cursor: isDragging ? 'grabbing' : 'grab' }}
                                    onMouseDown={handleMouseDown}
                                >
                                    <img
                                        src={selectedImage}
                                        alt="Selected Image"
                                        style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
                                    />
                                </div>
                            )}

                            <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 999 }}>
                                <label htmlFor="upload" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50px', height: '50px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%', cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon={faPlus} size="2x" />
                                    <input type="file" id="upload" style={{ display: 'none' }} onChange={handleFileChange} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="-mt-4 lg:mt-0 bg-gray-100 p-4 rounded-lg shadow-lg">
    <div className="mb-1">
        <label htmlFor="rotateX" className="block font-semibold">Rotate X:</label>
        <input
            type="range"
            id="rotateX"
            min="0"
            max="360"
            value={rotateX}
            onChange={handleRotateX}
            className="block w-full bg-gray-300 rounded-lg overflow-hidden appearance-none h-5 mt-1"
        />
    </div>
    <div className="mb-4">
        <label htmlFor="rotateY" className="block font-semibold">Rotate Y:</label>
        <input
            type="range"
            id="rotateY"
            min="0"
            max="360"
            value={rotateY}
            onChange={handleRotateY}
            className="block w-full bg-gray-300 rounded-lg overflow-hidden appearance-none h-5 mt-1"
        />
    </div>
    <div className="mb-4">
        <label htmlFor="rotateZ" className="block font-semibold">Rotate Z:</label>
        <input
            type="range"
            id="rotateZ"
            min="0"
            max="360"
            value={rotateZ}
            onChange={handleRotateZ}
            className="block w-full bg-gray-300 rounded-lg overflow-hidden appearance-none h-5 mt-1"
        />
    </div>
    <div className="mb-4">
        <label htmlFor="imageSize" className="block font-semibold">Image Size:</label>
        <input
            type="number"
            id="imageSize"
            min="10"
            max="500"
            value={imageSize}
            onChange={handleImageSizeChange}
            className="block w-full bg-gray-300 rounded-lg p-2 mt-1"
        />
    </div>
    <div className="mb-4 flex flex-wrap gap-2 lg:gap-4">
    <button onClick={() => handleViewChange('front')} className="bg-blue-500 text-white px-4 py-2 rounded-lg flex-grow">Front</button>
    <button onClick={() => handleViewChange('back')} className="bg-blue-500 text-white px-4 py-2 rounded-lg flex-grow">Back</button>
    <button onClick={() => handleViewChange('right')} className="bg-blue-500 text-white px-4 py-2 rounded-lg flex-grow">Right</button>
    <button onClick={() => handleViewChange('left')} className="bg-blue-500 text-white px-4 py-2 rounded-lg flex-grow">Left</button>
</div>


    <div className="mb-4">
        <label htmlFor="color" className="block font-semibold">Warna:</label>
        <select id="color" className="block w-full bg-gray-300 rounded-lg p-2 mt-1">
            <option value="red">Merah</option>
            <option value="blue">Biru</option>
            <option value="green">Hijau</option>
        </select>
    </div>
    <div>
        <label htmlFor="size" className="block font-semibold">Ukuran:</label>
        <select id="size" className="block w-full bg-gray-300 rounded-lg p-2 mt-1">
            <option value="s">S</option>
            <option value="m">M</option>
            <option value="l">L</option>
            <option value="xl">XL</option>
        </select>
    </div>
</div>

            </div>
            <Navigation/>
        </>
    );
};

export default Changelog;
