import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, update, remove } from 'firebase/database';
import UserContext from './UserContext';
const Uploaduser = () => {
  const [uploadedData, setUploadedData] = useState([]);
  const [editData, setEditData] = useState('');
  const [editStatus, setEditStatus] = useState('');
  const [editKey, setEditKey] = useState(null);
  const [activeTab, setActiveTab] = useState('new');
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const allowedUserIds = ['Iius3XSd75gMGLhRz5XwCJcLlng2','GJ5uRaD6eFhUPgl1j1T48bdM3Ky2', 'YxeXN5KgRxfcOFdFEw8ULKUcKR92'];
  const [editTitle, setEditTitle] = useState('');
  const [editTimestamp, setEditTimestamp] = useState('');
  const [editImage, setEditImage] = useState('');

  useEffect(() => {
    fetchUploadedData();
  }, []);

  const fetchUploadedData = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        if (!allowedUserIds.includes(userId)) {
          // Redirect to home or show 404 message
          window.location.href = '/'; // Replace '/' with the desired home page URL
          return;
        }
  
        const database = getDatabase();
        const notificationsRef = ref(database, 'notifications/users');
  
        onValue(notificationsRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const updatedData = Object.entries(data).map(([key, value]) => {
              // Check if timestamp has passed
              if (new Date(value.timestamp).getTime() < Date.now() && value.status === 'false') {
                // Update status to 'true'
                value.status = 'true';
                // Update status in Firebase
                const notificationRef = ref(database, `notifications/users/${key}`);
                update(notificationRef, { status: 'true' });
              }
              return {
                key,
                ...value,
                timestamp: new Date(value.timestamp),
              };
            });
  
            setUploadedData(updatedData);
          }
        });
      }
    } catch (error) {
      console.error('Failed to fetch uploaded data:', error);
    }
  };
  
  const handleEdit = (key, data, status, title, author, timestamp, imageUrl) => {
    setEditData(data);
    setEditStatus(status);
    setEditKey(key);
    setEditTitle(title);
    setEditAuthor(author);
    setEditTimestamp(timestamp);
    setEditImage(imageUrl); // Set nilai state editImage
    setShowModal(true);
  };
  
  
  
  const handleSaveEdit = async () => {
    if (editKey) {
      const updatedData = uploadedData.map(item => {
        if (item.key === editKey) {
          return {
            ...item,
            title: editTitle,
            data: editData,
            status: editStatus,
            author: editAuthor,
            timestamp: editTimestamp, // Update tanggal
          };
        }
        return item;
      });
  
      setUploadedData(updatedData);
  
      const database = getDatabase();
      const notificationRef = ref(database, `notifications/users/${editKey}`);
  
      try {
        console.log('Mengupdate ke Firebase:', editTitle, editData, editStatus, editTimestamp);
  
        await update(notificationRef, {
          title: editTitle,
          data: editData,
          status: editStatus,
          author: editAuthor,
          timestamp: editTimestamp, // Masukkan tanggal ke Firebase
        });
  
        alert('Data berhasil diperbarui di Firebase');
        console.log('Data berhasil diperbarui di Firebase');
      } catch (error) {
        console.error('Gagal mengupdate ke Firebase:', error);
      }
    }
  };
  
  
  

  const handleDelete = async (key) => {
    const database = getDatabase();
    const notificationRef = ref(database, `notifications/users/${key}`);

    try {
      await remove(notificationRef);
      console.log('Data successfully deleted');
    } catch (error) {
      console.error('Failed to delete data:', error);
    }
  };

  const handleToggleStatus = async (key, status) => {
    const database = getDatabase();
    const notificationRef = ref(database, `notifications/users/${key}`);

    try {
      await update(notificationRef, { status });
      console.log('Status successfully updated');
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  const filterData = () => {
    let filteredData = uploadedData;
  
    switch (activeTab) {
      case 'new':
        filteredData = filteredData.filter(item => item.status === 'true');
        break;
      case 'false':
        // Update status to 'true' if timestamp has passed and status is still 'false'
        filteredData = filteredData.map(item => {
          const currentTime = new Date().getTime();
          const itemTime = item.timestamp.getTime();
          if (currentTime > itemTime && item.status === 'false') {
            // Update status to 'true'
            item.status = 'true';
            // Update status in Firebase (optional, depending on your requirement)
            const database = getDatabase();
            const notificationRef = ref(database, `notifications/users/${item.key}`);
            update(notificationRef, { status: 'true' });
          }
          return item;
        });
        // Filter only items with status 'false'
        filteredData = filteredData.filter(item => item.status === 'false');
        break;
      case 'approved':
        filteredData = filteredData.filter(item => item.status === 'true');
        break;
      case 'yesterday':
        const today = new Date().setHours(0, 0, 0, 0);
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0);
        filteredData = filteredData.filter(item => item.timestamp >= yesterday && item.timestamp < today);
        break;
      default:
        break;
    }
  
    if (searchQuery.trim() !== '') {
      filteredData = filteredData.filter(item => item.data.toLowerCase().includes(searchQuery.toLowerCase()));
    }
  
    return filteredData;
  };
  

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const [editAuthor, setEditAuthor] = useState('');



  return (
    <>

<UserContext/>

      <div className="bg-gray-100 min-h-screen py-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Data yang Diunggah</h2>

          <div className="flex flex-col md:flex-row items-center mb-4">
            <div className="ml-2 space-y-4 space-x-2 md:space-y-0 md:space-x-2 w-full md:w-auto mb-2 md:mb-0">
              <button
                onClick={() => handleTabChange('new')}
                className={`py-2 px-4 rounded-md focus:outline-none ${
                  activeTab === 'new' ? 'bg-indigo-500 text-white' : 'bg-indigo-200 text-indigo-800'
                }`}
              >
                Data Baru
              </button>
              <button
                onClick={() => handleTabChange('false')}
                className={`py-2 px-4 rounded-md focus:outline-none ${
                  activeTab === 'false' ? 'bg-red-500 text-white' : 'bg-red-200 text-red-800'
                }`}
              >
                Data False
              </button>
              <button
                onClick={() => handleTabChange('approved')}
                className={`py-2 px-4 rounded-md focus:outline-none ${
                  activeTab === 'approved' ? 'bg-green-500 text-white' : 'bg-green-200 text-green-800'
                }`}
              >
                Setuju
              </button>
              <button
                onClick={() => handleTabChange('yesterday')}
                className={`py-2 px-4 rounded-md focus:outline-none ${
                  activeTab === 'yesterday' ? 'bg-yellow-500 text-white' : 'bg-yellow-200 text-yellow-800'
                }`}
              >
                Data Kemarin
              </button>
            </div>
            <input
              type="text"
              placeholder="Cari data..."
              className="w-96 md:w-auto py-2 px-4 border border-gray-300 rounded-sm focus:outline-none"
              value={searchQuery}
              onChange={handleSearch}
            />
    
          </div>

          <div className="bg-white rounded-md shadow-md">
            <ul className="divide-y divide-gray-200">
              {filterData().map((item) => (
                <li key={item.key} className="py-4 px-6">
                  <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md">
  <p className="text-2xl font-semibold mb-2">Judul: {item.title}</p>

  <p className="text-xs text-gray-500 mb-2">Status: {item.status}</p>
  <p className="text-xs text-white mb-2 bg-black">{item.timestamp.toString()}</p>
  <p className="text-xs text-gray-500 mb-2">UserID: {item.userId}</p>
  <p className="text-xs text-gray-500 mb-2">Permalink: {item.permalink}</p>
</div>

                    <div className="flex space-x-2 mt-4 md:mt-0">
               <button
  onClick={() => handleEdit(item.key, item.data, item.status, item.title, item.author, item.timestamp)}
  className="bg-indigo-500 text-white py-2 px-4 rounded-md focus:outline-none"
>
  Edit
</button>

                      <button
                        onClick={() => handleDelete(item.key)}
                        className="bg-red-500 text-white py-2 px-4 rounded-md focus:outline-none"
                      >
                        Delete
                      </button>
                      {item.status === 'false' && (
                        <button
                          onClick={() => handleToggleStatus(item.key, 'true')}
                          className="bg-green-500 text-white py-2 px-4 rounded-md focus:outline-none"
                        >
                          Setuju
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

<div className="mt-4 bg-white rounded-md shadow-md">
  <div className="py-4 px-6">
    <input
      type="text"
      placeholder='Judul'
      value={editTitle}
      onChange={(e) => setEditTitle(e.target.value)}
      className="w-full py-2 px-4 mt-2 border border-gray-300 rounded-md focus:outline-none bg-red-500"
    />
    <input
      type="text"
      placeholder='Content'
      value={editData}
      onChange={(e) => setEditData(e.target.value)}
      className="w-full py-2 px-4 border border-gray-300 rounded-md focus:outline-none bg-red-500"
    />
    <input
      type="text"
      placeholder='Status'
      value={editStatus}
      onChange={(e) => setEditStatus(e.target.value)}
      className="w-full py-2 px-4 mt-2 border border-gray-300 rounded-md focus:outline-none bg-red-500"
    />
    <input
      type="text"
      placeholder='Author' // Menambahkan input untuk author
      value={editAuthor}
      onChange={(e) => setEditAuthor(e.target.value)}
      className="w-full py-2 px-4 mt-2 border border-gray-300 rounded-md focus:outline-none bg-red-500"
    />
    <input
      type="text"
      placeholder='Tanggal'
      value={editTimestamp}
      onChange={(e) => setEditTimestamp(e.target.value)}
      className="w-full py-2 px-4 mt-2 border border-gray-300 rounded-md focus:outline-none bg-red-500"
    />

    {/* Input untuk edit image */}
    <input
      type="file"
      accept="image/*" // Hanya menerima file gambar
      onChange={(e) => setEditImage(e.target.files[0])} // Set nilai state editImage saat file dipilih
      className="w-full py-2 px-4 mt-2 border border-gray-300 rounded-md focus:outline-none bg-red-500"
    />

    <button
      onClick={handleSaveEdit}
      className="bg-indigo-500 text-white py-2 px-4 mt-4 rounded-md focus:outline-none"
    >
      Save
    </button>
  </div>
</div>



    
        </div>
      </div>
   
    </>
  );
};

export default Uploaduser;

