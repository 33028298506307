  import React, { useState, useEffect } from 'react';
  import firebase from "firebase/compat/app";
  import "firebase/compat/database";

  function FormDataComponent() {
    const [formDataList, setFormDataList] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const onlineUsersRef = firebase.database().ref('notifications/formulir');
          onlineUsersRef.on('value', (snapshot) => {
            const formData = snapshot.val();
            const formDataArray = Object.values(formData || {});
            setFormDataList(formDataArray);
          });
        } catch (error) {
          console.error('Error fetching form data:', error);
        }
      };
  
      fetchData();
  
      // Cleanup function to unsubscribe when the component unmounts
      return () => {
        firebase.database().ref('notifications/formulir').off();
      };
    }, []);
  
    const handleDelete = (index) => {
      const updatedFormData = formDataList.filter((_, i) => i !== index);
      setFormDataList(updatedFormData);
    
      // Perform deletion from Firebase
      try {
        const onlineUsersRef = firebase.database().ref('notifications/formulir');
        onlineUsersRef.once('value', (snapshot) => {
          const formData = snapshot.val();
          const formDataArray = Object.values(formData || {});
    
          // Get the key of the item you want to delete from Firebase
          const keyToDelete = Object.keys(formData)[index];
    
          // Remove the item from Firebase
          firebase.database().ref(`notifications/formulir/${keyToDelete}`).remove();
        });
      } catch (error) {
        console.error('Error deleting data from Firebase:', error);
      }
    };
    

    return (
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-4">Formulir Pendaftaran Seller</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {formDataList.map((formData, index) => (
            <div key={index} className="border p-4 rounded-md shadow-md">
              {/* Displaying index starting from 1 */}
              <p className="font-bold">Index: {index + 1}</p>
              <p className="">Name: {formData.name}</p>
              <p>User ID: {formData.userId}</p>
              <a href={`https://wa.me/${formData.whatsapp}`} target="_blank" rel="noopener noreferrer">
                <p>WhatsApp: {formData.whatsapp}</p>
              </a>
              {/* Add other fields as needed */}
              <button className="bg-blue-500 px-4 rounded-sm mt-1 text-white"onClick={() => handleDelete(index)}>Delete</button>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default FormDataComponent;