import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const FirebaseDataDisplay = () => {
  // State untuk URL dan toggle
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputUrl, setInputUrl] = useState("");
  const [inputToggle, setInputToggle] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      const dataRef = firebase.database().ref("notifications");
      dataRef.on("value", (snapshot) => {
        const fetchedData = snapshot.val();
        setData(fetchedData);
        setLoading(false);
        setInputUrl(fetchedData?.url || '');
        setInputToggle(fetchedData?.toggle || ''); // Mengisi inputToggle dengan nilai toggle saat ini
      });
    };

    fetchData();

    return () => {
      firebase.database().ref("notifications").off();
    };
  }, []);

  const handlePosterClick = () => {
    if (data && data.url) {
      window.location.href = data.url;
    } else {
      console.log("URL tidak tersedia");
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Logika penyimpanan ke Firebase saat tombol simpan ditekan
    if (inputUrl && inputToggle) {
      const urlToSubmit = inputUrl.trim();
      const toggleToSubmit = inputToggle.trim();
      firebase
        .database()
        .ref("notifications")
        .update({ url: urlToSubmit, toggle: toggleToSubmit })
        .then(() => {
          console.log("Data berhasil diubah di Firebase");
        })
        .catch((error) => {
          console.log("Error saat mengubah data di Firebase:", error);
        });
    } else {
      console.log("URL dan toggle tidak boleh kosong");
    }
  };

  const handleUrlChange = (e) => {
    setInputUrl(e.target.value);
  };

  const handleToggleChange = (e) => {
    setInputToggle(e.target.value);
  };

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="bg-white shadow-md rounded-md p-6 mb-2">
        <div className="flex flex-col space-y-4">
          <div className="text-xl">
            <p>Content Header</p>
          </div>
          <div className="flex justify-between items-center">
            {data ? (
              isEditing ? (
                <div className="flex flex-col space-y-4">
                  <input
                    type="text"
                    value={inputUrl}
                    onChange={handleUrlChange}
                    placeholder="Masukkan URL baru"
                    className="border border-gray-300 rounded-md p-2"
                  />
                  <input
                    type="text"
                    value={inputToggle}
                    onChange={handleToggleChange}
                    placeholder="Masukkan Nilai Toggle baru"
                    className="border border-gray-300 rounded-md p-2"
                  />
                  <button onClick={handleSaveClick} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                    Simpan
                  </button>
                </div>
              ) : (
                <button onClick={handleEditClick} className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md hover:bg-gray-300">
                  Edit
                </button>
              )
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirebaseDataDisplay;
