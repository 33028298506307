import React, { useEffect, useState } from 'react';
import { ref, getDatabase, onValue, update, remove } from 'firebase/database';
import { Switch, Button, Input } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const FirebaseDataViewer = () => {
  const [data, setData] = useState(null);
  const [searchUserId, setSearchUserId] = useState('');

  useEffect(() => {
    // Initialize Firebase Realtime Database
    const firebaseConfig = {
        apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
        authDomain: "kelasskilla.firebaseapp.com",
        databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
        projectId: "kelasskilla",
        storageBucket: "kelasskilla.appspot.com",
        messagingSenderId: "785364290918",
        appId: "1:785364290918:web:1da135595c6cea24be4ca7",
        measurementId: "G-R4L1YVZSTB"
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    // Get database reference
    const database = getDatabase();
    const notificationsRef = ref(database, 'notifications/premium');

    // Fetch data from Firebase when component is mounted
    onValue(notificationsRef, (snapshot) => {
      const dataSnapshot = snapshot.val();
      setData(dataSnapshot);
    });

    // Clean up the listener when component is unmounted
    return () => {
      setData(null);
    };
  }, []);

  const handleUpdateValue = (userId, field, value) => {
    const updates = {
      [`notifications/premium/${userId}/${field}`]: value
    };

    update(ref(getDatabase()), updates)
      .then(() => {
        toast.success('Data successfully updated');
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  };

  const handleDeleteEntry = (userId) => {
    const entryRef = ref(getDatabase(), `notifications/premium/${userId}`);

    remove(entryRef)
      .then(() => {
        toast.success('Entry successfully deleted');
        if (searchUserId === userId) {
          setSearchUserId('');
        }
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  };

  const handleSortByAdmin = () => {
    if (data) {
      const sortedData = Object.keys(data).sort((a, b) => {
        const isAdminA = data[a].isAdmin;
        const isAdminB = data[b].isAdmin;
        return isAdminA === isAdminB ? 0 : isAdminA ? -1 : 1;
      });

      setData(
        sortedData.reduce((result, userId) => {
          result[userId] = data[userId];
          return result;
        }, {})
      );
    }
  };

  const handleSortBySeller = () => {
    if (data) {
      const sortedData = Object.keys(data).sort((a, b) => {
        const isSellerA = data[a].isSeller;
        const isSellerB = data[b].isSeller;
        return isSellerA === isSellerB ? 0 : isSellerA ? -1 : 1;
      });

      setData(
        sortedData.reduce((result, userId) => {
          result[userId] = data[userId];
          return result;
        }, {})
      );
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchUserId(value.trim());
  };

  const filteredData = searchUserId
    ? Object.entries(data || {}).filter(([userId]) =>
        userId.includes(searchUserId)
      )
    : Object.entries(data || {});

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white">
        <h2 className="text-2xl font-bold mb-4">Team Support</h2>
      <p>1. Gateway [YxeX]</p>

      <div className="flex mb-4">
        <Input
          placeholder="Search User ID"
          value={searchUserId}
          onChange={handleSearch}
          className="mr-2"
        />
        <Button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
          onClick={handleSortByAdmin}
        >
          Sort by Admin
        </Button>
        <Button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
          onClick={handleSortBySeller}
        >
          Sort by Seller
        </Button>
      </div>

      {filteredData.length > 0 ? (
        <ul className="space-y-4">
          {filteredData.map(([userId, user]) => (
            <li key={userId} className="border border-gray-300 rounded p-4">
              <strong>User ID:</strong> {userId}
              <div className="flex items-center mt-2">
                <span className="mr-2">isAdmin:</span>
                <Switch
                  checked={user.isAdmin}
                  onChange={(checked) =>
                    handleUpdateValue(userId, 'isAdmin', checked)
                  }
                />
              </div>
              <div className="flex items-center mt-2">
                <span className="mr-2">isSeller:</span>
                <Switch
                  checked={user.isSeller}
                  onChange={(checked) =>
                    handleUpdateValue(userId, 'isSeller', checked)
                  }
                />
              </div>
              <Button
                className="mt-4"
                type="danger"
                onClick={() => handleDeleteEntry(userId)}
              >
                Delete
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        <p className='bg-red-500'>No data found</p>
      )}

      <ToastContainer />
    </div>
  );
};

export default FirebaseDataViewer;
