import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {
    getStorage,
    ref as storageRef,
    uploadBytes,
    getDownloadURL,
} from 'firebase/storage';

const firestore = firebase.firestore();

const AirdropEvent = () => {
    const [newTaskTitle, setNewTaskTitle] = useState('');
    const [newTaskReward, setNewTaskReward] = useState('');
    const [tasks, setTasks] = useState([]);
    const [showClaimForm, setShowClaimForm] = useState(null);
    const [eWalletNumber, setEWalletNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [taskImage, setTaskImage] = useState(null);

    useEffect(() => {
        const unsubscribe = firestore.collection('tasks')
            .onSnapshot(querySnapshot => {
                const tasksData = [];
                querySnapshot.forEach(doc => {
                    tasksData.push({ id: doc.id, ...doc.data() });
                });
                setTasks(tasksData);
            });

        return () => unsubscribe();
    }, []);

    const addTask = async () => {
        await firestore.collection('tasks').add({
            title: newTaskTitle,
            reward: newTaskReward,
            claimedBy: null,
            status: 'Available'
        });
        setNewTaskTitle('');
        setNewTaskReward('');
    };

    const claimTask = async (taskId, imageFile) => {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            const userUid = currentUser.uid;
            try {
                // Upload the image to Firebase Storage
                const storage = getStorage();
                const imageRef = storageRef(storage, `task_images/${taskId}`);
                await uploadBytes(imageRef, imageFile);

                // Add the user's e-wallet number, name, and image URL to the claimedTasks collection
                const imageUrl = await getDownloadURL(imageRef);
                await firestore.collection('claimedTasks').add({
                    taskId,
                    userUid,
                    eWalletNumber,
                    userName,
                    imageUrl,
                    status: false, // Menambahkan status false secara default
                    timestamp: firebase.firestore.FieldValue.serverTimestamp()
                });
                

                // Update the task to reflect it's claimed
                await firestore.collection('tasks').doc(taskId).update({
                    claimedBy: userUid,
                    status: 'Claimed'
                });

                // Reset the e-wallet number and user name fields after claiming the task
                setEWalletNumber('');
                setUserName('');

                console.log('Task claimed successfully!');
            } catch (error) {
                console.error('Error claiming task:', error);
            }
        } else {
            console.error('User is not logged in!');
        }
    };

    return (
        <div className="container mx-auto">
            <h1 className="text-3xl font-semibold text-center my-6">Airdrop Event</h1>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Enter Task Title"
                    value={newTaskTitle}
                    onChange={(e) => setNewTaskTitle(e.target.value)}
                    className="border border-gray-400 rounded px-3 py-2 mr-2"
                />
                <input
                    type="text"
                    placeholder="Enter Task Reward"
                    value={newTaskReward}
                    onChange={(e) => setNewTaskReward(e.target.value)}
                    className="border border-gray-400 rounded px-3 py-2 mr-2"
                />
                <button onClick={addTask} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                    Add Task
                </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {tasks.map(task => (
                    <div key={task.id} className="bg-white rounded-lg shadow-md p-4">
                        <h2 className="text-lg font-semibold mb-2">{task.title}</h2>
                        <p>Reward: {task.reward}</p>
                        <button
                            onClick={() => setShowClaimForm(task.id)}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2"
                        >
                            Claim Task
                        </button>
                        {showClaimForm === task.id && (
                            <div className="mt-4">
                                <input
                                    type="text"
                                    placeholder="Enter Your E-Wallet Number"
                                    value={eWalletNumber}
                                    onChange={(e) => setEWalletNumber(e.target.value)}
                                    className="border border-gray-400 rounded px-3 py-2 mr-2"
                                />
                                <input
                                    type="text"
                                    placeholder="Enter Your Name"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    className="border border-gray-400 rounded px-3 py-2 mr-2"
                                />
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        setTaskImage(file);
                                    }}
                                    className="border border-gray-400 rounded px-3 py-2 mr-2"
                                />
                                <button
                                    onClick={() => claimTask(task.id, taskImage)}
                                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    Claim Now
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AirdropEvent;
