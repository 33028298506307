import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const AboutMe = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} font-serif`}>
      <h2 className="text-xl font-bold mb-2">About Me</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec risus eget ipsum vestibulum consequat.</p>
    </div>
  );
};

export default AboutMe;
