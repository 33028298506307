
import Navigation from "./Navigation";
import Navbar from "./Navbar";
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

import Navbara from "../pages/Navbara";
  export default function About() {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
      const fetchDarkModeSetting = async () => {
        try {
          const darkModeRef = firebase.database().ref('notifications/darkmode');
          darkModeRef.on('value', (snapshot) => {
            const darkModeValue = snapshot.val();
            setDarkMode(darkModeValue);
          });
        } catch (error) {
          console.error('Error fetching dark mode setting:', error);
        }
      };
  
      fetchDarkModeSetting();
  
      return () => {
        // Unsubscribe from Firebase listener when component unmounts
        firebase.database().ref('notifications/darkmode').off();
      };
    }, []);
  
    
    return <>
    <Navbara/>
<Helmet>
        <title>Tentang kami</title>
        <meta name="description" content="about zivzu" />
        {/* Other SEO metadata can be added here */}
      </Helmet>

    
      <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6`}>


</div>

<section className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} mb-12 px-4 -mt-10`}>


    <div className="grid md:grid-cols-2 gap-4 items-center">

      <div className="mb-6 md:mb-0">
      <div className=" mt-2">
        Kelasskill adalah platform course online
platform ini memudahkan pengguna untuk dapat meningkatkan kealihan atau minat mereka sesuai dengan passion mereka inginkan
<div className=" mt-2">
Kelasskill membangun lebih dari puluhan skill yang dapat membantu kamu mendapatkan experience sehingga kesempatanmu di bakat tersebut lebih terpenuhi.

</div>

<div className=" mt-2 mb-18">
Jadikan kelasskill sebagai gerbang
komunitas para pelajar karena kami memiliki beberapa fitur unggulan

Bukan cuman mencari ilmu tetapi mencari skill, peluang, store, goals, bahkan pesan jasa dan dapatkan
informasi terupdate tentang dunia pendidikan.
</div>




          <p className="mt-6 font-bold">Hubungi Email:</p>
          <a
            href="mailto:zivzucorp@gmail.com"
            className="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 transition duration-300"
          >
admin@kelasskill.com          </a>
        </div>

   
      </div>
    </div>
  <div className="p-4">
  <a
        href="https://trakteer.id/kelasskill/tip"
        className="block w-full  py-2 text-center bg-green-700 hover:bg-green-800 text-white font-medium rounded-lg transition duration-300 ease-in-out"
      >
        Trakteer
      </a>
  </div>
    <Navigation/>
  </section>
  {/* Section: Design Block */}

  {/* Container for demo purpose */}


    {/* End block */}
    {/* Start block */}

    {/* End block */}



  </>}

  