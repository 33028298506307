import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getStorage, ref as storageRef } from 'firebase/storage';
import LazyLoad from 'react-lazyload';
import { Helmet } from 'react-helmet';
import qrisCode from '../assets/rezas.jpeg'; // Mengimpor gambar QRIS dari direktori assets

import AddProductForm from './AddProductForm';
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Transition } from '@headlessui/react'; // Import the Transition component from the appropriate library
import Navigation from '../pages/Navigation';
import Navbara from '../pages/Navbara';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faDownload} from '@fortawesome/free-solid-svg-icons';
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import axios from 'axios';
const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const database = getDatabase(app);

const ProductCard = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [pesanTerimaKasih, setPesanTerimaKasih] = useState('');

  const [categories, setCategories] = useState([
    'Produk Digital',
    'Other'
  ]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = ref(database, 'notifications/products');
        onValue(productsRef, (snapshot) => {
          const productsData = snapshot.val();
          if (productsData) {
            const productsList = Object.values(productsData);
            setProducts(productsList);
          } else {
            setProducts([]);
          }
        });
      } catch (error) {
        console.error('Gagal mengambil produk', error);
      }
    };

    fetchProducts();
  }, []);


  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);
  
  const handleProductClick = async (product) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        // User is not logged in, show message to login first
        const confirmLogin = await showModal(); // Menampilkan modal konfirmasi
        if (confirmLogin) {
          // Jika pengguna setuju untuk login, tampilkan Firebase Auth popup for login
          const provider = new GoogleAuthProvider();
          await signInWithPopup(auth, provider);
        } else {
          // Jika pengguna tidak setuju untuk login, tidak melakukan apa-apa
          return;
        }
      }
      // User is logged in, proceed with purchasing logic
      setSelectedProduct(product);
      setModalVisible(true);
    } catch (error) {
      console.error('Error handling product click:', error);
    }
  };
  
  const showModal = () => {
    return new Promise((resolve) => {
      // Menampilkan modal konfirmasi dengan Tailwind CSS
      const modal = document.createElement('div');
      modal.innerHTML = `
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
              <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <!-- Heroicon name: exclamation -->
                    <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Login Required
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Anda harus login terlebih dahulu untuk melanjutkan. Apakah Anda ingin login sekarang?
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" onclick="resolve(true)" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                  Login
                </button>
                <button type="button" onclick="resolve(false)" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      `;
      document.body.appendChild(modal);
  
      // Menghapus modal dari DOM setelah digunakan
      const cleanup = () => {
        document.body.removeChild(modal);
      };
  
      // Fungsi untuk menyelesaikan promise sesuai dengan tombol yang diklik
      window.resolve = (value) => {
        cleanup();
        resolve(value);
      };
    });
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setModalVisible(false);
    setEnlargedImage(null); // Menutup gambar yang diperbesar saat menutup modal
  };
  

  const handleAddProduct = async (productData) => {
    try {
      // Validasi email dan nomor WhatsApp
      if (!productData.email || !productData.whatsapp) {
        alert('Mohon isi email dan nomor WhatsApp.');
        return;
      }

      // Rest of the code for adding the product...
    } catch (error) {
      console.error('Gagal menambahkan produk', error);
    }
  };


  const auth = getAuth(); // Define 'auth' within the component scope

  const filteredProducts = products.filter((product) => product.status === true);

  const getProductsByCategory = (category) => {
    return filteredProducts.filter((product) => {
      if (!searchQuery || searchQuery.trim() === "") { // Jika tidak ada query pencarian, kembalikan hasil berdasarkan kategori saja
        return product.category === category;
      } else { // Jika ada query pencarian, filter berdasarkan judul produk
        return (
          product.category === category &&
          product.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  

  
  const [isHovered, setIsHovered] = useState(null);


  const displayedProducts = categories.map((category) => {
    const categoryProducts = getProductsByCategory(category);
    
    if (!selectedCategory) {
      return categoryProducts; // Tampilkan semua produk dari berbagai kategori
    } else if (selectedCategory === category) {
      if (categoryProducts.length > 4) {
        return categoryProducts; // Tampilkan semua produk jika lebih dari 4
      } else {
        return categoryProducts.slice(0, productsPerPage);
      }
    } else {
      return []; // Kembalikan array kosong untuk kategori yang tidak dipilih
    }
  });
  

  const flattenDisplayedProducts = [].concat(...displayedProducts);

  // Pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = flattenDisplayedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [whatsappNumber, setWhatsappNumber] = useState('');
  const handleBeliClick = async () => {
    try {
        const { id, title, price } = selectedProduct;
        setPesanTerimaKasih('Dalam pengecekan admin [x]');

        // Menggunakan pesan yang mengandung detail produk beserta UID
        const pesan = `Saya ingin membeli produk dengan UID ${id}: ${title} Harga: ${price}.`;

        const response = await axios.get(`https://asia-southeast2-starry-gravity-428814-h7.cloudfunctions.net/function-1?message=${encodeURIComponent(pesan)}`);

        console.log('Response from server:', response.data);

        // Logic to handle the response can be added here.
        // Misalnya, menampilkan pesan sukses atau mengarahkan pengguna.

        // Set state untuk menampilkan pesan "Terima kasih!"

    } catch (error) {
        console.error('Terjadi kesalahan saat mengirim pesan:', error);
    }
};

  
  
  
  const [enlargedImage, setEnlargedImage] = useState(null);

 const [isContentExpanded, setIsContentExpanded] = useState(false);

  // Fungsi untuk membalikkan status konten
  const toggleContent = () => {
    setIsContentExpanded(!isContentExpanded);
  };

  const MAX_CHARACTERS = 100; // Ubah sesuai kebutuhan

  const getContent = () => {
    if (isContentExpanded) {
      // Jika konten ditampilkan secara penuh
      return selectedProduct.content;
    } else {
      // Jika konten harus dibatasi menjadi jumlah karakter tertentu
      if (selectedProduct.content.length > MAX_CHARACTERS) {
        return selectedProduct.content.substring(0, MAX_CHARACTERS) + '...';
      } else {
        return selectedProduct.content;
      }
    }
  };






  return (
    <>
  <Navbara/>
  <Helmet>
        <title>ZIVZU STORES</title>
        <meta
          name="description"
          content="Jelajahi koleksi produk zivzu, produk digtal, sofware premium, marketplace digital terbaik, mainan menarik"
        />         
        <meta name="keywords" content="marketplace, produk digital, elektronik, gadget, premium, store" />
        {/* Informasi SEO lainnya */}
      </Helmet>

        <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'} shadow-md p-4`}>


<div className="flex justify-between items-center mb-6 flex-wrap">

  <div className="w-full md:w-2/5 relative mb-4 md:mb-0">
    <div className="relative">
    <input
  type="text"
  placeholder={
    searchQuery
      ? ''
      : 'Cari produk, atau katakunci disini'
  }
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  className="text-black w-full p-3 pl-10 rounded-lg focus:outline-none focus:border-blue-500 placeholder-gray-500"

/>

      <span className="absolute inset-y-0 left-3 flex items-center justify-center">
        <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
      </span>
    </div>
  </div>
  <div className="w-full md:w-2/5 relative mb-4 md:mb-0">
    <div className="relative">
    <select
  value={selectedCategory}
  onChange={(e) => setSelectedCategory(e.target.value)}
  className="w-full p-3 rounded-lg focus:outline-none focus:border-blue-500 text-white bg-blue-900 appearance-none"

>

        <option value="">Lihat Kategori</option>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
      <span className="absolute inset-y-0 right-3 flex items-center justify-center pointer-events-none">
        <FontAwesomeIcon icon={faChevronDown} className="text-white" />
      </span>
    </div>
  </div>

</div>




<div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
{currentProducts.map((product) => (
  product.image && (
    <div
      key={product.id}
      className="relative overflow-hidden rounded-lg shadow-md  dark-overlay transition-opacity duration-300 ease-in-out"
      onClick={() => setEnlargedImage(product.image)} // Menampilkan gambar yang diperbesar ketika di-klik
    >
{enlargedImage && (
  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-800 bg-opacity-50" onClick={(e) => { if(e.target === e.currentTarget) setEnlargedImage(null) }}>
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="max-w-screen-lg w-full mx-auto rounded-lg bg-white relative">
        <img 
          src={enlargedImage} 
          alt="Enlarged Product" 
          className="w-full h-full object-contain" 
          onClick={(e) => e.stopPropagation()} // Menghentikan event klik agar tidak menutup modal ketika gambar diperbesar diklik
          onDoubleClick={(e) => {
            e.stopPropagation(); // Menghentikan event double click agar tidak memicu event pada parent elements
            alert("Close untuk menutup gambar");
          }} // Menambahkan alert untuk double click pada gambar
        />
        <button
          className="mt-10 absolute top-4 right-4 bg-violet-600 text-white px-3 py-1 rounded"
          onClick={(e) => {
            e.stopPropagation(); // Menghentikan event klik agar tidak menutup tampilan ketika tombol close ditekan
            setEnlargedImage(null);
          }}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}


<div className="relative">
  <div className="bg-gray-900 rounded-lg overflow-hidden shadow-lg relative min-h-[300px] flex flex-col">
    {/* Latar belakang blur */}
    <div className="absolute inset-0 bg-gray-900 opacity-25 filter blur-lg transition duration-300"></div>
    {/* Isi konten */}
    <div className="relative z-10 flex-grow flex flex-col">
      {/* Gambar produk */}
      <img
        src={product.image}
        alt={product.title}
        className="w-full h-40 sm:h-48 object-cover rounded-t-lg transition duration-300 transform hover:scale-105"
      />
      {/* Detail produk */}
      <div className="p-4 flex flex-col flex-grow">
        <p className="text-white font-semibold text-xs overflow-hidden line-clamp-2">{product.title}</p>
        <div className="flex items-center mt-2">
          <p className="text-green-500 font-semibold text-xs">{product.price}</p>
          {product.discount && (
            <>
              <p className="text-gray-500 text-sm ml-2 line-through">{product.originalPrice}</p>
              <p className="text-red-500 text-sm ml-2">-{product.discount}%</p>
            </>
          )}
        </div>
        {/* Penyaring Harga */}
        <div className="flex items-center mt-2">
          {/* Tambahkan elemen penyaring harga di sini */}
        </div>
        {/* Label Diskon */}
        {product.discount && (
          <div className="absolute top-0 right-0 bg-green-500 text-white py-1 px-2 rounded-bl-lg rounded-tr-lg">
            <p className="text-xs">Diskon</p>
          </div>
        )}
        {/* Spacer */}
        <div className="flex-grow"></div>
        {/* Tombol Pesan */}
        <button
          onClick={(e) => {
            e.stopPropagation(); // Menghentikan penyebaran event klik ke atas
            handleProductClick(product);
          }}
          className="w-full bg-green-700 text-white py-2 px-4 rounded-lg mt-2 transition duration-300 ease-in-out hover:bg-gray-500"
        >
          Pesan
        </button>
      </div>
    </div>
  </div>
</div>


  <Helmet>
                <title>{`Buy ${product.title} Online - Best Prices`}</title>
                <meta name="description" content={`${product.title} - ${product.description} - Price: ${product.price}`} />
                <meta property="og:title" content={`Buy ${product.title}`} />
                <meta property="og:description" content={`${product.description} - Price: ${product.price}`} />
                <meta property="og:image" content={product.image} />
                {/* Other meta tags for SEO */}
              </Helmet>
      </div>
    ))
  )}
</div>
<div className="mt-6">
    <nav className="flex justify-center">
      <ul className="pagination flex space-x-2">
        {Array.from(
          { length: Math.ceil(flattenDisplayedProducts.length / productsPerPage) },
          (_, i) => (
            <li key={i + 1}>
        <button
  className={`pagination-link ${i + 1 === currentPage ? 'active' : 'bg-gray-500 hover:bg-gray-700'} text-white px-4 py-2 rounded-md mx-1 sm:mx-2 md:mx-3`}
  onClick={() => paginate(i + 1)}
  style={{ backgroundColor: i + 1 === currentPage ? "#212121" : "#000000" }}
>
  {i + 1}
</button>

            </li>
          )
        )}
      </ul>
    </nav>
  </div>






  {modalVisible && (
  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-white bg-opacity-150">
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="max-w-full w-full mx-auto rounded-lg bg-white">
        {selectedProduct && (
          <div className="p-6">
            <div>
            {!isFullScreen && (
  <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
    <div className="bg-black p-4 rounded-lg">
      <h2 className="text-sm font-bold text-gray-900 mb-2">{selectedProduct.title}</h2>
      <h2 className="text-sm font-bold text-gray-900 mb-2">{selectedProduct.price}</h2>

    </div>
    <div className="overflow-hidden rounded-lg mb-4 relative">
  <img src={qrisCode} alt="QRIS Code" className="w-full h-60 object-cover rounded-lg" />

  <button
    className="absolute top-2 right-2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 transition duration-300"
    onClick={() => {
      const downloadImage = async () => {
        try {
          const response = await fetch(selectedProduct.qrCode);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${selectedProduct.title}_QR_Code.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading image:', error);
        }
      };
      downloadImage();
    }}
  >
    <FontAwesomeIcon icon={faDownload} />
  </button>
</div>

  </div>
)}

            </div>
            <div className="border border-gray-300 rounded-lg overflow-hidden">
              <div className="p-4 bg-gray-900 text-white">
                {/* Menampilkan konten produk */}
                {isFullScreen ? (
                  <div className="max-w-lg mx-auto p-4">
  <h2 className="text-2xl font-bold">{selectedProduct.title}</h2>
  <p className="text-gray-600">Kategori: {selectedProduct.category}</p>

  <div
    className="mt-4 "
    dangerouslySetInnerHTML={{ __html: getContent() }}
  />

  {/* Tombol close dengan efek call to action */}
  <button
    onClick={() => {
      setIsFullScreen(false);
      toggleContent(); // Panggil fungsi untuk menyembunyikan konten
    }}
    className="block mt-4 bg-gray-500 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out"
  >
    X
  </button>
</div>

) : (
  <div className='bg-gray-900'>
    <div dangerouslySetInnerHTML={{ __html: getContent() }} />
    {/* Tombol "Lihat Selengkapnya" */}
    {selectedProduct.content.length > 50 && (
      <button
        onClick={() => {
          setIsFullScreen(true);
          toggleContent(); // Panggil fungsi untuk menampilkan konten secara penuh
        }}
        className="text-blue-500 underline mt-2"
      >
        Lihat Selengkapnya
      </button>
    )}
  </div>
)}




              </div>
            </div>
            <form>
              {!isFullScreen && (
                <div className="flex justify-center mb-40">
                  <div className='mt-2'>
                    <button
                      type="button"
                      onClick={handleBeliClick}
                      className="bg-red-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg mr-4"
                    >
                      Konfirmasi
                    </button>
                    <button>{pesanTerimaKasih && <div className="alert bg-gray-500 hover:bg-gray-600 text-white font-semibold py-3 px-6 rounded-lg">{pesanTerimaKasih}</div>}</button>

              
                  </div>

                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  </div>
)}






{!isFullScreen && (
  <div className="mb-20">

    <Navigation/>
  </div>
)}

    </div>
    </>
  );
};

export default ProductCard;