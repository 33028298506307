import React, { useEffect, useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const Advertise = () => {
    const [imageUrl, setImageUrl] = useState('');
    const [status, setStatus] = useState(false);
    const [href, setHref] = useState('');

    useEffect(() => {
        const adRef = firebase.database().ref('notifications/ad1');

        const handleData = (snapshot) => {
            const data = snapshot.val();
            if (data && data.imageUrl) {
                setImageUrl(data.imageUrl);
            }
            if (data && data.status) {
                setStatus(data.status);
            }
            if (data && data.href) { // Memeriksa apakah properti href ada dalam data
                setHref(data.href); // Mengatur state href jika ada
            }
        };

        // Gunakan metode 'on' untuk mendengarkan perubahan secara real-time
        adRef.on('value', handleData, (error) => {
            console.error('Error fetching ad data:', error);
        });

        // Cleanup: Hapus listener saat komponen dibongkar
        return () => {
            adRef.off('value', handleData);
        };
    }, []); // Penambahan status dapat menyebabkan perubahan terus-menerus, maka tidak dimasukkan ke dalam dependensi useEffect

    return (
        <div className="w-full max-w-sm mx-auto">
            {status && imageUrl && href && ( // Memastikan status, imageUrl, dan href ada sebelum menampilkan iklan
                <a href={href} target="_blank" rel="noopener noreferrer"> {/* Menggunakan href yang diambil dari state */}
                    <img 
                        src={imageUrl} 
                        alt="Advertisement" 
                        className="w-full h-auto object-cover" 
                        style={{ maxHeight: '250px' }} 
                    />
                </a>
            )}
        </div>
    );
};

export default Advertise;
