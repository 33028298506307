import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import FirebaseDataDisplay from "../component/FirebaseDataDisplay";
import 'firebase/compat/database';
import "firebase/compat/messaging";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import 'firebase/firestore';
import Snowfall from 'react-snowfall';
import { getDatabase, ref, onValue } from 'firebase/database';
import moment from 'moment';
import 'moment/locale/id';
import IsTanggap from "../component/IsTanggap";
import Navbara from "./Navbara";
import Navigation from "./Navigation";
import Mentahan from "./Mentahan";
import AddAllowedUidForm from '../component/AddAllowedUidForm';
import CoinBalance from "../component/CoinBalance";
import Chat from "../component/Chat";
import IsRequest from "../component/IsRequest";
import Bar from "../component/bar";
import TaskList from '../component/TaskList';
import HelloWorldComponent from './HelloWorldComponent';
import { FaWhatsapp, FaEnvelope, FaInstagram } from 'react-icons/fa';
import AddUIDForm from "../component/AddUIDForm";
import CheckProduct from '../component/CheckProduct';
import AddStepForm from './AddStepForm';
import Monitor from './Monitor';
import Advertise from './Advertise';
import AddAdImageForm from './AddAdImageForm';
import DataFetcher from '../component/FetchUploadedData';
import Reward from './Reward';
import ThemeToggle from '../component/ThemeToggle';
import UploadKelas from './UploadKelas';
import Kelas from './Kelas';
import MyCourse from './MyCourse';
import Chatgram from '../component/Chatgram';
const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const database = firebase.database();


const Home = () => {


    
  const getDateTime = (timestamp) => {
    moment.locale('id'); // Set lokalisasi bahasa Indonesia
  
    const date = moment(timestamp).format('DD MMMM YYYY');
    const time = moment(timestamp).format('HH:mm');
    const day = moment(timestamp).format('dddd');
    return { date, time, day };
  };


  const [uploadedData, setUploadedData] = useState([]);

  useEffect(() => {
    const database = getDatabase();
    const notificationsRef = ref(database, 'notifications/users');

    // Mengambil data dengan status "true" dari Firebase Realtime Database
    onValue(notificationsRef, (snapshot) => {
      const notifications = snapshot.val();
      const uploadedData = Object.values(notifications).filter(
        (notification) => notification.status === 'true'
      );
      setUploadedData(uploadedData);
    });
  }, []);

  
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [userData, setUserData] = useState(null);

  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid;
        const notificationsRef = firebase.database().ref(`notifications/premium/${userId}`);
  
        notificationsRef.on('value', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setIsAdmin(data.isAdmin === true);
            setIsSeller(data.isSeller === true);
          }
        });
      } else {
        setIsAdmin(false);
        setIsSeller(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogin = () => {
    // Tambahkan fungsi untuk mengarahkan pengguna ke halaman login Firebase
    // Misalnya, menggunakan Firebase Auth popup
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider);
  };
 






  const db = firebase.database().ref('notifications/salju');
  const [snowfallActive, setSnowfallActive] = useState(false);

  useEffect(() => {
    // Mendapatkan status snowfall dari Firebase saat komponen dimuat
    db.child('toggle').on('value', (snapshot) => {
      const isActive = snapshot.val();
      if (isActive !== null) {
        setSnowfallActive(isActive);
      }
    });

    // Membersihkan listener Firebase pada unmount
    return () => {
      db.child('toggle').off('value');
    };
  }, []);

  const toggleSnowfall = () => {
    const updatedStatus = !snowfallActive;
    setSnowfallActive(updatedStatus);

    // Menyimpan status snowfall ke Firebase
    db.child('toggle').set(updatedStatus);
  };
  
  
  const [categories, setCategories] = useState([]);
  const [cachedData, setCachedData] = useState(null);

  useEffect(() => {
    const categoriesRef = database.ref('notifications/categories');

    const saveDataToLocalStorage = (data) => {
      localStorage.setItem('categoriesData', JSON.stringify(data));
      setCachedData(data);
    };

    const getDataFromLocalStorage = () => {
      const storedData = localStorage.getItem('categoriesData');
      return storedData ? JSON.parse(storedData) : null;
    };

    const cachedCategories = getDataFromLocalStorage();

    if (cachedCategories) {
      setCategories(cachedCategories);
      setCachedData(cachedCategories);
    }

    const handleSnapshot = (snapshot) => {
      const categoriesData = [];
      snapshot.forEach((childSnapshot) => {
        const data = childSnapshot.val();
        categoriesData.push({
          id: childSnapshot.key,
          icon: data.icon,
          title: data.title,
          link: data.link,
        });
      });
      setCategories(categoriesData);
      saveDataToLocalStorage(categoriesData);
    };

    categoriesRef.on('value', handleSnapshot);

    const handleOnlineStatus = () => {
      if (!cachedCategories) {
        categoriesRef.once('value', handleSnapshot);
      }
    };

    const handleOfflineStatus = () => {
      // Handle situasi offline di sini jika diperlukan
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    return () => {
      categoriesRef.off('value', handleSnapshot);
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
    };
  }, []);

  const dataToUse = cachedData || categories;
  // Deklarasikan state uploadedData hanya sekali di luar komponen

  const handleDataUpdate = (data) => {
    setUploadedData(data);
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  return (
    <>

 <Helmet>
        <title>KELASSKILL - KURSUS ONLINE</title>
        <meta
          name="description"
          content="Jadikan kelasskill sebagai gerbang komunitas para pelajar, platform ini memudahkan pengguna untuk dapat meningkatkan skill, Ayo gabung kelasskill"
        />
        {/* Meta tag untuk aplikasi super */}
        <meta name="super:app:name" content="KELASSKILL - KURSUS APP" />
        <meta name="super:app:category" content="EDUCATION" />
        <meta name="super:app:url" content="https://www.kelasskill.com/about" />
        {/* ... tambahkan tag-tag lain sesuai kebutuhan */}
      </Helmet>
    
<div
  
    >


        {isAdmin && (
          // gabisa
          
          <>     
          <Chatgram/>  
          <UploadKelas/>
          <AddAdImageForm/>             
            <AddAllowedUidForm/>
            <AddStepForm/>
            <AddUIDForm />
            <Monitor/>

        

{/* Form input untuk mengisi warna gradien */}

<div>

      <button onClick={toggleSnowfall}>
        <FontAwesomeIcon icon={snowfallActive ? faToggleOn  : faToggleOff} color={snowfallActive ? 'blue' : 'gray'} />
      </button>
    </div>
    <div className='mb-22'>

    <FirebaseDataDisplay/>
    <HelloWorldComponent/>

</div>
            <Bar />
          </>
        )}
        {isSeller && (
          <>

          <h1>seller</h1>
          <CheckProduct/>
          
            <IsTanggap />

            <Bar />
          </>
        )}
        {!isAdmin && !isSeller && (
            <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} `}>
   
      

      {snowfallActive && <Snowfall snowflakeCount={8} />}
      <Navbara />
      <Advertise/>

            <CoinBalance />
{/* <Reward/> */}
            <TaskList/>
            <Mentahan items={dataToUse} />
            <IsRequest />
            <Chat />

            <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} p-4 rounded-md shadow-md -mt-16 mb-14`}>

       

            <div className="flex justify-between">
              
            <div className="text-center flex items-center">
    <p className=" mt-1 mb-6 mr-2 font-medium ">
      Hubungi kami
      <span className="relative">
        <span className="absolute top-0 left-2 mt-2">
          <div className="w-2 h-2 rounded-full bg-green-700 animate-pulse"></div>
        </span>
      </span>
    </p>
  </div>
  {/* Bagian WhatsApp */}
  <div className="text-center">
    <a href="https://wa.me/62895392807421" target="_blank" rel="noopener noreferrer">
      <FaWhatsapp className="h-8 w-8 text-green-700 mx-auto" />
    </a>
  </div>
  <div className="text-center">
    <a href="https://www.instagram.com/kelasskill/" target="_blank" rel="noopener noreferrer">
      <FaInstagram className="h-8 w-8 text-green-700 mx-auto" />
    </a>
  </div>

  {/* Bagian Email */}
  <div className="text-center">
    <a href="mailto:admin@kelasskill.com">
      <FaEnvelope className="h-8 w-8 text-green-700 mx-auto" />
    </a>
  </div>

  {/* Bagian Instagram */}

  {/* Teks "Online" dan LED hijau kedap-kedip */}

</div>


     
    </div>
            <Navigation />
     
          </div>
        )}
      </div>
    </>
  );
  
  
  
};

export default Home;




