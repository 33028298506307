import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

firebase.initializeApp(firebaseConfig);

const ToggleComponent = () => {
  const [data, setData] = useState(null);
  const [showData, setShowData] = useState(false);
  const [inputUrl, setInputUrl] = useState("");

  useEffect(() => {
    const fetchData = () => {
      const dataRef = firebase.database().ref("notifications");
      dataRef.on("value", (snapshot) => {
        const data = snapshot.val();
        setData(data);
        const toggleData = data && data.toggle;
        const toggleStatus = data && data.status;

        if (toggleStatus) {
          setShowData(true);
        } else {
          setShowData(false);
        }
      });
    };

    fetchData();
  }, []);

  const animationStyle = {
    animation: showData ? "fade-in 1s ease-in-out" : "none"
  };

  const handlePosterClick = () => {
    if (data && data.url) {
      window.location.href = data.url;
    } else {
      console.log("URL tidak tersedia");
    }
  };

  const handleClose = () => {
    setShowData(false);
  };

  const handleUrlSubmit = (e) => {
    e.preventDefault();

    if (inputUrl) {
      const urlToSubmit = inputUrl.trim();
      firebase
        .database()
        .ref("notifications")
        .update({ status: true, url: urlToSubmit })
        .then(() => {
          console.log("URL berhasil dikirim ke Firebase");
        })
        .catch((error) => {
          console.log("Error saat mengirim URL ke Firebase:", error);
        });
    } else {
      console.log("URL tidak boleh kosong");
    }
  };

  return (
    <div>
  {showData && (
  <div style={animationStyle}>
    <div style={{}} className={""}>
      {data.url ? (
        <a href={data.url} onClick={handlePosterClick} className="">
          {data && data.toggle}
        </a>
      ) : (
        <p>URL tidak tersedia</p>
      )}
    </div>
  </div>
)}

    </div>
  );
};

export default ToggleComponent;
