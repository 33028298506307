import React, { useState } from 'react';
import { ref, update, getDatabase } from 'firebase/database';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const AdminForm = () => {
  const [uid, setUid] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [notification, setNotification] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Inisialisasi Firebase Realtime Database
    const firebaseConfig = {
      apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
      authDomain: "kelasskilla.firebaseapp.com",
      databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
      projectId: "kelasskilla",
      storageBucket: "kelasskilla.appspot.com",
      messagingSenderId: "785364290918",
      appId: "1:785364290918:web:1da135595c6cea24be4ca7",
      measurementId: "G-R4L1YVZSTB"
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    // Dapatkan referensi database
    const database = getDatabase();
    const notificationsRef = ref(database, 'notifications/premium');
    const data = {
      [uid]: {
        uid: uid,
        isAdmin: isAdmin,
        isSeller: isSeller
      }
    };

    update(notificationsRef, data)
      .then(() => {
        setNotification('Data berhasil dikirim ke Realtime Database.');
        setUid('');
        setIsAdmin(false);
        setIsSeller(false);
      })
      .catch((error) => {
        console.error('Terjadi kesalahan:', error);
      });
  };

  return (
    <div className="bg-white rounded-lg shadow p-8 max-w-sm w-full">
      {notification && <p className="text-center text-green-500 mb-4">{notification}</p>}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="uid" className="block text-gray-700 font-bold mb-2">
            Nama Pengguna:
          </label>
          <input
            type="text"
            id="uid"
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="isAdmin" className="block text-gray-700 font-bold mb-2">
            isAdmin:
          </label>
          <input
            type="checkbox"
            id="isAdmin"
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
            className="mr-2"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="isSeller" className="block text-gray-700 font-bold mb-2">
            isSeller:
          </label>
          <input
            type="checkbox"
            id="isSeller"
            checked={isSeller}
            onChange={(e) => setIsSeller(e.target.checked)}
            className="mr-2"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Kirim Data
        </button>
      </form>
    </div>
  );
};

export default AdminForm;
