import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  set,
  push,
  onValue,
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Navbar from '../pages/Navbar';
import Navigation from '../pages/Navigation';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

firebase.initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const database = getDatabase();
const storage = getStorage();

const Upload = () => {
  const [title, setTitle] = useState('');
  const [data, setData] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploadedData, setUploadedData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [customPermalink, setCustomPermalink] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date()); // State untuk menyimpan tanggal dan waktu yang dipilih

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const toggleRef = ref(database, `users/${userId}/toggle`);
  
        onValue(toggleRef, (snapshot) => {
          const toggleValue = snapshot.val();
          if (toggleValue) {
            const notificationsRef = ref(database, 'notifications/users');
            onValue(notificationsRef, (snapshot) => {
              const notifications = snapshot.val();
              if (notifications) {
                const filteredNotifications = Object.values(notifications)
                  .filter(
                    (notification) =>
                      notification.userId === userId &&
                      notification.status === 'true'
                  );
                const sortedNotifications = filteredNotifications.sort((a, b) => b.timestamp - a.timestamp);
                setUploadedData(sortedNotifications);
              } else {
                setUploadedData([]);
              }
            });
          }
        });
      }
    };
  
    fetchData();
  }, []);
  const handleUpload = async (e) => {
    e.preventDefault();
    if (title.trim() === '') {
      setError('Judul harus diisi');
      return;
    }
  
    if (data.trim() === '') {
      setError('Data harus diisi');
      return;
    }
  
    try {
      setLoading(true);
      setError('');
      setShowLoading(true);
  
      let downloadUrl = '';
  
      if (image) {
        const uploadRef = storageRef(
          storage,
          `images/${auth.currentUser.uid}/${image.name}`
        );
        await uploadBytes(uploadRef, image);
        downloadUrl = await getDownloadURL(uploadRef);
      }
  
      await new Promise((resolve) => setTimeout(resolve, 3000));
  
      // Menggunakan custom permalink jika tersedia, jika tidak generate permalink baru
      let permalink = customPermalink.trim() !== '' ? customPermalink : generatePermalink();
  
      // Pastikan permalink berakhir dengan .html
      if (!permalink.endsWith('.html')) {
        permalink += '.html';
      }
  
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const toggleRef = ref(database, `users/${userId}/toggle`);
        await set(toggleRef, true);
  
        const notificationsRef = ref(database, 'notifications/users');
        const newNotificationRef = push(notificationsRef);
  
        const timestamp = selectedDate.getTime();
  
        await set(newNotificationRef, {
          userId: userId,
          title: title,
          data: data,
          imageUrl: downloadUrl,
          status: 'false',
          timestamp: timestamp,
          permalink: permalink,
          toggle: false,
          author: author,
        });
  
      } else {
        throw new Error('Pengguna tidak terautentikasi');
      }
  
      setTitle('');
      setData('');
      setImage(null);
      setImageUrl(downloadUrl);
      setLoading(false);
      setIsImageSelected(false);
      setCustomPermalink('');
      alert('Data berhasil diunggah. Menunggu Persetujuan Admin.');
    } catch (error) {
      setError('Gagal mengunggah data');
      setLoading(false);
      console.error(error);
    } finally {
      setShowLoading(false);
      window.location.href = '/';
    }
  };
  
  

  const handleLogin = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Gagal melakukan login:', error);
    }
  };

  const handleReadMore = () => {
    setShowFullText(true);
  };

  const handleShare = (permalink) => {
    console.log('Sharing Permalink:', permalink);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setIsImageSelected(true);
    } else {
      setImage(null);
      setIsImageSelected(false);
    }
  };

  const generatePermalink = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let permalink = '';
    for (let i = 0; i < 10; i++) {
      permalink += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return permalink;
  };
  
  const [author, setAuthor] = useState('');
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  return (
    <>
      <Navbar />
      <Helmet>
        <title>Kabari Komunitas - Judul Halaman Anda</title>
        <meta name="description" content="Deskripsi singkat tentang halaman Anda" />
      </Helmet>
      <div style={{ backgroundColor: '#10131A' }} className={'bg-10131A dark:bg-10131A  min-h-screen -mt-16 flex items-center justify-center'}>
        <div className="bg-white mx-2 shadow-lg rounded-lg w-full sm:w-2/3 md:w-1/2 lg:w-1/3">
          <div className="p-8">
            <h2 className="text-2xl font-semibold mb-4">Kabari Komunitas</h2>
            <form onSubmit={handleUpload}>
              <input
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                placeholder="Penulis"
                className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-4 w-full"
              />
              <input
                type="text"
                value={customPermalink}
                onChange={(e) => setCustomPermalink(e.target.value)}
                placeholder="Masukkan URL kustom"
                className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-4 w-full"
              />
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Judul"
                className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-4 w-full"
                required
              />
              <ReactQuill
                value={data}
                onChange={(value) => setData(value)}
                placeholder="Sampaikan apa yang sedang terjadi disana..."
                modules={modules}
                formats={formats}
                className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-4 w-full"
                required
              />
              <DatePicker
                selected={selectedDate}
                onChange={date => setSelectedDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MMMM d, yyyy h:mm aa"
                className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-4 w-full"
              />
              <input
                type="file"
                onChange={handleImageUpload}
                accept="image/*"
                className="mb-4"
              />
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Thumbnail"
                  className="max-w-full h-auto mb-4"
                />
              )}
              {error && <p className="text-red-500 mb-4">{error}</p>}
              {!auth.currentUser ? (
                <button
                  onClick={handleLogin}
                  className="bg-purple-700 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mb-4 w-full"
                >
                  Login
                </button>
              ) : (
                <>
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-purple-800 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mb-4 w-full"
                  >
                    {loading ? 'Uploading...' : 'Kirim'}
                  </button>
                  {uploadedData.length > 0 && (
                    <div className="bg-white bg-opacity-25 p-4 rounded-lg">
                      <h3 className="text-xl font-semibold mb-2">
                        Data yang telah diunggah:
                      </h3>
                      <ul className="list-disc ml-6">
                        {uploadedData.map((item, index) => (
                          <li key={index} className="mb-2">
                            <span>{item.title}</span>
                            {!showFullText && (
                              <span>
                                {' - '}
                                {item.data.slice(0, 100)}...{' '}
                                <button
                                  onClick={handleReadMore}
                                  className="text-blue-500"
                                >
                                  Read More
                                </button>
                              </span>
                            )}
                            {showFullText && <span> - {item.data}</span>}
                            <button
                              onClick={() =>
                                handleShare(
                                  `${window.location.href}/${item.permalink}`
                                )
                              }
                              className="text-blue-500 ml-2"
                            >
                              Share
                            </button>
                            {item.imageUrl && (
                              <img
                                src={item.imageUrl}
                                alt="Thumbnail"
                                className="max-w-full h-auto mb-2"
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <Navigation />
    </>
  );
};

export default Upload;