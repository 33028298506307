import React, { useState, useEffect } from 'react';

import AddProductForm from '../component/AddProductForm';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, set } from 'firebase/database';
import { getStorage, ref as storageRef, uploadString } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
    authDomain: "kelasskilla.firebaseapp.com",
    databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
    projectId: "kelasskilla",
    storageBucket: "kelasskilla.appspot.com",
    messagingSenderId: "785364290918",
    appId: "1:785364290918:web:1da135595c6cea24be4ca7",
    measurementId: "G-R4L1YVZSTB"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const database = getDatabase(app);

const ArticleForm = () => {
  const [products, setProducts] = useState([]);

  const handleAddProduct = async (productData) => {
    try {
      const newProductRef = push(ref(database, 'notifications'));
      const newProductId = newProductRef.key;
      const newProduct = { ...productData, id: newProductId };
      await set(newProductRef, newProduct);

      if (productData.image) {
        const imageRef = storageRef(storage, `images/${newProductId}`);
        await uploadString(imageRef, productData.image, 'data_url;base64');
      }

      console.log('Product successfully added!');
    } catch (error) {
      console.error('Failed to add product', error);
    }
  };

  const [userInputsList, setUserInputsList] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    const storedInputs = getCookie('userInputs');
    if (storedInputs) {
      setUserInputsList(JSON.parse(storedInputs));
    }
  }, []);

  const handleFormSubmit = (e) => {
    // ... remaining code
  };

  const getCookie = (name) => {
    // ... remaining code
  };

  return (
    <div>
      <AddProductForm onAddProduct={handleAddProduct} />
   


      {/* Remaining code */}
    </div>
  );
};

export default ArticleForm;
