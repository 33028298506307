import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { FaPlus, FaTrash, FaEdit,  } from 'react-icons/fa';
import Navbara from "../pages/Navbara";
import Navigation from '../pages/Navigation';
import { FaArrowUp, FaArrowDown, FaRegPaperPlane } from 'react-icons/fa';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import 'firebase/compat/storage'; // Pastikan Anda telah mengimpor modul Storage

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

const TrelloClone = () => {
  const [lists, setLists] = useState([]);
  const [newListTitle, setNewListTitle] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [listTitleEditing, setListTitleEditing] = useState(null);
  const [editedListTitle, setEditedListTitle] = useState('');
  const [floatingCardText, setFloatingCardText] = useState(''); // State untuk teks floating card
  const [showFloatingCard, setShowFloatingCard] = useState(false); // State untuk mengontrol tampilan floating card
  const [loading, setLoading] = useState(true); // State untuk menandai status loading

  useEffect(() => {
      const unsubscribe = db.collection('lists').onSnapshot(snapshot => {
          if (firebase.auth().currentUser) {
              const currentUserUID = firebase.auth().currentUser.uid;
              const listsData = snapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data()
              })).filter(list => list.uid === currentUserUID);

              setLists(listsData);
              setLoading(false); // Setelah data diperbarui, berhenti loading
          }
      });

      return () => unsubscribe();
  }, []);


  const [isMenuOpen, setIsMenuOpen] = useState(false); // State untuk menentukan apakah menu dibuka atau tidak

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Mengubah status menu menjadi kebalikannya (buka-tutup)
  };

const handleAddList = async () => {
    // Dapatkan UID pengguna yang saat ini login
    const currentUser = firebase.auth().currentUser;
    const userUID = currentUser.uid;

    // Lakukan pengecekan untuk memastikan user telah login
    if (!userUID) {
        console.error('User not authenticated.');
        return;
    }

    // Memeriksa apakah sudah ada setidaknya satu list
    const uidsCollection = db.collection('uids');
    const subscribedUser = await uidsCollection.where('uid', '==', userUID).get();
    
    // Cek jumlah list, jika lebih dari 2, berikan peringatan untuk berlangganan kecuali jika UID terdaftar
    if (lists.length > 1 && subscribedUser.empty) {
      const currentUser = firebase.auth().currentUser;
      const userUID = currentUser.uid;
  
      const confirmMessage = "Maaf Anda Bukan Premium, Silahkan Hubungi Admin Untuk Upgrade Premium Rp. 30.000";
      if (window.confirm(confirmMessage)) {
          // Membuat pesan khusus untuk WhatsApp dengan UID pengguna
          const message = encodeURIComponent(`Kak, saya mau upgrade akun challenge. UID: ${userUID}`);
          window.location.href = `https://wa.me/62895392807421/?text=${message}`;
      }
      return;
  }
  
    
    

    // Meminta pengguna untuk memasukkan judul list baru
    const newListTitleInput = prompt("Masukan Judul list baru");

    // Memeriksa apakah pengguna membatalkan prompt atau memasukkan judul yang kosong
    if (!newListTitleInput || newListTitleInput.trim() === '') {
        alert("Judul list tidak boleh kosong.");
        return;
    }

    // Meminta pengguna untuk memasukkan nilai maxCardCount
    const maxCardCountInput = prompt("Masukkan Batas Hari Tantangan");

    // Konversi input pengguna menjadi angka
    const maxCardCount = parseInt(maxCardCountInput);

    // Memeriksa apakah input pengguna valid (berupa angka positif)
    if (isNaN(maxCardCount) || maxCardCount <= 0) {
        alert("Nilai maxCardCount harus berupa angka positif.");
        return;
    }

    try {
        await db.collection('lists').add({
            title: newListTitleInput,
            cards: [],
            maxCardCount: maxCardCount, // Menambahkan maxCardCount ke dalam dokumen
            uid: userUID // Menambahkan UID pengguna ke dalam dokumen
        });
        setNewListTitle('');
    } catch (error) {
        console.error('Error adding document: ', error);
    }
};




  

  const handleAddCard = async (listId) => {
    try {
        const listSnapshot = await db.collection('lists').doc(listId).get();
        const listData = listSnapshot.data();

        if (!listData) {
            console.error('List data not found');
            return;
        }

        const maxCardCount = listData.maxCardCount;

        if (listData.cards.length >= maxCardCount) {
            alert('Maaf, jumlah kartu dalam daftar sudah mencapai batas maksimal.');
            return;
        }

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString();

        // Lakukan pengecekan apakah tanggal terakhir kartu ditambahkan sama dengan tanggal hari ini
        if (listData.lastAddedDate === formattedDate) {
            alert('Anda Telah Selesai Dalam prosses, Coba Lagi Besok');
            return;
        }

        const cardText = prompt("Tambahkan pesan apapun");
        if (cardText !== null) {
            const card = {
                text: cardText,
                date: formattedDate
            };

            await db.collection('lists').doc(listId).update({
                cards: firebase.firestore.FieldValue.arrayUnion(card),
                lastAddedDate: formattedDate // Simpan tanggal terakhir kartu ditambahkan
            });
        }
    } catch (error) {
        console.error('Error adding card: ', error);
    }
};

const handleSettings = () => {
  setIsSidebarOpen(!isSidebarOpen); // Mengubah nilai isSidebarOpen menjadi kebalikannya (true -> false, false -> true)
};




  const handleDeleteList = async (listId) => {
    try {
      await db.collection('lists').doc(listId).delete();
    } catch (error) {
      console.error('Error deleting list: ', error);
    }
  };

  const handleSwapLists = (indexA, indexB) => {
    const newLists = [...lists];
    [newLists[indexA], newLists[indexB]] = [newLists[indexB], newLists[indexA]];
    setLists(newLists);
  };
// Di dalam fungsi TrelloClone
const handleSaveListTitle = async (listId) => {
  try {
    await db.collection('lists').doc(listId).update({
      title: editedListTitle
    });
    setListTitleEditing(null); // Setelah selesai mengedit, kembalikan ke tampilan judul biasa
  } catch (error) {
    console.error('Error updating list title: ', error);
  }
};




const [isInputVisible, setIsInputVisible] = useState(true);
const handleToggleInputVisibility = () => {
  setIsInputVisible(!isInputVisible); // Mengubah nilai state menjadi kebalikannya
};

const handleToggleToNormalMode = () => {
  setIsInputVisible(true); // Mengembalikan ke mode normal dengan menyetel isInputVisible menjadi true
};


  const handleDoubleClick = (text) => {
    // Atur teks floating card
    setFloatingCardText(text);
    // Tampilkan floating card
    setShowFloatingCard(true);
  };

  // Fungsi untuk menutup floating card
  const handleCloseFloatingCard = () => {
    setShowFloatingCard(false);
  };


  const [newChecklistItem, setNewChecklistItem] = useState('');
  const [description, setDescription] = useState('');

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleSave = () => {
    // Lakukan logika untuk menyimpan deskripsi atau catatan
    console.log('Deskripsi atau catatan yang disimpan:', description);
    // Anda dapat menambahkan logika untuk menyimpan data ke database atau melakukan tindakan lainnya di sini
  };

  const [listItemsCount, setListItemsCount] = useState({});
  useEffect(() => {
    const countListItems = () => {
      const listItemsCountObj = {};
      lists.forEach(list => {
        listItemsCountObj[list.id] = list.cards.length;
      });
      setListItemsCount(listItemsCountObj);
    };
  
    countListItems();
  }, [lists]);

  const [background, setBackground] = useState('');


  const [loadingProgressbar, setLoadingProgressbar] = useState(0);
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setLoadingProgressbar(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 1));
    }, 100); // Mengubah interval menjadi 100ms untuk animasi yang lebih lambat

    return () => clearInterval(progressInterval);
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // State untuk menyimpan gambar yang dipilih oleh pengguna
  const [selectedImage, setSelectedImage] = useState(null);

  // Fungsi untuk menangani perubahan file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const [uploadError, setUploadError] = useState(null);


  const handleUploadImage = () => {
    if (selectedImage) {
      // Proses untuk menyimpan gambar di penyimpanan lokal
      // Anda dapat menyesuaikan proses ini sesuai kebutuhan aplikasi Anda
      const reader = new FileReader();
      reader.onload = function(event) {
        const url = event.target.result;
        setBackground(url);
        // Simpan URL gambar di penyimpanan lokal
        localStorage.setItem('backgroundImage', url);
      };
      reader.onerror = function(event) {
        console.error('Error uploading image:', event.target.error);
        setUploadError('Error uploading image. Please try again.');
      };
      reader.readAsDataURL(selectedImage);
    } else {
      console.error('No image selected');
    }
  };
  
  
  
  

  useEffect(() => {
    // Periksa apakah ada URL gambar di penyimpanan lokal
    const backgroundImage = localStorage.getItem('backgroundImage');
    if (backgroundImage) {
      setBackground(backgroundImage);
    }
  }, []);
  

  
  const handleEditClick = async (listId, cardIndex) => {
    // Dapatkan teks kartu yang ingin diedit
    const currentText = lists.find(list => list.id === listId).cards[cardIndex].text;
  
    // Prompt untuk meminta teks baru
    const newText = prompt("Edit card text:", currentText);
  
    // Periksa jika pengguna tidak membatalkan prompt dan memasukkan teks baru
    if (newText !== null) {
      try {
        // Dapatkan referensi ke dokumen yang ingin diperbarui
        const listRef = db.collection('lists').doc(listId);
  
        // Dapatkan data list yang sudah ada
        const listSnapshot = await listRef.get();
        const listData = listSnapshot.data();
  
        // Perbarui teks kartu dengan teks baru
        listData.cards[cardIndex].text = newText;
  
        // Lakukan update ke Firebase
        await listRef.update({
          cards: listData.cards
        });
  
        // Pemberitahuan bahwa teks kartu telah diperbarui
        alert('Teks kartu telah diperbarui.');
      } catch (error) {
        console.error('Error updating card text: ', error);
        alert('Terjadi kesalahan saat memperbarui teks kartu.');
      }
    }
  };
  
  const [uploadProgress, setUploadProgress] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleToggleMenu = () => {
    // Logika untuk menangani tombol menu
  };

  const [showSplash, setShowSplash] = useState(true);
  const [progressgoal, setProgressgoal] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
      setShowSignUp(true); // Menampilkan tampilan "Sign up with Google" setelah progress bar selesai
    }, 3000); // Menyembunyikan splash screen setelah 7 detik

    const progressInterval = setInterval(() => {
      setProgressgoal(prevProgress => (prevProgress < 100 ? prevProgress + 14 : prevProgress));
    }, 1000); // Mengupdate progress setiap detik

    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, []);

  const handleLoginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        // Handle login success
        console.log('User signed in with Google:', result.user);
        // Reload halaman setelah login berhasil
        window.location.reload();
      })
      .catch((error) => {
        // Handle login error
        console.error('Error signing in with Google:', error);
      });
  };
  
  const handleEditMaxCardCount = async (listId) => {
    try {
        // Ambil dokumen list dari Firebase
        const listRef = db.collection('lists').doc(listId);
        const listDoc = await listRef.get();
        
        if (listDoc.exists) {
            // Ambil nilai maxCardCount yang sudah ada
            const currentMaxCardCount = listDoc.data().maxCardCount;
            
            // Prompt pengguna untuk memasukkan nilai baru
            const newMaxCardCountInput = prompt("Enter new max card count:", currentMaxCardCount);
            
            if (newMaxCardCountInput !== null) {
                const parsedCount = parseInt(newMaxCardCountInput);
                if (!isNaN(parsedCount) && parsedCount > 0) {
                    // Lakukan penanganan Firebase untuk memperbarui maxCardCount
                    await listRef.update({ maxCardCount: parsedCount });
                    console.log("Max card count updated successfully.");
                    // Tampilkan alert bahwa nilai telah berhasil diperbarui di Firebase
                    alert("Max card count updated successfully in Firebase.");
                } else {
                    alert("Invalid input. Please enter a valid positive number.");
                }
            }
        } else {
            console.error("List data not found.");
        }
    } catch (error) {
        console.error("Error updating max card count:", error);
        alert("An error occurred while updating max card count. Please try again later.");
    }
};


  return (
    <>
      <Navbara />

        <div className="flex overflow-x-auto" style={{ 
    maxHeight: '100vh', 
    backgroundImage: `url(${background})`, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    minHeight: '100vh',
    backgroundColor: '#ffffff' // Warna latar belakang default
}}>    
      
{lists.map((list, index) => (
            <div key={list.id} className=" bg-white mb-2 p-2 " onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(-1)}>
              <div className="text-lg font-semibold mb-6 bg-green-700 text-white rounded-md shadow-md">
                {listTitleEditing === list.id ? (
      <div>
  <input 
    type="text" 
    className='bg-gray-900 text-black'
    value={editedListTitle} 
    onChange={(e) => setEditedListTitle(e.target.value)} 
  />
  <button 
    onClick={() => handleSaveListTitle(list.id)} 
    className="ml-2 bg-blue-500 hover:bg-blue-600 text-black font-bold text-sm py-1 px-2 rounded focus:outline-none focus:shadow-outline"
  >
    Save
  </button>
  <button 
    onClick={() => setListTitleEditing(null)} // Menutup mode pengeditan judul
    className="ml-2 bg-gray-500 hover:bg-gray-600 text-black font-bold text-sm py-1 px-2 rounded focus:outline-none focus:shadow-outline"
  >
    Cancel
  </button>
</div>

                ) : (
                  <div className="flex items-center justify-between">
                  <div className="flex space-x-1 items-center">
                    <span className='mt-1'>{list.title}</span>
                    <button onClick={() => setListTitleEditing(list.id)} className="hover:bg-gray-600 text-white font-bold py-1 px-3 rounded mt-2 focus:outline-none focus:ring-2 focus:ring-yellow-300">
                      <FaEdit className="text-sm" />
                    </button>
                  </div>
                  <div className="flex space-x-1 items-center">
                    <button onClick={() => handleDeleteList(list.id)} className="hover:bg-gray-600 text-white font-bold py-1 px-3 rounded mt-2 focus:outline-none focus:ring-2 focus:ring-yellow-300">
                      <FaTrash className="text-sm" />
                    </button>
                    {/* Kondisi untuk tombol "Arrow Up" */}
                    {lists.length > 1 && index !== 0 && (
                      <button onClick={() => handleSwapLists(index, index - 1)} className="hover:bg-gray-600 text-white font-bold py-1 px-3 rounded mt-2 focus:outline-none focus:ring-2 focus:ring-gray-300">
                        <FaArrowUp className='text-sm'/>
                      </button>
                    )}
                    {/* Kondisi untuk tombol "Arrow Down" */}
                    {lists.length > 1 && index !== lists.length - 1 && (
                      <button onClick={() => handleSwapLists(index, index + 1)} className="hover:bg-gray-600 text-white font-bold py-1 px-3 rounded mt-2 focus:outline-none focus:ring-2 focus:ring-yellow-300">
                        <FaArrowDown className='text-sm'/>
                      </button>
                    )}
                  </div>
                </div>
                
                )}
              </div>
              
              <div className="max-h-60 overflow-x-auto">
  <ul>
    {list.cards.map((card, cardIndex) => (
      <li key={cardIndex} className="bg-gray-800 text-white p-2 rounded mb-2 shadow-md flex items-center justify-between">
        <div>
          <span>
            {card.text.length > 30 ? 
              card.text.match(/.{1,30}/g).map((substring, index) => (
                <span key={index}>
                  {substring}
                  <br />
                </span>
              ))
              : card.text
            }
          </span>
          <div className="text-white  text-sm">{card.date}</div>
        </div>
        <div>
          <FaEdit onClick={() => handleEditClick(list.id, cardIndex)} className="cursor-pointer text-gray-200 hover:text-gray-700 mr-2" />
        </div>
      </li>
    ))}
    {list.cards.length === 0 && (
      <li className="bg-gray-50 text-black p-2 rounded mb-2 shadow-md flex items-center justify-between animate-pulse" style={{ animationDuration: '05.5s' }}>
        Are you ready? 
      </li>
    )}

    {list.cards.length > 0 && list.lastAddedDate !== new Date().toLocaleDateString() && (
      <li className="bg-yellow-200 text-gray-800 p-2 rounded mb-2 shadow-md flex items-center justify-between">
        <span>You haven't added a card today!</span>
      </li>
    )}
  </ul>
</div>




<div className="flex items-center">
    <button 
        onClick={() => handleAddCard(list.id)} 
        className="mr-1 text-black bg-gray-600 font-bold px-2 rounded hover:bg-gray-700 hover:text-gray-100 transition duration-300 ease-in-out"
    >
        +
    </button>
    <progress 
        value={listItemsCount[list.id]} 
        max={list.maxCardCount}
        onClick={() => handleEditMaxCardCount(list.id)} // Menjalankan fungsi handleEditMaxCardCount saat progress diklik
        style={{ cursor: 'pointer' }} // Mengubah kursor saat diarahkan ke progress
    ></progress>

    <span className="text-sm text-gray-500 ml-2 mr-1">
        (-{list.maxCardCount - listItemsCount[list.id]})
    </span>
</div>



          
            </div>
          ))}


<div className="fixed bottom-24 right-6">
  <div className="relative">
    <button onClick={toggleMenu} className="bg-green-700 text-white w-12 h-12 rounded-full hover:bg-gray-600 focus:outline-none flex items-center justify-center">
      <FaPlus size={20} />
    </button>
    <p className="text-xs text-gray-500 mt-1 -ml-2">Create Goals</p> {/* Teks label fitur baru */}
    {isMenuOpen && (
      <div className="absolute z-10 right-0 bottom-10 w-48 bg-gray-900  rounded-md shadow-lg">
        {isInputVisible && (
          <button onClick={() => { handleAddList(); toggleMenu(); }} className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-green-700 hover:text-gray-900">
            Buat Tantangan
          </button>
        )}
        <button onClick={() => { handleSettings(); toggleMenu(); }} className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-green-700 hover:text-gray-900">
          Settings
        </button>
      </div>
    )}
  </div>
</div>



          
          <div>

          {!user && (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-gray-100">
{showSplash && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black text-white">
          <div className="text-center">
            <h1 className="text-4xl font-bold mb-4">Goals Challenge</h1>
            <p className="text-xl mb-8">Kami akan membantu progress anda setiap hari disini</p>
            <div className="w-32 h-32 mx-auto mb-8">
            <CircularProgressbar 
    value={progressgoal} 
    text={`${progressgoal}%`} 
    styles={{
        path: {
            stroke: '#ff0000' // Warna untuk lingkaran progres
        },
        text: {
            fill: '#ff0000', // Warna teks
            fontSize: '20px' // Ukuran teks, sesuaikan dengan kebutuhan Anda
        }
    }}
/>
            </div>
            <p className="text-lg">Loading...</p>
          </div>
        </div>
      )}
{showSignUp && (
  <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black text-white z-50">
    <div className="rounded-lg p-8 max-w-md mx-auto">
      <h1 className="text-2xl font-bold mb-4">Maaf, Anda belum login. </h1>
      <button onClick={handleLoginWithGoogle} className=" bg-gradient-to-r from-green-700 to-green-800 hover:from-green-800 hover:to-green-900 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center">
        <svg className="w-6 h-6 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 12h16M12 4l8 8-8 8"></path>
        </svg>
        Login with Google
      </button>
    </div>
  </div>
)}

  </div>
)}

          </div>
          <div style={{ position: 'fixed', top: '80px', right: '20px', zIndex: 999 }}>
        
            {isSidebarOpen && (
              <div className="fixed inset-0 z-50 overflow-hidden">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                <div className="fixed inset-y-0 right-0 max-w-full flex">
                  <div className="relative w-screen max-w-xs">
                    <div className="h-full flex flex-col shadow-xl" style={{ backgroundColor: '#DEC791' }}>
                      <div className="p-4">
                        <div className="flex items-center justify-between">
                          <h2 className="text-base font-normal text-black">Settings</h2>
                          <button onClick={handleSettings} className="text-gray-600 focus:outline-none hover:text-gray-800 transition-colors duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="overflow-y-auto font-extralight p-1">
  <div className="bg-white p-4 rounded-md shadow-md">
    <h2 className="text-base font-semibold mb-2">Ganti Background</h2>
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        Choose an image:
      </label>
      <input 
        type="file" 
        accept="image/*" 
        onChange={handleImageChange} 
        className="block w-full border border-gray-300 rounded py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:border-blue-500" 
      />
    </div>
    {selectedImage && !background && (
      <div className="mb-2">
        <h3 className="text-xs font-semibold mb-1">Preview:</h3>
      </div>
    )}
    <button 
      onClick={handleUploadImage} 
      className="bg-green-700 hover:bg-green-900 text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline text-sm mt-4"
    >
      {uploadProgress === 0 ? 'UPLOAD' : `UPLOADING ${uploadProgress.toFixed(2)}%`}
    </button>
    {uploadError && (
      <p className="text-red-500 text-sm mt-2">{uploadError}</p>
    )}
  </div>
</div>



                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

      {showFloatingCard && (
        <div>
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
            <div className="absolute top-0 left-0 w-full h-full bg-black" onClick={handleCloseFloatingCard}></div>
            <div className="relative bg-black p-8 rounded-lg" style={{ width: '100%', height: '100%' }}>
              <textarea
                value={description} 
                onChange={(e) => setDescription(e.target.value)}
                className="block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 mb-2"
                rows={5}
                placeholder="Add Description or Note"
              ></textarea>
              <button onClick={handleSave} className="bg-green-500 text-black px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none mr-2">
                Save
              </button>
              <button onClick={handleCloseFloatingCard} className="bg-red-500 text-black px-4 py-2 rounded-lg hover:bg-red-600 focus:outline-none">
                Close
              </button>
            </div>
          </div>
          
        </div>
      )}

      <Navigation />
    </>

);

};

export default TrelloClone;