import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, remove, get } from 'firebase/database';
import { TrashIcon, PencilIcon, XIcon, SaveIcon } from '@heroicons/react/outline';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CheckProduct = () => {
  const [productData, setProductData] = useState(null);
  const [user, setUser] = useState(null);
  const [editProductKey, setEditProductKey] = useState('');
  const [editProductValue, setEditProductValue] = useState({
    title: '',
    content: '',
    category: '',
    price: '',
    email: '',
    whatsapp: '',
    status: false,
    image: ''
  });
  const [image, setImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false); // State untuk melacak apakah proses upload sedang berlangsung

  useEffect(() => {
    const database = getDatabase();
    const productsRef = ref(database, 'notifications/products');
    const auth = getAuth();

    const checkAuthStatus = () => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setUser(user);
      });

      return () => unsubscribe();
    };

    checkAuthStatus();

    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const products = Object.entries(data).map(([key, value]) => ({ key, ...value }));
        setProductData(products);
      } else {
        setProductData([]);
      }
    });

  }, []);

  const formatRupiah = (angka) => {
    var number_string = angka.toString().replace(/[^,\d]/g, ''),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return 'Rp ' + rupiah;
  };

  const handleUpdateProduct = (key, updatedProduct) => {
    if (user) {
      const database = getDatabase();
      const productRef = ref(database, `notifications/products/${key}`);

      get(productRef)
        .then((snapshot) => {
          const productData = snapshot.val();

          if (productData && productData.id === user.uid) {
            const updatedFields = {};
            Object.keys(updatedProduct).forEach((property) => {
              if (updatedProduct[property] !== productData[property]) {
                updatedFields[property] = updatedProduct[property];
              }
            });

            if (Object.keys(updatedFields).length > 0) {
              if (image) {
                const storage = getStorage();
                const storageReference = storageRef(storage, `images/${image.name}`);
                setIsUploading(true); // Set isUploading ke true saat proses upload dimulai
                setUploadStatus('Uploading...');
                uploadBytes(storageReference, image).then((snapshot) => {
                  getDownloadURL(snapshot.ref).then((url) => {
                    updatedFields.image = url;
                    update(productRef, updatedFields)
                      .then(() => {
                        console.log('Product updated successfully.');
                        setEditProductKey('');
                        setEditProductValue({});
                        setImage(null);
                        setUploadStatus('Upload successful!');
                        setIsUploading(false); // Set isUploading ke false setelah proses upload selesai
                      })
                      .catch((error) => {
                        console.error('Failed to update product:', error);
                        setUploadStatus('Upload failed.');
                        setIsUploading(false); // Set isUploading ke false jika terjadi kesalahan
                      });
                  }).catch((error) => {
                    console.error('Failed to get download URL:', error);
                    setUploadStatus('Upload failed.');
                    setIsUploading(false); // Set isUploading ke false jika terjadi kesalahan
                  });
                }).catch((error) => {
                  console.error('Failed to upload image:', error);
                  setUploadStatus('Upload failed.');
                  setIsUploading(false); // Set isUploading ke false jika terjadi kesalahan
                });
              } else {
                update(productRef, updatedFields)
                  .then(() => {
                    console.log('Product updated successfully.');
                    setEditProductKey('');
                    setEditProductValue({});
                  })
                  .catch((error) => {
                    console.error('Failed to update product:', error);
                  });
              }
            } else {
              console.log('No changes detected.');
              setEditProductKey('');
              setEditProductValue({});
            }
          } else {
            alert('You are not authorized to edit this product because you do not have the required permissions.');
          }
        })
        .catch((error) => {
          console.error('Error fetching product data:', error);
        });
    } else {
      alert('You are not authorized to edit this product because you are not authenticated.');
    }
  };

  const handleDeleteProduct = (key) => {
    if (user) {
      const database = getDatabase();
      const productRef = ref(database, `notifications/products/${key}`);

      onValue(productRef, (snapshot) => {
        const productData = snapshot.val();

        if (productData && productData.id === user.uid) {
          remove(productRef)
            .then(() => {
              console.log('Product deleted successfully.');
            })
            .catch((error) => {
              console.error('Failed to delete product:', error);
            });
        } else {
          alert('You are not authorized to delete this product.');
        }
      });
    } else {
      alert('You are not authorized to delete this product because you are not authenticated.');
    }
  };

  const handleEditProduct = (key, product) => {
    if (user) {
      const database = getDatabase();
      const productRef = ref(database, `notifications/products/${key}`);

      onValue(productRef, (snapshot) => {
        const productData = snapshot.val();
        if (productData && productData.id === user.uid) {
          setEditProductKey(key);
          setEditProductValue(product);
        } else {
          alert('You are not authorized to edit this product because you do not have the required permissions.');
        }
      });
    } else {
      alert('You are not authorized to edit this product because you are not authenticated.');
    }
  };

  const handleCancelEdit = () => {
    setEditProductKey('');
    setEditProductValue({});
    setImage(null);
    setUploadStatus('');
    setIsUploading(false); // Set isUploading ke false saat pembatalan
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setEditProductValue({
      ...editProductValue,
      [name]: name === 'price' ? value : value === 'active'
    });
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col gap-4 px-2">
      <h2 className="text-2xl font-bold text-white">Checkbox to hidden or show products</h2>
      <p className="text-base -mt-4 text-white">Management Your Stock Items Active/Inactive</p>

      {productData.map((product) => (
        user && product.id === user.uid && (
          <div key={product.key} className="bg-white rounded-lg shadow-md p-4 flex flex-col md:flex-row md:items-start md:gap-4">
            {editProductKey === product.key ? (
              <div className="flex flex-col gap-4 md:flex-row md:items-start md:gap-4 w-full">
                <div className="w-full md:w-1/2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={editProductValue.title}
                    onChange={handleInputChange}
                    placeholder="Enter the title"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">Category</label>
                  <input
                    type="text"
                    name="category"
                    value={editProductValue.category}
                    onChange={handleInputChange}
                    placeholder="Enter the category"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">Content</label>
                  <ReactQuill
                    value={editProductValue.content}
                    onChange={(value) => handleInputChange({ target: { name: 'content', value } })}
                    placeholder="Enter the content"
                    className="h-40 mb-6"
                    modules={{
                      toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                        { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image', 'video'],
                        ['clean']
                      ],
                    }}
                    formats={[
                      'header', 'font', 'size',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link', 'image', 'video'
                    ]}
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="image">Image</label>
                  <input
                    type="file"
                    name="image"
                    onChange={handleImageChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  />
                  {editProductValue.image && (
                    <img src={editProductValue.image} alt="Product" className="mt-2" />
                  )}
                  {uploadStatus && (
                    <p className="text-sm mt-1">{uploadStatus}</p> // Tampilkan status upload
                  )}
                </div>
                <div className="w-full md:w-1/3">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">Price</label>
                  <input
                    type="text"
                    name="price"
                    value={formatRupiah(editProductValue.price)}
                    onChange={handleInputChange}
                    placeholder="Enter the price"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  />
                </div>
                <div className="w-full md:w-1/3 flex gap-4 items-center">
                  <label className="block text-gray-700 text-sm font-bold" htmlFor="status">Status</label>
                  <div className="flex items-center gap-2">
                    <select
                      name="status"
                      value={editProductValue.status ? 'active' : 'inactive'}
                      onChange={handleInputChange}
                      className="text-blue-500 focus:ring-blue-500"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="flex gap-4 md:ml-auto mb-20">
                  <button
                    onClick={() => handleUpdateProduct(product.key, editProductValue)}
                    className={`flex items-center gap-2 text-white px-4 py-2 rounded-lg focus:outline-none ${isUploading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                    disabled={isUploading} // Nonaktifkan tombol jika sedang mengunggah
                  >
                    <SaveIcon className="w-5 h-5" />
                    <span>Save</span>
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="flex items-center gap-2 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg focus:outline-none"
                  >
                    <XIcon className="w-5 h-5" />
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-4 md:flex-row md:items-center md:gap-8 w-full">
                <div className="w-full md:w-1/3">
                  <p className="text-lg font-semibold">Title: {product.title}</p>
                </div>
                <div className={`w-full md:w-1/3 ${product.status ? '' : 'inactiveStatus'}`}>
                  <p>Status: {product.status ? 'Active' : 'Inactive'}</p>
                </div>
                <div className="flex gap-4 md:ml-auto">
                  <button
                    onClick={() => handleEditProduct(product.key, product)}
                    className="flex items-center gap-2 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg focus:outline-none"
                  >
                    <PencilIcon className="w-5 h-5" />
                    <span>Edit</span>
                  </button>
                  <button
                    onClick={() => handleDeleteProduct(product.key)}
                    className="flex items-center gap-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg focus:outline-none"
                  >
                    <TrashIcon className="w-5 h-5" />
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )
      ))}
    </div>
  );
};

export default CheckProduct;
