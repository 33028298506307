 import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { FiMoreVertical } from 'react-icons/fi';
import { FiFileText, FiImage, FiVideo, FiFile, FiArchive } from 'react-icons/fi';
import { FaFileWord, FaFileExcel, FaFileCode  } from 'react-icons/fa'; // Contoh import untuk ikon file dari react-icons/fa

const Cloud = () => {
  const [directories, setDirectories] = useState([]);
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [fileType, setFileType] = useState('txt'); // 'txt' or 'file'
  const [selectedFile, setSelectedFile] = useState(null); // State to track selected txt file
  const [note, setNote] = useState(''); // State to track note content
  const [openMenuItemId, setOpenMenuItemId] = useState(null);

  const firebaseConfig = {
    apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
    authDomain: "kelasskilla.firebaseapp.com",
    databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
    projectId: "kelasskilla",
    storageBucket: "kelasskilla.appspot.com",
    messagingSenderId: "785364290918",
    appId: "1:785364290918:web:1da135595c6cea24be4ca7",
    measurementId: "G-R4L1YVZSTB"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  useEffect(() => {
    const fetchDirectories = async () => {
      try {
        const snapshot = await firebase.firestore().collection('directories').get();
        const loadedDirectories = snapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          isOpen: doc.data().isOpen,
          items: []
        }));

        await Promise.all(loadedDirectories.map(async (directory) => {
          const itemsSnapshot = await firebase.firestore().collection('directories').doc(directory.id).collection('items').get();
          directory.items = itemsSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            type: doc.data().type,
            note: doc.data().note || '',
            url: doc.data().url || '',
          }));
        }));

        setDirectories(loadedDirectories);
      } catch (error) {
        console.error('Error fetching directories:', error);
      }
    };

    fetchDirectories();
  }, []);

  useEffect(() => {
    const saveDirectoriesToFirestore = async (updatedDirectories) => {
      try {
        await Promise.all(updatedDirectories.map(async (directory) => {
          const { id, name, isOpen, items } = directory;
          await firebase.firestore().collection('directories').doc(id).set({ name, isOpen });

          if (items.length > 0) {
            await Promise.all(items.map(async (item) => {
              await firebase.firestore().collection('directories').doc(id).collection('items').doc(item.id).set({
                name: item.name,
                type: item.type,
                note: item.note || '',
                url: item.url || '',
              });
            }));
          }
        }));
        console.log('Directories saved successfully to Firestore.');
      } catch (error) {
        console.error('Error saving directories to Firestore:', error);
      }
    };

    saveDirectoriesToFirestore(directories);
  }, [directories]);

  const toggleDirectory = (id) => {
    setDirectories(prevDirectories =>
      prevDirectories.map(directory =>
        directory.id === id ? { ...directory, isOpen: !directory.isOpen } : directory
      )
    );
  };

  const handleFloatingButtonClick = () => {
    setShowFloatingButton(!showFloatingButton);
  };

  const handleFolderSelection = (folderId) => {
    setSelectedFolder(folderId);
    setShowFloatingButton(false);
    setShowModal(true);
  };

  const createNewFile = async () => {
    if (selectedFolder === null || (!newFileName.trim() && fileType === 'txt')) {
      alert('Please select a folder and enter a file name to create a new file.');
      return;
    }
  
    if (fileType === 'txt') {
      const fileExtension = '.txt';
      const newFile = {
        id: firebase.firestore().collection('directories').doc().id,
        name: newFileName.trim() + fileExtension,
        type: fileType,
        note: '',
        url: ''
      };
  
      const updatedDirectories = directories.map(directory => {
        if (directory.id === selectedFolder) {
          return { ...directory, items: [...directory.items, newFile] };
        }
        return directory;
      });
  
      setDirectories(updatedDirectories);
      setSelectedFolder(null);
      setNewFileName('');
      setFileType('txt');
      setShowModal(false);
      alert('New text file created successfully!');
    } else if (fileType === 'file') {
      try {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*, video/*'; // Accept image and video files
  
        fileInput.onchange = async (e) => {
          const file = e.target.files[0];
          if (!file) return;
  
          const storageRef = firebase.storage().ref();
          const fileReference = storageRef.child(`files/${file.name}`);
          await fileReference.put(file);
          const fileUrl = await fileReference.getDownloadURL();
  
          const newFile = {
            id: firebase.firestore().collection('directories').doc().id,
            name: file.name,
            type: fileType,
            url: fileUrl,
            note: ''
          };
  
          const updatedDirectories = directories.map(directory => {
            if (directory.id === selectedFolder) {
              return { ...directory, items: [...directory.items, newFile] };
            }
            return directory;
          });
  
          setDirectories(updatedDirectories);
          setSelectedFolder(null);
          setShowModal(false);
          alert('New file uploaded successfully!');
        };
  
        fileInput.click();
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file. Please try again.');
      }
    }
  };
  

  const handleFileClick = (file) => {
    setSelectedFile(file);
    if (file.type === 'txt') {
      setNote(file.note);
    } else if (file.url) {
      window.open(file.url, '_blank');
    } else {
      alert("Cannot open this file type.");
    }
  };

  const saveNote = () => {
    if (selectedFile) {
      const updatedDirectories = directories.map(directory => {
        return {
          ...directory,
          items: directory.items.map(item => {
            if (item.id === selectedFile.id) {
              return { ...item, note };
            }
            return item;
          })
        };
      });
      setDirectories(updatedDirectories);
      setSelectedFile(null);
      setNote('');
      alert('Note saved successfully!');
    }
  };

  const handleFileSelection = async (file) => {
    if (!file) return;
  
    const storageRef = firebase.storage().ref();
    const fileReference = storageRef.child(`files/${file.name}`);
  
    const uploadTask = fileReference.put(file);
  
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle progress here
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        // Update UI with progress (e.g., set state for progress bar)
      },
      (error) => {
        console.error('Error uploading file:', error);
        alert('Error uploading file. Please try again.');
      },
      () => {
        // Handle successful upload here
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          const newFile = {
            id: Date.now().toString(),
            name: file.name,
            type: fileType,
            url: downloadURL,
          };
          const updatedDirectories = directories.map((directory) => {
            if (directory.id === selectedFolder) {
              return { ...directory, items: [...directory.items, newFile] };
            }
            return directory;
          });
  
          setDirectories(updatedDirectories);
          setSelectedFolder(null);
          setShowModal(false);
          alert('New file uploaded successfully!');
        });
      }
    );
  };
  
  const handleDeleteItem = async (directoryId, itemId) => {
    if (!window.confirm('Are you sure you want to delete this item?')) {
      return;
    }
  
    try {
      const updatedDirectories = directories.map(directory => {
        if (directory.id === directoryId) {
          const updatedItems = directory.items.filter(item => item.id !== itemId);
          return { ...directory, items: updatedItems };
        }
        return directory;
      });
  
      setDirectories(updatedDirectories);
  
      // Delete item from Firestore
      await firebase.firestore().collection('directories').doc(directoryId).collection('items').doc(itemId).delete();
      console.log('Item deleted successfully.');
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('Error deleting item. Please try again.');
    }
  };
  const getFileIcon = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    switch (fileExtension) {
      case 'txt':
        return <FiFileText className="h-6 w-6 text-blue-500" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FiImage className="h-6 w-6 text-blue-500" />;
      case 'mp4':
      case 'avi':
      case 'mkv':
        return <FiVideo className="h-6 w-6 text-blue-500" />;
      case 'zip':
      case 'rar':
      case '7z':
        return <FiArchive className="h-6 w-6 text-blue-500" />;
      case 'doc':
      case 'docx':
        return <FaFileWord className="h-6 w-6 text-blue-500" />; // Menggunakan ikon file Word dari react-icons/fa
      case 'xls':
      case 'xlsx':
        return <FaFileExcel className="h-6 w-6 text-blue-500" />; // Menggunakan ikon file Excel dari react-icons/fa
      case 'html':
      case 'htm':
        return <FaFileCode   className="h-6 w-6 text-blue-500" />; // Menggunakan ikon file HTML dari react-icons/fa
      default:
        return <FiFile className="h-6 w-6 text-blue-500" />;
    }
  };

  const handleRenameItem = async (directoryId, itemId, newName) => {
    try {
      // Prompt user for new name via input
      const userInput = prompt('Enter new name:');
      if (!userInput) {
        // If user cancels or inputs empty string, do nothing
        return;
      }
  
      // Update local state (directories)
      const updatedDirectories = directories.map(directory => {
        if (directory.id === directoryId) {
          const updatedItems = directory.items.map(item => {
            if (item.id === itemId) {
              return { ...item, name: userInput };
            }
            return item;
          });
          return { ...directory, items: updatedItems };
        }
        return directory;
      });
  
      setDirectories(updatedDirectories);
  
      // Update item name in Firestore
      await firebase.firestore().collection('directories').doc(directoryId).collection('items').doc(itemId).update({ name: userInput });
      console.log('Item renamed successfully.');
    } catch (error) {
      console.error('Error renaming item:', error);
      alert('Error renaming item. Please try again.');
    }
  };
  
  

  const handleFileOptionsClick = (event, itemId, itemName) => {
    event.stopPropagation(); // Prevents toggling directory when clicking on menu
    setOpenMenuItemId(itemId === openMenuItemId ? null : itemId);
  };
  

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl -mt-10">

        {directories.map(directory => (
          <div key={directory.id} className="">

            {directories.map(directory => (
  <div key={directory.id} className="">

    {directory.isOpen && (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
        {directory.items.map(item => (
          <div key={item.id} className="relative bg-white rounded-lg shadow-md p-4 cursor-pointer">
            <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2" onClick={() => handleFileClick(item)}>
  {item.url ? (
    <>
      {getFileIcon(item.name) ? getFileIcon(item.name) : <FiFile className="h-6 w-6 text-blue-500" />}
      <span className="ml-2">{item.name.split('.').slice(0, -1).join('.')}</span>
    </>
  ) : (
    <>
      {item.type === 'txt' ? <FiFileText className="h-6 w-6 text-blue-500" /> : <FiFile className="h-6 w-6 text-blue-500" />}
      <span className="ml-2 text-lg">{item.name.split('.').slice(0, -1).join('.')}</span>
    </>
  )}
</div>



              <div className="relative">
              <button className="focus:outline-none" onClick={(e) => handleFileOptionsClick(e, item.id, item.name)}>
  <FiMoreVertical className="text-gray-500 h-3 w-3 sm:h-4 sm:w-4 md:h-5 md:w-5 lg:h-7 lg:w-8 xl:h-9 xl:w-9" />
</button>
                {openMenuItemId === item.id && (
  <div className="absolute right-0 mt-2 sm:mt-0 sm:right-auto sm:left-full sm:top-0 w-48 bg-white rounded-lg shadow-lg z-10">
    <ul className="py-1">
      <li
        className="cursor-pointer px-4 py-2 text-gray-800 hover:bg-gray-200"
        onClick={() => handleRenameItem(directory.id, item.id)}
      >
        Rename
      </li>
      <li
        className="cursor-pointer px-4 py-2 text-gray-800 hover:bg-gray-200"
        onClick={() => handleDeleteItem(directory.id, item.id)}
      >
        Delete
      </li>
    </ul>
  </div>
)}

              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
))}

          </div>
        ))}

{showFloatingButton && (
  <div className="fixed bottom-4 right-16">
      <ul className="space-y-2">
        {directories.map((directory) => (
          <li
            key={directory.id}
            onClick={() => handleFolderSelection(directory.id)}
            className="cursor-pointer hover:bg-gray-100 rounded-lg p-2"
          >
            {directory.name}
          </li>
        ))}
      </ul>
    </div>
)}
{showModal && (
  <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-800 bg-opacity-50" onClick={() => setShowModal(false)}>
    <div className="bg-white p-8 shadow-md rounded-lg w-50 sm:w-96" onClick={(e) => e.stopPropagation()}>
      <h2 className="text-lg font-semibold mb-4">Create New {fileType === 'txt' ? 'Text' : 'File'}</h2>
      <div className="flex items-center mb-4">
        <input
          type="radio"
          id="txt"
          name="fileType"
          value="txt"
          checked={fileType === 'txt'}
          onChange={() => setFileType('txt')}
          className="mr-2"
        />
        <label htmlFor="txt" className="mr-4">Text</label>
        <input
          type="radio"
          id="file"
          name="fileType"
          value="file"
          checked={fileType === 'file'}
          onChange={() => setFileType('file')}
          className="mr-2"
        />
        <label htmlFor="file">File</label>
      </div>
      {fileType === 'txt' ? (
        <input
          type="text"
          value={newFileName}
          onChange={(e) => setNewFileName(e.target.value)}
          placeholder="Enter file name"
          className="border border-gray-300 rounded-lg p-2 w-full mb-4"
        />
      ) : (
        <div>
          <input
            type="file"
            accept="image/*, video/*"
            onChange={(e) => handleFileSelection(e.target.files[0])}
            className="border border-gray-300 rounded-lg p-2 w-full mb-4"
          />
          <p className="text-sm text-gray-500">Upload image or video</p>
        </div>
      )}
      <div className="flex justify-end">
        <button onClick={createNewFile} className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2">Create</button>
        <button onClick={() => setShowModal(false)} className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg">Cancel</button>
      </div>
    </div>
  </div>
)}


{selectedFile && selectedFile.type === 'txt' && (
  <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-800 bg-opacity-50" onClick={() => setSelectedFile(null)}>
    <div className="bg-white p-8 shadow-md rounded-lg w-full sm:w-96" onClick={(e) => e.stopPropagation()}>
      <h2 className="text-lg font-semibold mb-4">Edit Note</h2>
      <textarea
        value={note}
        onChange={(e) => setNote(e.target.value)}
        className="border border-gray-300 rounded-lg p-2 w-full mb-4"
        rows={4}
      />
      <div className="flex justify-end">
        <button onClick={saveNote} className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2">Save</button>
        <button onClick={() => setSelectedFile(null)} className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg">Cancel</button>
      </div>
    </div>
  </div>
)}

<button
  onClick={handleFloatingButtonClick}
  className="fixed bottom-4 right-4 md:bottom-8 md:right-8 bg-blue-500 text-white p-3 md:p-4 rounded-full shadow-lg focus:outline-none hover:bg-blue-600 hover:shadow-xl transition duration-300"
>
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
  </svg>
</button>

      </div>
    </div>
  );
};

export default Cloud;
