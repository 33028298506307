import React, { useState, useEffect } from 'react';

const DiscountAlert = () => {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const currentDate = new Date();
    const discountEndDate = new Date('2024-08-17'); // Set the discount end date here
    const daysRemaining = Math.ceil((discountEndDate - currentDate) / (1000 * 60 * 60 * 24));

    if (daysRemaining >= 0 && daysRemaining <= 7) {
      setShowAlert(true);
    }
  }, []);

  return (
    <>
      {showAlert && (
        <p className="text-gray-500 text-sm">Diskon akan berakhir pada tanggal 17/08/2024</p>
      )}
   
    </>
  );
};

export default DiscountAlert;
