import React, { useState, useEffect, useRef } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { MdCheckroom, MdClass, MdDelete, MdFilter, MdRoomPreferences, MdStarRate } from "react-icons/md"; // Import Material Icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { MdSettingsApplications, } from 'react-icons/md';
import Navbara from '../pages/Navbara';
import Navigation from './Navigation';

const Kelas = () => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [newData, setNewData] = useState({});
  const [searchTitle, setSearchTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState('');
  const [showMyCourse, setShowMyCourse] = useState(false); // State untuk mengontrol apakah MyCourse ditampilkan
  const [creatorProfile, setCreatorProfile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Number of items per page

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const fetchClasses = async () => {
      const classesCollection = await firebase.firestore().collection('classes').get();
      const classesData = classesCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClasses(classesData);
      setLoading(false);
    };

    fetchClasses();

    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    const fetchRatedClasses = async () => {
      const ratedClassesCollection = await firebase.firestore().collection('classes').where('rated', '==', true).get();
      const ratedClassesData = ratedClassesCollection.docs.map(doc => doc.data());
      // Do something with rated classes if needed
    };

    fetchRatedClasses();
  }, []);

  const userCanModify = (classUserId) => {
    return user && classUserId === user.uid;
  };

  const handleEditClick = (kelas) => {
    setEditMode(true);
    setEditData(kelas);
    setNewData(kelas);
  };

  const handleSaveClick = async () => {
    await firebase.firestore().collection('classes').doc(editData.id).update(newData);
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const handleBuyClick = (kelas) => {
    // Logic for purchasing can be added here
    console.log('Beli kelas:', kelas);
  };

  // Filtering classes based on title and selected category
  const filteredClasses = classes.filter(kelas =>
    kelas.title.toLowerCase().includes(searchTitle.toLowerCase()) &&
    (!selectedCategory || kelas.category === selectedCategory)
  );

  // Get search suggestions from Firestore
  const getSuggestions = async (searchTerm) => {
    const suggestionsCollection = await firebase.firestore().collection('classes').where('title', '>=', searchTerm).where('title', '<=', searchTerm + '\uf8ff').get();
    const suggestionsData = suggestionsCollection.docs.map(doc => doc.data().title);
    setSuggestions(suggestionsData);
  };

  // Get list of available categories
  const availableCategories = [...new Set(classes.map(kelas => kelas.category))];

  const handleDeleteItem = async (itemId) => {
    try {
      await firebase.firestore().collection('classes').doc(itemId).delete();
      setDeleteStatus('Item berhasil dihapus.');
    } catch (error) {
      console.error('Terjadi kesalahan saat menghapus item:', error);
      setDeleteStatus('Terjadi kesalahan saat menghapus item.');
    }
  };

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Fungsi untuk menanggapi klik di luar sidebar dan menutupnya
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    const fetchCreatorProfile = async () => {
      try {
        const userProfileDoc = await firebase.firestore().collection('users').doc(editData.userId).get();
        if (userProfileDoc.exists) {
          const userProfileData = userProfileDoc.data();
          setCreatorProfile(userProfileData);
        } else {
          console.log('Profil pengguna tidak ditemukan.');
        }
      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil profil pengguna:', error);
      }
    };

    fetchCreatorProfile();
  }, [editData.userId]);

  // Menambahkan event listener untuk menutup sidebar saat klik di luar
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  const handleKelasClick = (kelas) => {
    if (user) {
      // Jika pengguna sudah login, arahkan mereka ke halaman detail kelas
      window.location.href = `/${kelas.permalink}`;
    } else {
      // Jika pengguna belum login, tampilkan pop-up login Firebase Authentication
      const confirmLogin = window.confirm('Anda harus login untuk melihat kelas. Lanjutkan ke proses login?');
      if (confirmLogin) {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
          .then((result) => {
            // Setelah pengguna berhasil login, arahkan mereka ke halaman detail kelas
            window.location.href = `/${kelas.permalink}`;
          })
          .catch((error) => {
            // Handle error
            console.error("Error signing in:", error);
          });
      }
    }
  };

  const toggleMyCourse = () => {
    setShowMyCourse(!showMyCourse);
  };

  const formatToRupiah = (value) => {
    // Mengonversi nilai string menjadi angka
    const numberValue = parseFloat(value);
    // Melakukan formatting ke format mata uang Rupiah
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(numberValue);
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredClasses.length / itemsPerPage);
  const currentClasses = filteredClasses.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
      <Navbara />
      <div className="container mx-auto px-4 py-8 ">
        {loading && <p>Loading...</p>}
        <div className="mb-8 flex flex-col md:flex-row md:items-center justify-between relative">
        <div className="flex items-center mb-2 md:mb-0">
  <p className="text-lg font-bold md:mr-4">Pilih Kategori:</p>
  {user ? (
    <MdSettingsApplications
      className="text-green-700 ml-auto text-2xl cursor-pointer md:hidden"
      onClick={toggleSidebar}
    />
  ) : (
    <MdFilter // Menggunakan ikon MyProfile
      className="text-green-700 ml-auto text-2xl cursor-pointer md:hidden"
      onClick={handleKelasClick}
    />
  )}
</div>

  <div className="relative flex-grow md:flex-grow-0 mb-2 md:mb-0 md:mr-4">
  <select
    value={selectedCategory}
    onChange={(e) => setSelectedCategory(e.target.value)}
    className="border border-gray-300 rounded-md px-4 py-2 w-full md:w-40 text-black"
  >
    <option value="">Semua Kategori</option>
    {availableCategories.map((category, index) => (
      <option key={index} value={category}>{category}</option>
    ))}
  </select>
</div>

<div className="relative flex-grow md:flex-grow-0">
  <input
    type="text"
    value={searchTitle}
    onChange={(e) => {
      setSearchTitle(e.target.value);
      getSuggestions(e.target.value); // Panggil getSuggestions saat nilai input berubah
    }}
    placeholder="Cari Kelas"
    className="border border-gray-300 rounded-md px-4 py-2 w-full md:w-auto text-black"
  />
  {(suggestions.length > 0 && searchTitle.trim() !== '') && (
    <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 text-black">
      {suggestions.map((suggestion, index) => (
        <li
          key={index}
          onClick={() => {
            setSearchTitle(suggestion);
            setSuggestions([]); // Hapus daftar saran setelah memilih salah satu
          }}
          className="px-4 py-2 cursor-pointer hover:bg-gray-200"
        >
          {suggestion}
        </li>
      ))}
    </ul>
  )}
</div>


</div>


        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {currentClasses.map((kelas, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer"
              onClick={() => handleKelasClick(kelas)}
            >
              <img
                src={kelas.thumbnailURL}
                alt={kelas.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-5 text-white">
                <h2 className="text-xl font-bold mb-3">{kelas.title}</h2>
                <div className="flex justify-between items-center mb-3">
                  <div className="flex items-center">
                    <svg
                      className="w-5 h-5 text-yellow-500 mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M17.52 7.416l-4.896-.714-2.192-4.447c-.3-.607-1.323-.607-1.623 0l-2.192 4.447-4.896.714c-.662.097-.929.854-.458 1.285l3.55 3.462-.839 4.899c-.114.666.561 1.181 1.136.852l4.398-2.312 4.398 2.312c.575.302 1.25-.186 1.136-.852l-.839-4.899 3.55-3.462c.472-.46.204-1.188-.458-1.285z"
                        clipRule="evenodd"
                      />
                    </svg>
           
                    <p className="text-sm text-yellow-500">{kelas.rating}</p>
                  </div>
                  <div>
                    <p className="text-gray-400 line-through">{formatToRupiah(kelas.price)}<span>0</span></p>
                    {kelas.discountedPrice && (
                      <p className="text-green-600 font-bold">{formatToRupiah(kelas.discountedPrice)}<span>0</span></p>
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center space-x-2">
                    {userCanModify(kelas.userId) ? (
                      <>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(kelas);
                          }}
                          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md text-sm"
                        >
                          Edit
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteItem(kelas.id);
                          }}
                          className="text-red-500 hover:text-red-700"
                        >
                          <MdDelete size={20} />
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => handleBuyClick(kelas)}
                        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md text-sm"
                      >
                        Lihat
                      </button>
                    )}
                  </div>
                  <button
                    onClick={() => handleBuyClick(kelas)}
                    className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded-md text-sm"
                  >
                    Tambah Favorit
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-6 mb-20">
  {Array.from({ length: totalPages }, (_, index) => (
    <button
      key={index}
      onClick={() => handlePageChange(index + 1)}
      className={`px-3 py-1 mx-1 ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-700 text-black'} rounded hover:bg-gray-300`}
    >
      {index + 1}
    </button>
  ))}
  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
    className={`px-3 py-1 mx-1 ${currentPage === totalPages ? 'bg-gray-900 cursor-not-allowed text-white' : 'bg-gray-900 text-white rounded hover:bg-gray-300'}`}
  >
    Next
  </button>
</div>

      </div>
      <Navigation />
    </div>
  );
};

export default Kelas;
