import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database'; // Import database dari Firebase
import Navbar from '../pages/Navbar';
import Navigation from '../pages/Navigation';
import { GoogleLoginButton } from 'react-social-login-buttons'; // Import komponen login Google
import { Helmet } from 'react-helmet';
import { FaWhatsapp, FaEnvelope, FaInstagram } from 'react-icons/fa';
import { MoonIcon, SunIcon } from '@heroicons/react/solid'; // Import ikon mode malam/siang

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const database = firebase.database(); // Inisialisasi database

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [darkMode, setDarkMode] = useState(false); // State untuk mode malam/siang

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Mengambil status mode malam/siang dari Firebase saat komponen dimuat
    const darkModeRef = database.ref('notifications/darkmode');
    darkModeRef.on('value', (snapshot) => {
      const darkModeValue = snapshot.val();
      if (darkModeValue !== null) {
        setDarkMode(darkModeValue);
      }
    });

    return () => {
      unsubscribe();
      darkModeRef.off(); // Mematikan listener ketika komponen tidak lagi digunakan
    };
  }, []);

  const handleGoogleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider)
      .then((result) => {
        // Login berhasil
        setUser(result.user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setUser(null);
    }).catch((error) => {
      console.error(error);
    });
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode); // Mengubah status mode malam/siang secara lokal

    // Menyimpan status mode malam/siang ke Firebase
    database.ref('notifications/darkmode').set(newDarkMode);
  };

  return (
    <>
     <Helmet>
        <title>Login Page</title>
        <meta name="description" content="Halaman Login Kelasskill" />
        {/* Add other meta tags for SEO as needed */}
      </Helmet>
      <Navbar />
      <div
        className={`flex flex-col items-center justify-center min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-white'}`}
      >
   
        <div
          className={`max-w-md w-full p-6 ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} rounded-md shadow-lg backdrop-blur-lg bg-opacity-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-screen/2`}
          style={{
            width: '80%', // Atur lebar sesuai kebutuhan
          }}
        >    
          {user ? (
            <>
              <h2 className="text-lg font-bold">{`Welcome, ${user.displayName}`}</h2>
              <p className="text-lg">Email: {user.email}</p>
              <img
                src={user.photoURL}
                alt="Profile"
                className="w-20 h-20 rounded-full mt-4 shadow-md ring-2 ring-blue-500"
              />
              <button
                onClick={handleLogout}
                className="mt-6 px-4 py-2 bg-green-700  text-white rounded-md shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Logout
              </button>
              
              <div className="flex justify-between mt-20">
                <div className="text-center flex items-center">
                  <p className="mt-1 mb-6 mr-2 font-medium">
                    Hubungi kami
                    <span className="relative">
                      <span className="absolute top-0 left-2 mt-2">
                        <div className="w-2 h-2 rounded-full bg-green-700 animate-pulse"></div>
                      </span>
                    </span>
                  </p>
                </div>
                <div className="text-center">
                  <a href="https://wa.me/62895392807421" target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp className="h-8 w-8 mx-auto" />
                  </a>
                </div>
                <div className="text-center">
                  <a href="https://www.instagram.com/kelasskill/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="h-8 w-8 mx-auto" />
                  </a>
                </div>
                <div className="text-center">
                  <a href="mailto:admin@kelasskill.com">
                    <FaEnvelope className="h-8 w-8 mx-auto" />
                  </a>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center">
              <p className={darkMode ? 'text-white mb-4' : 'text-black mb-4'}>Silakan login untuk mendapatkan akses penuh</p>
              <GoogleLoginButton onClick={handleGoogleLogin} />
            </div>
          )}
          {/* Toggle Mode */}
          <div className="flex justify-center mt-4">
            <label className="inline-flex items-center cursor-pointer" onClick={toggleDarkMode}>
              {darkMode ? <SunIcon className="h-5 w-5 text-yellow-500 ml-1" /> : <MoonIcon className="h-5 w-5 text-gray-600 ml-1" />}
            </label>
          </div>
        </div>
      </div>
      <Navigation />
    </>
  );
};

export default UserProfile;
