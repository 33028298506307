import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Navbara from '../pages/Navbara';
import Navigation from './Navigation';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const ErrorPage = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);
  return (
   <>
      <Navbara/>


      <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} flex flex-col items-center justify-center h-screen -mt-20`}>

      <div className="text-2xl font-bold mb-2 text-center ">Fitur sedang dalam Pengembangan/Perbaikan</div>
      <p className="text-sm mb-6 text-center">
        Masa pengembangan fitur akan selesai akhir 2024 <br/>Kami mohon maaf atas ketidaknyamanan ini.
      </p>
      <Link to="/">
        <button className="px-4 py-2 bg-green-700  rounded-md hover:bg-gray-700">
          Kembali
        </button>
      </Link>
    </div>

    <Navigation/>
   </>
  );
};

export default ErrorPage;
