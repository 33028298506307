import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import moment from "moment";
import { Transition } from "@headlessui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

import { faExpandArrowsAlt, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import "tailwindcss/tailwind.css";

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
const getDateTime = (timestamp) => {
  moment.locale("id"); // Set localization to Indonesian

  const date = moment(timestamp).format("DD MMMM YYYY");
  const time = moment(timestamp).format("HH:mm");
  const day = moment(timestamp).format("dddd");
  return { date, time, day };
};


const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [user, setUser] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showChatStream, setShowChatStream] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const sortedUploadedData = uploadedData.sort((a, b) => b.timestamp - a.timestamp);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const notificationsRef = firebase.database().ref("notifications/users");
  
    // Retrieve data with status "true" from Firebase Realtime Database
    notificationsRef.on("value", (snapshot) => {
      const notifications = snapshot.val();
      const uploadedData = Object.values(notifications).filter(
        (notification) => notification.status === "true"
      );
      setUploadedData(uploadedData);
      setLoading(false); // Set loading to false when data is retrieved
    });
  
    return () => {
      notificationsRef.off();
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (selectedTopic) {
      unsubscribe = firestore
        .collection("discuss")
        .doc(selectedTopic)
        .collection("messages")
        .orderBy("timestamp")
        .onSnapshot((snapshot) => {
          const messagesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(messagesData);
        });
    } else {
      // If no topic is selected, clear the messages
      setMessages([]);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedTopic]);

  const sendMessage = async () => {
    if (newMessage.trim() !== "") {
      if (user) {
        const fullMessage = replyMessage + newMessage; // Combine reply message and new message
        await firestore
          .collection("discuss")
          .doc(selectedTopic)
          .collection("messages")
          .add({
            authProfile: user.photoURL,
            authname: user.displayName,
            message: fullMessage,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
        setNewMessage("");
        setReplyMessage("");
      }
    }
  };

  const handleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await auth.signInWithPopup(provider);
  };

  const handleLogout = async () => {
    await auth.signOut();
  };

  const handleEdit = (messageId, messageText) => {
    // Set the edit message id and text
    console.log("Edit Message", messageId, messageText);
  };

  const cancelEdit = () => {
    // Clear the edit message id and text
  };

  const saveEdit = async () => {
    // Save the edited message
  };

  const deleteMessage = async (messageId) => {
    // Delete the message
  };

  const handleJoin = (topic) => {
    if (user) {
      // If the user is logged in, proceed with showing the chat stream
      setSelectedTopic(topic);
      setShowChatStream(true);
    } else {
      // If the user is not logged in, show the Firebase authentication popup
      handleLogin();
    }
  };

  const handleShare = (permalink) => {
    console.log("Sharing Permalink:", permalink);
  };

  const handleMoreDetails = (permalink) => {
    // Define the logic to handle displaying more details or redirecting to permalink
    // For instance, you might redirect the user to the permalink page
    // Replace this with your actual logic
    window.location.href = `/${permalink}`; // Redirect to the details page using the permalink
  };
  
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const buttonStyle = {
    position: "absolute",
    top: "12px", // Sesuaikan dengan posisi vertikal dari atas yang diinginkan
    right: "50px", // Ubah nilai right untuk menggeser tombol ke kiri sebanyak 10px
    zIndex: "999",
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      // Unsubscribe from Firebase listener when component unmounts
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);
  

  return (
    <>

      {user ? (
        <div style={{ 
          backgroundColor: "#333540",
        }} className={"bg-gray-800 dark:bg-gray-900 flex flex-col flex-grow"}>
        


  
          <div className="flex flex-col flex-grow overflow-y-auto">
            {messages.map((message) => (
              <div
                key={message.id}
                className="flex items-start p-2 space-x-2 transition-all ease-in-out duration-300 hover:bg-gray-200"
              >
                <img
                  src={message.authProfile}
                  alt="User Profile"
                  className="w-8 h-8 rounded-full"
                />
                <div className="flex-grow">
                  <div className="flex items-center justify-between">
                    <p className="font-semibold">{message.authname}</p>
                  </div>
                  <p>{message.message}</p>
                  <button
                    className="text-blue-500 mt-2"
                    onClick={() => setReplyMessage(`@${message.authname} `)}
                  >
                    Reply
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* ... bagian lain ... */}
      
        </div>
      ) : (
<div style={{ 
  backgroundColor: "#333540",
}} className={""}>           

<div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}  p-4 text-center flex items-center`}>

<p className="  mr-2 font-medium ">
Artikel Pendidikan:

</p>
</div></div>
      )}
<div className="p-4 text-white py-4 px-4 md:px-6 lg:px-8 items-center mt-4">
      {/* Meta tags */}
      {uploadedData.map((item, index) => (
        <Helmet key={index}>
          <title>{item.title}</title>
          <meta name="description" content={item.description} />
          {/* Add other meta tags as needed */}
        </Helmet>
      ))}

      {showAll ? (
        <div className="flex flex-wrap">
          {uploadedData.map((item, index) => (
            <div key={index} className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
              <article
                style={{ backgroundColor: "#000000" }}
                className={
                  "bg-000000 dark:bg-000000 rounded-lg overflow-hidden  hover: mb-4 sm:mb-6 lg:mb-8"
                }
              >
                <a href={`/${item.permalink}`}>
                  {item.imageUrl && (
                    <img
                      src={item.imageUrl}
                      alt="Thumbnail"
                      className="object-cover h-40 w-full rounded-t-lg"
                    />
                  )}
                  <div className="p-4">
                    <a href={`/${item.permalink}`} className="text-white font-bold text-lg hover:underline">
                      <h2 className="mb-1">{item.title}</h2>
                    </a>
                    <time className="text-gray-200 text-xs mb-1 block">
                      {getDateTime(item.timestamp).date} {getDateTime(item.timestamp).time} {getDateTime(item.timestamp).day}
                    </time>
                  </div>
                </a>
              </article>
            </div>
          ))}
        </div>
      ) : (
<Slider {...settings}>
          {uploadedData.map((item, index) => (
            <div key={index}>
 
         

<article className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} rounded-lg overflow-hidden  hover: mb-4 sm:mb-6 lg:mb-8`}>

                <a href={`/${item.permalink}`}>
                  {item.imageUrl && (
                    <img
                      src={item.imageUrl}
                      alt="Thumbnail"
                      className="object-cover h-40 w-full rounded-t-lg"
                    />
                  )}
                  <div className="p-4">
                    <a href={`/${item.permalink}`} className=" font-bold text-lg hover:underline">
                      <h2 className="mb-1 ">{item.title}</h2>
                    </a>
                    <time className=" text-xs mb-1 block">
                      {getDateTime(item.timestamp).date} {getDateTime(item.timestamp).time} {getDateTime(item.timestamp).day}
                    </time>
                  </div>
                </a>
              </article>
            </div>
          ))}
        </Slider>


      
      
      )}

      <div className="flex justify-end mt-4">
        <button onClick={toggleShowAll} style={buttonStyle} className="-mt-3 hover:bg-gray-50 text-gray-700 font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={showAll ? faChevronDown : faChevronRight} />
        </button>
      </div>
    </div>
    
    </>
  );
};

export default Chat;