import { useEffect, useState } from 'react';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

import { Route, Routes, useNavigate } from 'react-router-dom';

import DetailItem from './component/DetailItem';
import Home from './pages/Home';
import { useLocation } from 'react-router-dom';
import ArticleFormm from "./pages/ArticleFormm";
import Flashday from "./component/Flashday";
import FaqPage from "./component/FaqPage";
import Store from "./component/Store";
import Upload from "./component/Upload";
import Uploaduser from "./component/Uploaduser";
import AddProductForm from "./component/AddProductForm";
import Chat from "./component/Chat";
import { initializeApp } from "firebase/app";
import Login from "./component/Login";
import About from "./pages/About";
import Handle from "./pages/Handle";
import Mentahan from "./pages/Mentahan";
import Chatnow from './pages/Chatnow';
import Sosmed from './pages/Sosmed';
import Goals from './pages/Goals';
import Changelog from './pages/Changelog';
import Chart from './pages/Chart';
import ThemeToggle from './component/ThemeToggle';
import UploadKelas from './pages/UploadKelas';
import Kelas from './pages/Kelas';
import DetailKelas from './pages/DetailKelas';
import DetailPage from './pages/DetailPage';
import MyCourse from './pages/MyCourse';
import TaskForm from './component/TaskForm';
import TaskList from './component/TaskList';
import EditQR from './component/EditQR';
import Loginqr from "./component/Loginqr";
const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);


function App() {  
  const [onlineUsers, setOnlineUsers] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const currentUserUID = 'YxeXN5KgRxfcOFdFEw8ULKUcKR92'; // UID pengguna yang bisa melihat total pengguna online
  const location = useLocation();
  const navigate = useNavigate(); // Mendapatkan fungsi navigasi dari React Router







return (
  <div id='root'>
  
 <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:permalink" element={<DetailPage />} />


{/* <Route path="/Hijau" element={<Hijau/>} /> */}

<Route path="/about" element={<About />} />

<Route path="/handle" element={<Handle />} />
<Route path="/mentahan" element={<Mentahan />} />

<Route path="/store" element={<Store />} />





{/* <Route path="/UgcForm" element={<UgcForm/>} /> */}


<Route path="/articleFormm" element={<ArticleFormm />} />

<Route path="/flashday" element={<Flashday />} />
<Route path="/faqpage" element={<FaqPage />} />


{/* <Route path="/notificationform" element={<NotificationForm />} /> */}
<Route path="/login" element={<Login />} />
<Route path="/upload" element={<Upload />} />
<Route path="/uploaduser" element={<Uploaduser />} />
<Route path="/addproductform" element={<AddProductForm />} />
<Route path="/chat" element={<Chat />} />
<Route path="/chatnow" element={<Chatnow />} />
<Route path="/sosmed" element={<Sosmed />} />
<Route path="/goals" element={<Goals />} />
<Route path="/changelog" element={<Changelog />} />
<Route path="/chart" element={<Chart />} />
<Route path="/themetoggle" element={<ThemeToggle />} />
<Route path="/kelas" element={<Kelas />} />
<Route path="/mycourse" element={<MyCourse />} />
<Route path="/tasklist" element={<TaskList />} />
<Route path="/editqr" element={<EditQR />} />
<Route path="/loginqr" element={<Loginqr />} />

      {/* Batas Default Delete route*/}
        

        </Routes>
  </div>
);
}

export default App;