import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";

const AddAdImageForm = () => {
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [href, setHref] = useState('');

    useEffect(() => {
        const statusRef = firebase.database().ref('notifications/ad1/status');
        statusRef.on('value', (snapshot) => {
            const statusValue = snapshot.val();
            setStatus(statusValue);
        });

        return () => {
            statusRef.off();
        };
    }, []);

    const handleChange = (event) => {
        if (event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleUpload = () => {
        if (image) {
            const storageRef = firebase.storage().ref(`images/${image.name}`);
            const uploadTask = storageRef.put(image);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progress);
                },
                (error) => {
                    console.error('Error uploading image:', error);
                    setUploadMessage('Upload failed');
                },
                () => {
                    storageRef.getDownloadURL().then((url) => {
                        setImageUrl(url);
                        firebase.database().ref('notifications/ad1').update({ imageUrl: url, href: href })
                            .then(() => {
                                console.log('Image URL and href added to database successfully!');
                                firebase.database().ref('notifications/ad1').update({ status: true })
                                    .then(() => {
                                        console.log('Status set to true!');
                                        setStatus(true);
                                        setUploadMessage('Upload successful');
                                    })
                                    .catch((error) => {
                                        console.error('Error setting status:', error);
                                    });
                            })
                            .catch((error) => {
                                console.error('Error adding image URL and href to database:', error);
                                setUploadMessage('Upload failed');
                            });
                    });
                }
            );
        }
    };

    const toggleStatus = () => {
        const newStatus = !status;
        firebase.database().ref('notifications/ad1').update({ status: newStatus })
            .then(() => {
                console.log('Status updated successfully!');
                setStatus(newStatus);
            })
            .catch((error) => {
                console.error('Error updating status:', error);
            });
    };

    return (
        <div className="w-full max-w-md mx-auto p-4">
            {uploadMessage && <div className="bg-green-200 text-green-800 py-2 px-4 mb-4 rounded">{uploadMessage}</div>}
            <progress className="w-full mb-4" value={progress} max="100" />
            <input className="mb-4" type="file" onChange={handleChange} />
            <input className="mb-4" type="text" placeholder="Enter href" value={href} onChange={(e) => setHref(e.target.value)} />
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4" onClick={handleUpload}>Upload</button>
            <p className="bg-red-700 text-white py-2 px-4 mb-4 rounded">Status: {status ? 'True' : 'False'}</p>
            <p className="bg-blue-700 text-white py-2 px-4 mb-4 rounded">Href: {href}</p>
            <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded " onClick={toggleStatus}>Toggle Status</button>
        </div>
    );
};

export default AddAdImageForm;
