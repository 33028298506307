import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore"; // Mengimpor Firestore

const Chatnow = ({ roomId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const db = firebase.firestore();
  const messagesRef = db.collection(`chats/${roomId}/messages`);
  const currentUserId = firebase.auth().currentUser.uid;

  useEffect(() => {
    const unsubscribe = messagesRef.orderBy("timestamp").onSnapshot((snapshot) => {
      const messagesArray = [];
      snapshot.forEach((doc) => {
        messagesArray.push({ id: doc.id, ...doc.data() });
      });
      setMessages(messagesArray);
    });

    return () => unsubscribe();
  }, [roomId]);

  const handleSendMessage = (text, repliedTo = null) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    messagesRef.add({
      text: text,
      senderId: currentUserId,
      timestamp: timestamp,
      repliedTo: repliedTo, // ID dari pesan yang dibalas, jika ada
    });

    setNewMessage("");
  };

  return (
    <div>
      <div>
        {messages.map((message) => (
          <div key={message.id} className="bg-red-500">
            <p>{message.text}</p>
            <p>Sender: {message.senderId}</p>
            <p>Sent at: {message.timestamp && message.timestamp.toDate().toString()}</p>
            
            {/* Tombol balasan */}
            <button onClick={() => handleSendMessage("Balasan pesan", message.id)}>Balas</button>
          </div>
        ))}
      </div>
      <div>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={() => handleSendMessage(newMessage)}>Kirim</button>
      </div>
    </div>
  );
};

export default Chatnow;
