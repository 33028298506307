 import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Transition } from '@headlessui/react';
import "firebase/compat/storage";
import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const getRandomPlaceholder = () => {
  const placeholders = [
    "Pesan Jasa Disini",
    "Bikin Website?",
    "Bikin Aplikasi Android?",
    "Hubungi Freelance",
    "Cari Freelance",
    "Jasa Desain Termurah",
    "Bikin Toko Digital?",
    "Jasa Joki tugas kamu?",
    "Branding Bisnismu?"
  ];
  
  return placeholders[Math.floor(Math.random() * placeholders.length)];
};

const IsRequest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [details, setDetails] = useState('');
  const [namaPemesan, setNamaPemesan] = useState('');
  const [nomorWhatsApp, setNomorWhatsApp] = useState('');
  const [user, setUser] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const fileInputRef = useRef(null);

  const checkLoginStatus = () => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      handleGoogleLogin();
      return false;
    }
    setUser(currentUser);
    return true;
  };

  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const result = await firebase.auth().signInWithPopup(provider);
      setUser(result.user);
      setIsOpen(true);
    } catch (error) {
      console.error("Error signing in with Google:", error);
      alert("Maaf Anda belum login, Silakan Login Dulu.");
    }
  };

  useEffect(() => {
    const fetchDarkModeSetting = async () => {
      try {
        const darkModeRef = firebase.database().ref('notifications/darkmode');
        darkModeRef.on('value', (snapshot) => {
          const darkModeValue = snapshot.val();
          setDarkMode(darkModeValue);
        });
      } catch (error) {
        console.error('Error fetching dark mode setting:', error);
      }
    };

    fetchDarkModeSetting();

    return () => {
      firebase.database().ref('notifications/darkmode').off();
    };
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const user = firebase.auth().currentUser;

    if (!user) {
      alert("Silakan login terlebih dahulu!");
      return;
    }
    
    const db = firebase.firestore();

    try {
      const docRef = await db.collection("isrequest").add({
        category,
        details,
        userId: user.uid,
        isHiddenField: false,
        imageUrl,
        namaPemesan,
        nomorWhatsApp
      });
    
      alert("Pesanan kamu berhasil dikirim");
      setIsOpen(false);
    
      // Buat pesan yang akan dikirim ke server
      const newRequestId = docRef.id; // Mendapatkan ID dokumen baru dari Firestore
      const pesan = `Saya ingin pesan jasa anda dengan ID ${newRequestId}: ${category} - ${details}.`;
    
      // Kirim pesan ke server menggunakan Axios
      const response = await axios.get(`https://asia-southeast2-starry-gravity-428814-h7.cloudfunctions.net/function-1?message=${encodeURIComponent(pesan)}`);
      
      console.log("Response from server:", response.data); // Output respons dari server (opsional)
    } catch (error) {
      console.error("Error saving to Firestore or sending message:", error);
    }
    
  };

  const handleFileUpload = async (file) => {
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);
  
    try {
      // Upload file to Firebase Storage
      const snapshot = await fileRef.put(file);
      // Get the download URL of the uploaded file
      const url = await snapshot.ref.getDownloadURL();
      // Set the image URL state with the download URL
      setImageUrl(url);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Terjadi kesalahan saat mengunggah gambar. Silakan coba lagi.");
    }
  };
  

  return (
    <>
      <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} p-2`}>
        <div style={{ backgroundColor: "#E4E3F3" }} className={" flex items-center mx-auto max-w-[300px] rounded-lg overflow-hidden shadow-lg"}>
          <input
            type="text"
            placeholder={getRandomPlaceholder()}
            onClick={() => {
              const isLoggedIn = checkLoginStatus();
              if (!isLoggedIn) {
                window.location.href = '/login';
                return;
              }
              setIsOpen(true);
            }}
            className="w-full px-4 py-3 bg-white text-black dark:text-gray-300 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none"
          />

          <button 
            onClick={() => {
              const isLoggedIn = checkLoginStatus();
              if (!isLoggedIn) {
                window.location.href = '/login';
                return;
              }
              setIsOpen(true);
            }}
            className="px-4 py-3 bg-green-700 text-white hover:bg-green-800 transition duration-300 ease-in-out focus:outline-none"
          >
            Joki
          </button>

          <Transition
            show={isOpen}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`fixed inset-0 z-50 ${isOpen ? 'flex justify-center items-center' : 'hidden'}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="w-full h-full flex items-center justify-center">
                <div className="w-full h-screen mx-0 p-0 rounded-lg bg-black shadow-lg">
                  <form onSubmit={handleFormSubmit} className="max-w-md mx-auto">
                    <div className="mb-4 relative mt-12">
                      <input
                        type="text"
                        value={namaPemesan}
                        onChange={(e) => setNamaPemesan(e.target.value)}
                        className="w-full p-3 border rounded-lg focus:outline-none text-black"
                        placeholder="Nama Pemesan"
                        required
                      />
                    </div>
                    <div className="mb-4
relative">
<textarea
  value={details}
  onChange={(e) => {
    setDetails(e.target.value);
  }}
  className="w-full p-3 border rounded-lg focus:outline-none text-black"
  placeholder="Ceritakan masalah Anda di sini"
  required
  rows="5"
/>
</div>
<div className="mb-4 relative">
<input
  type="file"
  onChange={(e) => handleFileUpload(e.target.files[0])}
  className="p-3"
  required
  ref={fileInputRef}
/>
</div>
<div className="mb-4 relative">
<span className="absolute inset-y-0 left-0 flex items-center pl-2 text-white bg-green-700">
  +62
</span>
<input
  type="text"
  value={nomorWhatsApp}
  onChange={(e) => setNomorWhatsApp(e.target.value)}
  className="pl-10 w-full p-3 border rounded-lg focus:outline-none text-black"
  placeholder="Nomor WhatsApp"
  required
/>
</div>
<button
type="submit"
className="w-full bg-gray-600 text-white py-3 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out"
>
Kirim Pesan
</button>
</form>
<div className="text-center py-4 text-sm text-white">
Nomor anda akan dihubungi tim kami{' '}
<a
  href="https://www.zivzu.com/registration"
  target="_blank"
  rel="noopener noreferrer"
  className="text-blue-500 font-bold hover:underline"
>
  <br/>Dalam beberapa menit
</a>
<button onClick={() => setIsOpen(false)} className="block mt-4 mx-auto px-4 py-2 rounded-lg bg-green-700 text-white hover:bg-gray-400 focus:outline-none">
  Tutup
</button>
</div>
</div>
</div>
</div>
</Transition>
</div>
</div>
</>
);
};

export default IsRequest;
