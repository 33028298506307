import React, { useState, useEffect } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/database';
import 'firebase/storage';
import Navbara from '../pages/Navbara';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ParticlesBg from 'particles-bg';
import Navigation from '../pages/Navigation';

// Initialize Firebase (replace with your own config)
const firebaseConfig = {
  apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
  authDomain: "kelasskilla.firebaseapp.com",
  databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
  projectId: "kelasskilla",
  storageBucket: "kelasskilla.appspot.com",
  messagingSenderId: "785364290918",
  appId: "1:785364290918:web:1da135595c6cea24be4ca7",
  measurementId: "G-R4L1YVZSTB"
};

const AddProductForm = ({ onAddProduct }) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const [user] = useAuthState(firebase.auth());
  const [productData, setProductData] = useState({
    id: user ? user.uid : '',
    title: '',
    content: '',
    image: null,
    price: '',
    category: '',
    email: '',
    whatsapp: '',
    status: false,
    qrCode: 'https://i.ibb.co/g63TmFq/1702895582056.jpg',
    permalink: '' // Permalink untuk produk
  });
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;
  
    if (name === 'title') {
      // Memaksa judul menjadi Title Case
      formattedValue = value.toLowerCase().replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    } else if (name === 'price') {
      const formattedPrice = formatRupiah(value);
      formattedValue = formattedPrice;
    }
  
    if (name === 'price') {
      setProductData({ ...productData, [name]: formattedValue });
    } else {
      setProductData({ ...productData, [name]: formattedValue });
    }
  };
  

  const generateRandomString = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
  }

  useEffect(() => {
    const generatedPermalink = generateRandomString(10); // Misalnya, panjang permalink 10 karakter
    setProductData(prevProductData => ({
      ...prevProductData,
      permalink: generatedPermalink
    }));
  }, []);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setProductData({ ...productData, image: imageFile });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (productData.category === '') {
      alert('Please select a category');
      return;
    }
  
    const database = getDatabase();
    const productsRef = ref(database, 'notifications/products');
  
    const generatedPermalink = generateRandomString(10);
  
    if (productData.image) {
      const storage = getStorage();
      const storageReference = storageRef(storage, `product_images/${productData.image.name}`);
      const uploadTask = uploadBytesResumable(storageReference, productData.image);
  
      uploadTask.on('state_changed',
        (snapshot) => {},
        (error) => {
          console.error('Error uploading image:', error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const productDataWithImageUrl = { ...productData, image: downloadURL };
            const productDataWithPermalink = { ...productDataWithImageUrl, permalink: generatedPermalink };
            push(productsRef, productDataWithPermalink);
  
            if (typeof onAddProduct === 'function') {
              onAddProduct(productDataWithPermalink);
            }
  
            setProductData({
              id: user ? user.uid : '',
              title: '',
              content: '',
              image: null,
              price: '',
              category: '',
              email: '',
              whatsapp: '',
              status: false,
              qrCode: '',
              permalink: ''
            });
  
            alert('Product added successfully!');
          });
        }
      );
    } else {
      const productDataWithPermalink = { ...productData, permalink: generatedPermalink };
      push(productsRef, productDataWithPermalink);
  
      if (typeof onAddProduct === 'function') {
        onAddProduct(productDataWithPermalink);
      }
  
      setProductData({
        id: user ? user.uid : '',
        title: '',
        content: '',
        image: null,
        price: '',
        category: '',
        email: '',
        whatsapp: '',
        status: false,
        qrCode: '',
        permalink: ''
      });
  
      alert('Product added successfully!');
    }
  };
  
  const formatRupiah = (angka) => {
    var number_string = angka.toString().replace(/[^,\d]/g, ''),
        split = number_string.split(','),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        
    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return 'Rp ' + rupiah;
  }

  return (
    <>
      <Navbara/>
      
      <div className=''>
        <form onSubmit={handleSubmit} className="max-w-4xl mx-auto mt-2 p-2">
          <div className="space-y-4">
            <div className="flex flex-col">
              <input
                type="text"
                id="title"
                name="title"
                value={productData.title}
                onChange={handleInputChange}
                placeholder="Product Title"
                className="input-field px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <ReactQuill
                id="content"
                theme="snow"
                value={productData.content}
                onChange={(value) => setProductData({ ...productData, content: value })}
                placeholder="Product Description"
              />
            </div>
            <div className="flex flex-col">
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleImageChange}
                className="input-field"
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                id="price"
                name="price"
                value={productData.price}
                onChange={handleInputChange}
                placeholder="Product Price"
                className="input-field px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="category" className="mb-1 font-medium text-gray-800">Pilih Kategori</label>
              <select
                id="category"
                name="category"
                value={productData.category}
                onChange={handleInputChange}
                required
                className="input-field px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Category</option>
                <option value="Produk Digital">Produk Digital</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
  <button
    type="submit"
    className="bg-red-500 text-white py-2 px-4 rounded"
    style={{
      position: 'absolute',
      right: '20px',
    }}
  >
    Add Product
  </button>
</div>

          </div>
        </form>
      </div>
      <Navigation/>
    </>
  );
};

export default AddProductForm;
