import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Navbara from './Navbara';
import Navigation from './Navigation';
import Waiting from './Waiting';

function Sosmed() {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
  const [socialMediaOptions, setSocialMediaOptions] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');
  const [servicesOptions, setServicesOptions] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [isUnderDevelopment, setIsUnderDevelopment] = useState(true); // New state for development alert
  const [usernameLink, setUsernameLink] = useState('');

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyA6Lo9ItL6kf5gm9ZC5tF1a1vOqReWW7k0",
      authDomain: "kelasskilla.firebaseapp.com",
      databaseURL: "https://kelasskilla-default-rtdb.firebaseio.com",
      projectId: "kelasskilla",
      storageBucket: "kelasskilla.appspot.com",
      messagingSenderId: "785364290918",
      appId: "1:785364290918:web:1da135595c6cea24be4ca7",
      measurementId: "G-R4L1YVZSTB"
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    async function fetchData() {
      try {
        const db = firebase.firestore();
        const snapshot = await db.collection('Steps').get();
        const data = snapshot.docs.map(doc => doc.data());
        setSocialMediaOptions(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    async function fetchServicesOptions() {
      try {
        const db = firebase.firestore();
        const snapshot = await db.collection('Steps').get();
        const data = snapshot.docs.map(doc => doc.data().service);
        setServicesOptions(data);
      } catch (error) {
        console.error('Error fetching services options:', error);
      }
    }
  
    fetchServicesOptions();
  }, []);

  // Define handleSubmit function to handle form submission
  const handleSubmit = () => {
    // Proses masukan pengguna, hitung total harga, dan lakukan tindakan yang diperlukan
    // Misalnya:
    const totalPrice = calculateTotalPayment();
    console.log('Total Harga:', totalPrice);

    // Lakukan validasi sebelum mengirim data ke server
    if (!selectedSocialMedia || !selectedService || !selectedPackage || !quantity || !usernameLink) {
      alert('Mohon lengkapi semua bidang sebelum mengirim permintaan.');
      return;
    }

    // Persiapkan data untuk dikirim ke server atau database
    const requestData = {
      selectedSocialMedia,
      selectedService,
      selectedPackage,
      quantity,
      totalPrice, // Menambahkan total harga ke data permintaan
      usernameLink
    };

    // Kirim data ke server atau database
    sendRequest(requestData);
  };

  const sendRequest = async (requestData) => {
    try {
      const db = firebase.firestore();
      await db.collection('Sosmed').add(requestData);
      alert('Permintaan Anda berhasil disimpan!');
    } catch (error) {
      console.error('Error sending request:', error);
      alert('Maaf, terjadi kesalahan saat mengirim permintaan Anda. Silakan coba lagi.');
    }
  };

  const calculateTotalPayment = () => {
    // Extract package price from selectedPackage
    const selectedPackageData = socialMediaOptions.find(option => option.service === selectedService && `${option.service}-${option.quantity}` === selectedPackage);
    if (selectedPackageData && quantity !== '') {
      // Calculate total payment based on quantity and price from Firestore
      const totalPriceFromFirestore = selectedPackageData.price;
      const totalPrice = Math.ceil(parseInt(quantity) / 1000) * totalPriceFromFirestore; // Menggunakan Math.ceil() untuk memastikan kelipatan dihitung dengan benar
      return totalPrice;
    }
    return 0; // Default value if data is not found or quantity is not provided
  };
  
  const [socialMediadeskripsi, setSocialMediadeskripsi] = useState('');
  useEffect(() => {
    if (selectedSocialMedia) {
      const selectedOption = socialMediaOptions.find(option => option.socialMedia === selectedSocialMedia);
      if (selectedOption) {
        setSocialMediadeskripsi(selectedOption.deskripsi);
      }
    }
  }, [selectedSocialMedia, socialMediaOptions]);
  
  
  
  return (
    <div className='bg-black'>
    <Navbara/>
    <div className="max-w-md mx-auto mt-8 p-4 bg-black rounded-lg shadow-md">
      {isUnderDevelopment && (
        <div className="bg-yellow-200 p-4 mb-4 border-l-4 border-yellow-500 text-yellow-700">
Fitur ini sedang dalam pengembangan.        </div>
      )}
   {currentStep === 1 && (
  <div>
    <h2 className="text-lg font-semibold mb-4 text-white">Step 1: Select Social Media</h2>
    <div className="mb-4">
      <select
        className="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 focus:border-purple-900 focus:ring-purple-900 text-base text-gray-800 bg-white"
        value={selectedSocialMedia}
        onChange={(e) => setSelectedSocialMedia(e.target.value)}
      >
        <option value="">Select One</option>
        {socialMediaOptions.map(option => (
          <option key={option.socialMedia} value={option.socialMedia}>{option.socialMedia}</option>
        ))}
      </select>
    </div>
    <button
      onClick={nextStep}
      disabled={!selectedSocialMedia}
      className={`px-4 py-2 rounded-md ${
        !selectedSocialMedia ? 'bg-gray-300 cursor-not-allowed' : 'bg-purple-800 hover:bg-purple-600 text-white'
      }`}
    >
      Next
    </button>
  </div>
)}

{currentStep === 2 && socialMediaOptions.some(option => option.socialMedia === selectedSocialMedia) && (
  <div>
    <h2 className="text-lg font-semibold mb-4 text-white">Step 2: Select Service</h2>
    <div className="mb-4">
      <select
        className="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 focus:border-purple-900 focus:ring-purple-900 text-base text-gray-800 bg-white"
        value={selectedService}
        onChange={(e) => setSelectedService(e.target.value)}
      >
        <option value="">Select One</option>
        {servicesOptions.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
    <div className="flex justify-between">
    <button onClick={prevStep} className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 ">
        Back
      </button>
      <button
        onClick={nextStep}
        disabled={!selectedService}
        className={`px-4 py-2 rounded-md ${
          !selectedService ? 'bg-gray-300 cursor-not-allowed' : 'bg-purple-900 hover:bg-purple-600 text-white'
        }`}
      >
        Next
      </button>
 
    </div>
  </div>
)}

{currentStep === 3 && (
  <div>
    <h2 className="text-lg font-semibold mb-2 text-white">Step 3: Select Package</h2>
    <div className="mb-4">
      <select
        id="package"
        name="package"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-900 focus:border-purple-900 sm:text-sm rounded-md"
        value={selectedPackage}
        onChange={(e) => setSelectedPackage(e.target.value)}
      >
        <option value="">Select One</option>
        {socialMediaOptions.map(option => (
          option.service === selectedService &&
          <option key={`${option.service}-${option.quantity}`} value={`${option.service}-${option.quantity}`}>
            {`${option.service} - ${option.quantity} (${option.price})`}
          </option>
        ))}
      </select>
    </div>

    <div className="mb-4">
  <label className="block mb-1 text-white font-semibold">Deskripsi:</label>
  <div className="relative">
    <input
      type="text"
      value={socialMediadeskripsi}
      readOnly
      className="pl-3 pr-10 py-2 w-full text-base border-gray-300 focus:outline-none focus:ring-purple-900 focus:border-purple-900 rounded-md bg-gray-100 text-gray-800"
    />
    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
      <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"></path>
      </svg>
    </div>
  </div>
</div>

<div className="mb-4">
  <label className="block mb-1 text-white font-semibold">Jumlah:</label>
  <input
    type="number"
    value={quantity}
    onChange={(e) => setQuantity(e.target.value)}
    placeholder='Masukkan Jumlah View'
    className="pl-3 pr-10 py-2 w-full text-base border-gray-300 focus:outline-none focus:ring-purple-900 focus:border-purple-900 rounded-md bg-gray-100 text-gray-800"
  />
</div>

<div className="mb-4">
  <label className="block mb-1 text-white font-semibold">Username/Link:</label>
  <input
    type="text"
    value={usernameLink}
    onChange={(e) => setUsernameLink(e.target.value)}
    placeholder="Masukkan username atau link di sini"
    className="pl-3 pr-10 py-2 w-full text-base border-gray-300 focus:outline-none focus:ring-purple-900 focus:border-purple-900 rounded-md bg-gray-100 text-gray-800"
  />
</div>


    {/* Display total payment */}
    <p className='mb-4 text-white font-semibold'>Total Payment: {quantity && selectedPackage ? calculateTotalPayment() : 0}</p>
    <div className="flex justify-between mt-4">
  <button onClick={prevStep} className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 mr-2 focus:outline-none">
    Back
  </button>
  <button
    onClick={() => handleSubmit()}
    className="px-4 py-2 bg-purple-900 text-white rounded-md hover:bg-purple-700 focus:outline-none"
  >
    Submit
  </button>
</div>

  </div>
)}

    </div>
    <Waiting/>
    <Navigation/>
    </div>  );
  
}

export default Sosmed;

