import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app'; // Pastikan firebase diimport dengan benar
import DetailItem from '../component/DetailItem';
import DetailKelas from './DetailKelas';

const DetailPage = () => {
  const { permalink } = useParams();
  const [isItem, setIsItem] = useState(false); // Menentukan apakah permalink mengacu pada item atau kelas

  useEffect(() => {
    const checkItemType = async () => {
      try {
        const usersRef = firebase.database().ref('notifications/users');
        usersRef.orderByChild('permalink').equalTo(permalink).once('value', (snapshot) => {
          if (snapshot.exists()) {
            setIsItem(true); // Set ke true jika permalink mengacu pada item
          }
        });
      } catch (error) {
        console.error('Terjadi kesalahan saat memeriksa tipe item:', error);
      }
    };

    checkItemType();

    // Membersihkan state saat komponen unmount
    return () => setIsItem(false);
  }, [permalink]);

  return (
    <div>
      {isItem ? <DetailItem /> : <DetailKelas />}
    </div>
  );
};

export default DetailPage;
